import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";

const useStyles = makeStyles({
  screen: {
    pageBreakAfter: "always",
  },
  text: {
    textAlign: "justify",
    textIndent: "50px",
    lineHeight: "55px",
    wordSpacing: 10,
  },
  bodyMm: {
    fontFamily: "PdsFont",
    fontSize: "22px",
  },
  bodyJp: {
    fontFamily: "Mincho",
    fontSize: "22px",
  },
  centeredText: {
    textAlign: "center",
    lineHeight: "55px",
    fontFamily: "Mincho",
    fontSize: "18px",
  },
  centeredSubtitle: {
    textAlign: "justify",
    fontSize: "20px",
    fontFamily: "PdsFont",
    lineHeight: "50px",
  },
  title: {
    textAlign: "center",
    fontSize: "24px",
    letterSpacing: 35,
    fontFamily: "Mincho",
    lineHeight: "50px",
  },
  subtitle: {
    textAlign: "center",
    fontSize: "22px",
    fontFamily: "PdsFont",
    lineHeight: "55px",
    letterSpacing: 2,
  },
  secondaryTitle: {
    fontSize: "24px",
    fontFamily: "PdsFont",
  },
  nomarl: {
    fontSize: "16px",
    fontFamily: "Mincho",
  },
});

const HousinForm_1_39 = () => {
  const classes = useStyles();

  return (
    <Box className={classes.screen} sx={{ px: 15, pt: 10 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography sx={{ fontSize: "16px", fontFamily: "Mincho" }}>
            参考様式第１-39 号
          </Typography>
          <Typography sx={{ fontSize: "16px", fontFamily: "Mincho" }}>
            ရည်ညွှန်းပုံစံ အမှတ် 1-39
          </Typography>

          <Typography sx={{ fontSize: "16px", fontFamily: "Mincho" }}>
            Ｄ・Ｅ・Ｆ
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Typography sx={{ fontSize: "16px", fontFamily: "Mincho" }}>
            （日本産業規格Ａ列４）
          </Typography>
          <Typography sx={{ fontSize: "16px", fontFamily: "Mincho" }}>
            (ဂျပန်စက်မှု သတ်မှတ်စံနှုန်း A-4)
          </Typography>
        </Box>
      </Box>

      <Box sx={{ py: 5, textAlign: "center" }}>
        <Typography className={classes.title}>
          技能実習生の個人情報の取り扱いに係る同意書
        </Typography>
        <Typography className={classes.subtitle}>
          နည်းပညာဆိုင်ရာ အလုပ်သင် သင်တန်းသားများ၏
          ကိုယ့်ရေးကိုယ်တာအချက်အလက်များအား ကိုင်တွယ်ခြင်းနှင့် ပတ်သက်သည့်
          သဘောတူညီချက်
        </Typography>
      </Box>

      <Box
        sx={{
          marginBottom: "50px",
        }}
      >
        <Typography className={classes.secondaryTitle}>
          外国人技能実習機構 理事長 殿
        </Typography>
        <Typography className={classes.secondaryTitle}>
          နိုင်ငံခြား နည်းပညာဆိုင်ရာအလုပ်သင်သင်တန်းအဖွဲ့အစည်း ဥက္ကဌ သို့
        </Typography>
      </Box>

      <Typography className={`${classes.text} ${classes.bodyJp}`}>
        外国人の技能実習の適正な実施及び技能実習生の保護に関する法律第 19
        条第１項又は同法第33
        条第１項の規定に基づき「技能実習実施困難時届出書」を提出した当該技能実習生に係る情
        報に関し、雇用保険制度における失業等給付に係る事務において必要となることもあることから、その必要が生じた場合に当該技能実習生に技能実習を行わせていた実習実施者の所在地を管轄
        する都道府県労働局及び公共職業安定所に連絡することについて、「独立行政法人等の保有する
        個人情報の保護に関する法律」（平成十五年法律第五十九号）第９条第２項第１号に基づき同意
        します。
      </Typography>
      <Typography className={`${classes.text} ${classes.bodyMm}`}>
        နိုင်ငံခြား နည်းပညာဆိုင်ရာအလုပ်သင်သင်တန်း၏
        သင့်လျော်သောနည်းပညာဆိုင်ရာလက်တွေ့သင်ကြားမှုနှင့် နည်းပညာဆိုင်ရာ
        အလုပ်သင်သင်တန်းသား၏ ကြည့်ရှုစောင့်ရှောက်မှုနှင့် ပတ်သက်သည့် ဥပဒေပုဒ်မ 19
        အချက် 1 (သို့မဟုတ်) တူညီသော ဥပဒေပုဒ်မ 33 အချက် 1 တွင်
        ပြဌာန်းထားသည့်အပေါ် အခြေခံပြီး “နည်းပညာဆိုင်ရာ အလုပ်သင်
        သင်တန်းလေ့ကျင့်သင်ကြားရေးခက်ခဲချိန် အကြောင်းကြားစာ” အားတင်ပြခဲ့သည့်
        ထိုနည်းပညာအလုပ်သင်သင်တန်းသားနှင့် သက်ဆိုင်သည့် သတင်းအချက်အလက်နှင့်
        စပ်လျဥ်း၍ အလုပ်အာမခံစနစ်ရှိ အလုပ်လက်မဲ့ အကျိုးခံစားခွင့်နှင့်ပတ်သက်သည့်
        ရုံးလုပ်ငန်းတွင် လိုအပ်သောအရာ ဖြစ်သည့်အတွက် ထိုကဲ့သို့လိုအပ်လာခဲ့ပါက
        ထိုနည်းပညာဆိုင်ရာ အလုပ်သင်သင်တန်းသားအား
        နည်းပညာဆိုင်ရာလက်တွေ့သင်ကြားမှုအား ဆောင်ရွက်စေခဲ့သော
        လက်တွေ့သင်ကြားမှုအား ဆောင်ရွက်သူ၏ နေထိုင်ရာ တည်နေရာအား အုပ်ချုပ်သည့်
        ခရိုင်အလုပ်သမားဗျူရိုနှင့် ပြည်သူ့ အလုပ်ကိုင်လုံခြုံရေးရုံးသို့
        ဆက်သွယ်ခြင်း နှင့်ပတ်သက်သော “တစ်သီးပုဂ္ဂလအုပ်ချုပ်ရေးအဖွဲ့အစည်းစသည်တို့၏
        ပိုင်ဆိုင်သော ကိုယ်ရေးကိုယ်တာ အချက်အလက်များ ထိန်းသိမ်းခြင်းဆိုင်ရာဥပဒေ”
        (2003 ခုနှစ် ဥပဒေအမှတ် 59) အချက် 9 အပိုင်း 2 အပိုဒ် 1 အား အခြေခံ၍
        သဘောတူပါသည်။
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: "100px",
          marginTop: "50px",
        }}
      >
        <Box>
          <Typography className={classes.normal}>
            署名年月日 ：
          </Typography>
          <Typography className={classes.normal}>
            လက်မှတ်ထိုးသည့် နှစ်၊ လ၊ ရက်:
          </Typography>
        </Box>
        <Box>
          <Typography className={classes.normal}>年</Typography>
          <Typography className={classes.normal}>နှစ်</Typography>
        </Box>
        <Box>
          <Typography className={classes.normal}>月</Typography>
          <Typography className={classes.normal}>လ</Typography>
        </Box>
        <Box>
          <Typography className={classes.normal}>日</Typography>
          <Typography className={classes.normal}>ရက်</Typography>
        </Box>
      </Box>
      <Box>
        <Typography className={classes.normal}>
          技能実習生の署名：
        </Typography>
        <Typography className={classes.normal}>
          နည်းပညာဆိုင်ရာလက်တွေ့သင်ကြားမည့်သင်တန်းသား၏ လက်မှတ်
        </Typography>
      </Box>
    </Box>
  );
};

export default HousinForm_1_39;
