import React, { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
    screen: {
        pageBreakAfter: 'always',
    },
});

const Coe9_2 = ({index}) => {
    const classes = useStyles();
    const { data: printData } = useSelector(state=> state.PrintData)
    const [ memberData,setMemberData ] = useState(null)
    const [ companyInfo,setCompanyInfo ] = useState(null)
    const [ orgInfo,setOrgInfo ] = useState(null)
  
    useEffect(()=>{
      if(printData && printData.data && printData.data.members){
        const data = printData.data.members[index]
        setMemberData(data)
        setCompanyInfo(printData.data.company)
        setOrgInfo(printData.data.organization)
      }
    },[printData, index])
    return (
        <Box className={classes.screen} sx={{ px: 15, pt: 10 }}>
            <Typography sx={{ fontSize: '28px', textAlign: 'center', fontFamily: 'PdsFontNumber', fontWeight: 'bold', lineHeight: '50px' }}>ပြည်ပအလုပ်အကိုင်ရှာဖွေရေးအေဂျင်စီနှင့်အလုပ်သင်သင်တန်းသားတို့ကြားချုပ်ဆိုသော<br/>စေလွှတ်မှုသဘောတူစာချုပ်</Typography>
            <Typography sx={{ fontSize: '28px', textAlign: 'center', fontFamily: 'Mincho', fontWeight: 'bold', lineHeight: '50px', mb: 3 }}>海外雇用エージェンシーと技能実習生との契約書<br/>契約書</Typography>
            <Typography sx={{
                lineHeight: '35px', fontFamily: 'PdsFont', fontSize: '20px', textAlign: 'justify' ,
            }}>
                <span style={{ textDecoration: 'underline', textUnderlineOffset: 5 , fontSize: '20px', textAlign: 'justify' , fontFamily: 'PdsFont' }}>CLOVER MANDALAY CO., LTD</span> (အောက်တွင် “ကျွန်ုပ်” ဟု သုံးနှုန်းမည်) သည် 
                <span style={{ textDecoration: 'underline', textUnderlineOffset: 5 , fontSize: '20px', textAlign: 'justify' , fontFamily: 'PdsFont' }}> {orgInfo ? orgInfo.nameEng : ''}</span> (အောက်တွင် “ကြီးကြပ်အဖွဲအစည်း” ဟု သုံးနှုန်းမည်) နှင့် ဂျပန်နိုင်ငံသို့ အလုပ်သင် သင်တန်းသား စေလွှတ်ခြင်းနှင့်စပ်လျဉ်း၍ သဘောတူစာချုပ်ချုပ်ဆိုပြီး၊ စေလွှတ်မည့် အလုပ်သင်သင်တန်းသား 
                <span style={{ textDecoration: 'underline', textUnderlineOffset: 5 , fontSize: '20px', textAlign: 'justify' , fontFamily: 'PdsFont' }}> {memberData ? memberData.nameEng : ''}</span> (အောက်တွင် “သင်” ဟု သုံးနှုန်းမည်) နှင့် မြန်မာနိုင်ငံပြည်ပအလုပ်အကိုင်စေလွှတ်မှုနှင့် ပတ်သက် သည့် သတ်မှတ်စည်းမျဉ်းများနှင့်အညီ ဂျပန်နိုင်ငံ 
                <span style={{ textDecoration: 'underline', textUnderlineOffset: 5 , fontSize: '20px', textAlign: 'justify' , fontFamily: 'PdsFont' }}> {companyInfo ? companyInfo.nameEng : ''}</span> (အောက်တွင်“လက်ခံမည့် လုပ်ငန်း” ဟု သုံးနှုန်းမည်)သို့ တရားဝင် စေလွှတ်ရန်အတွက် အောက်ပါအတိုင်း ဤသဘောတူစာချုပ်ကို ချုပ်ဆိုပါသည်။
            </Typography>
            <Typography sx={{
                lineHeight: '35px', fontFamily: 'PdsFont', fontSize: '20px', textAlign: 'justify' ,
                pb: '30px',
            }}>
                <span style={{ textDecoration: 'underline', textUnderlineOffset: 5 , fontSize: '20px', textAlign: 'justify' , fontFamily: 'PdsFont' }}>株式会社クローバーマンダレー</span>（以下「私」という）と 
                <span style={{ textDecoration: 'underline', textUnderlineOffset: 5 , fontSize: '20px', textAlign: 'justify' , fontFamily: 'PdsFont' }}> {orgInfo ? orgInfo.nameJp : ''}</span> （以下「受入監理団体」という） が日本への技能実習生送り出しに関して契約書を締結して送り出される技能実習生 
                <span style={{ textDecoration: 'underline', textUnderlineOffset: 5 , fontSize: '20px', textAlign: 'justify' , fontFamily: 'PdsFont' }}> {memberData ? memberData.nameEng : ''}</span> （以下 「あなた」という）とミャンマー連邦共和国の海外雇用送り出しに関する 規定に従って日本国 
                <span style={{ textDecoration: 'underline', textUnderlineOffset: 5 , fontSize: '20px', textAlign: 'justify' , fontFamily: 'PdsFont' }}> {companyInfo ? companyInfo.nameJp : ''}</span> （以下「受け入れ機関」）への法的な送り出し に関して次の通りの契約を結ぶ。
            </Typography>
            <Typography sx={{ fontFamily: 'PdsFontNumber', fontSize: '20px', textAlign: 'justify'  , fontWeight: 'bold' }}>ကျွန်ုပ်၏လုပ်ငန်းတာဝန်နှင့်ဝတ္တရား</Typography>
            <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px', textAlign: 'justify' , fontWeight: 'bold', mb: 3 }}>（私の役割と義務）</Typography>
            <Typography sx={{ lineHeight: '35px', fontFamily: 'PdsFont', fontSize: '20px', textAlign: 'justify'  }}>
                ၁။ ကျွန်ုပ်သည် ဂျပန်နိုင်ငံ၏ကြီးကြပ်အဖွဲ့အစည်း၊ လက်ခံမည့်လုပ်ငန်း၏ သင်တန်းအကြောင်းအရာ၊ လုပ်ငန်း အကြောင်းအရာ၊ အလုပ်ခန့်အပ်ခြင်းဆိုင်ရာသဘောတူစာချုပ်ပါအကြောင်းအရာများသည် မြန်မာဂျပန် နှစ်နိုင်ငံ အစိုးရ၏ဥပဒေနှင့်ကိုက်ညီမှု ရှိမရှိစစ်ဆေးပါမည်။
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontFamily: 'Mincho', fontSize: '20px', textAlign: 'justify' , mb: 3 }}>
                1.　私は日本の受入監理団体、技能実習事業の内容、従事する仕事の内容、雇用契約書の内容がミャンマー国と日本国の現行法に適用することを検討します。
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontFamily: 'PdsFont', fontSize: '20px', textAlign: 'justify'  }}>
                ၂။ ကျွန်ုပ်သည် သင့်အား အမှတ်စဉ်(၁) ပါ အကြောင်းအရာများကို လုပ်ငန်းအင်တာဗျူးဝင်ရောက်မဖြေဆိုမီ သိရှိ နားလည်အောင် ရှင်းလင်းပြောပြပါမည်။
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontFamily: 'Mincho', fontSize: '20px', textAlign: 'justify' , mb: 3 }}>
                2.　私はあなたが面接を受ける前に第１条のことをあなたが十分理解するように説明視します。
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontFamily: 'PdsFont', fontSize: '20px', textAlign: 'justify'  }}>
                ၃။ ကျွန်ုပ်သည် သင့်အား လုပ်ငန်းအင်တာဗျူးဝင်ရောက်ဖြေဆိုနိုင်ရန် စီစဉ်ပေးမည်ဖြစ်ပြီး၊ အင်တာဗျူး ဖြေဆိုရာတွင် ဆောင်ရန် ရှောင်ရန်များကို ရှင်းလင်းပြောပြပါမည်။
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontFamily: 'Mincho', fontSize: '20px', textAlign: 'justify' , mb: 3 }}>
                3.　私は面接を受けるように手配し、面接において従うべき、避けるべきことを説明します。
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontFamily: 'PdsFont', fontSize: '20px', textAlign: 'justify'  }}>
                ၄။ လုပ်ငန်းအင်တာဗျူးအောင်မြင်ပြီးနောက် အလုပ်ခန့်အပ်ခြင်းဆိုင်ရာသဘောတူစာချုပ်ပါ အကြောင်းအရာများကို ထပ်မံ၍ ရှင်းလင်းပြောပြကာ၊ သင်၏သဘောတူလက်ခံမှုကို စစ်ဆေးပြီး လက်ခံမည့်လုပ်ငန်းနှင့် စာချုပ်ချုပ်ဆိုနိုင်ရန် စီစဉ်ပေးမည်။
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontFamily: 'Mincho', fontSize: '20px', textAlign: 'justify' , mb: 3 }}>
                4.　面接を合格後雇用契約書の内容を再説明してあなたが納得するかを確かめて雇用契約書を締結するように支度をします。
            </Typography>
        </Box>
    )
}

export default Coe9_2

