import React, { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
import { Box, Typography } from "@mui/material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import JobCat from "./JobCat";
import { convertToMyanmarNumbers } from "../../../../utils/enTomm";
import { splitDate } from "../../../../utils/splitDate";
import { getAge } from "../../../../utils/getAge";

const useStyles = makeStyles({
    screen: {
        pageBreakAfter: 'always',
        position: 'relative',
        minHeight: '100vh'
    },
    redBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        position: 'absolute',
        top: "140px",
        right: "120px"
    },
    firstInnerBox: {
        width: 180,
        height: 100,
        borderRadius: '10px',
        border: '1px solid black',
        marginBottom: '20px'
    },
    secondInnerBox: {
        width: 130,
        height: 150,
        border: '1px solid black',
        marginRight: 10
    },
    tableCell: {
        fontFamily: 'PdsFont',
        fontSize: '20px',
        padding: '20px 0px'
    },
});

const SmartCardForm = ({data}) => {
    const [ cardData,setCardData ] = useState(null)
    const classes = useStyles();
    
    useEffect(()=>{
        if(data){
            setCardData(data)
        }
    },[data])

    return (
      <Box className={classes.screen} sx={{ p: 15 }}>
        <Box className={classes.redBox}>
          <Box className={classes.firstInnerBox} />
          <Box className={classes.secondInnerBox} />
        </Box>
        {cardData && (
          <Box sx={{ float: "left", width: "100%" }}>
            <Typography
              sx={{
                fontFamily: "PdsFontNumber",
                textDecoration: "underline",
                fontWeight: "bold",
                textAlign: "center",
                mb: 3,
              }}
              variant="h5"
            >
              Overseas Worker Identification Card
            </Typography>
            <Typography
              sx={{
                fontFamily: "PdsFontNumber",
                textDecoration: "underline",
                fontWeight: "bold",
                textAlign: "center",
              }}
              variant="h5"
            >
              ကိုယ်ရေးအချက်လတ်ပုံစံ
            </Typography>
            <table style={{ width: "90%", marginTop: "30px" }}>
              <tbody>
                <tr>
                  <td className={classes.tableCell}>၁။</td>
                  <td className={classes.tableCell}>အမည်(မြန်မာ)/အင်္ဂလိပ်</td>
                  <td className={classes.tableCell}>
                    {cardData.nameMm} / {cardData.nameEng.toUpperCase()}
                    <hr style={{ border: "1px solid #000" }}></hr>
                  </td>
                  <td style={{ minWidth: "150px" }}></td>
                </tr>
                <tr>
                  <td className={classes.tableCell}>၂။</td>
                  <td className={classes.tableCell}>
                    အခြားအမည်(မြန်မာ)/အင်္ဂလိပ်
                  </td>
                  <td className={classes.tableCell}>
                    &nbsp;
                    <hr style={{ border: "1px solid #000" }}></hr>
                  </td>
                  <td style={{ minWidth: "150px" }}></td>
                </tr>
                <tr>
                  <td className={classes.tableCell}>၃။</td>
                  <td className={classes.tableCell}>အဘအမည်</td>
                  <td className={classes.tableCell}>
                    {cardData.fatherNameMm ? cardData.fatherNameMm :  <span>&nbsp;</span> }
                    <hr style={{ border: "1px solid #000" }}></hr>
                  </td>
                  <td style={{ minWidth: "150px" }}></td>
                </tr>
                <tr>
                  <td className={classes.tableCell}>၄။</td>
                  <td className={classes.tableCell}>အမိအမည်</td>
                  <td className={classes.tableCell}>
                    {cardData.motherNameMm ? cardData.motherNameMm :  <span>&nbsp;</span> }
                    <hr style={{ border: "1px solid #000" }}></hr>
                  </td>
                  <td style={{ minWidth: "150px" }}></td>
                </tr>
                <tr>
                  <td className={classes.tableCell}>၅။</td>
                  <td className={classes.tableCell}>မွေးနေ့</td>
                  <td className={classes.tableCell}>
                    {convertToMyanmarNumbers(
                      splitDate(cardData.dateOfBirth).day)} နေ့ {convertToMyanmarNumbers(
                      splitDate(cardData.dateOfBirth).month)} လ {convertToMyanmarNumbers(
                      splitDate(cardData.dateOfBirth).year)} ခုနှစ် အသက် ({getAge(cardData.dateOfBirth)})
                    <hr style={{ border: "1px solid #000" }}></hr>
                  </td>
                  <td style={{ minWidth: "150px" }}></td>
                </tr>
                <tr>
                  <td className={classes.tableCell}>၆။</td>
                  <td className={classes.tableCell}>မွေးဖွားရာဇာတိ</td>
                  <td className={classes.tableCell}>
                    {cardData.hometownMm ? cardData.hometownMm :  <span>&nbsp;</span> }
                    <hr style={{ border: "1px solid #000" }}></hr>
                  </td>
                  <td style={{ minWidth: "150px" }}></td>
                </tr>
                <tr>
                  <td className={classes.tableCell}>၇။</td>
                  <td className={classes.tableCell}>
                    လူမျိုး/ ကိုးကွယ်သည့်ဘာသာ
                  </td>
                  <td className={classes.tableCell}>
                    {cardData.ethnicityAndReligionMm ? cardData.ethnicityAndReligionMm :  <span>&nbsp;</span> }
                    <hr style={{ border: "1px solid #000" }}></hr>
                  </td>
                  <td style={{ minWidth: "150px" }}></td>
                </tr>
                <tr>
                  <td className={classes.tableCell}>၈။</td>
                  <td className={classes.tableCell}>
                    မြင်သာထင်ရှားသည့်အမှတ်အသား
                  </td>
                  <td className={classes.tableCell}>
                    {cardData.promientMark ? cardData.promientMark :  <span>&nbsp;</span> }
                    <hr style={{ border: "1px solid #000" }}></hr>
                  </td>
                  <td style={{ minWidth: "150px" }}></td>
                </tr>
                <tr>
                  <td className={classes.tableCell}></td>
                  <td className={classes.tableCell}></td>
                  <td className={classes.tableCell}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <span
                        className={classes.tableCell}
                        style={{ marginRight: "10px" }}
                      >
                        ကျား
                      </span>{" "}
                      {
                        Number(cardData.gender) === 1 ?
                        <CheckBoxOutlinedIcon/>:
                        <CheckBoxOutlineBlankIcon></CheckBoxOutlineBlankIcon>
                      }
                      <span
                        className={classes.tableCell}
                        style={{ marginLeft: "120px", marginRight: "10px" }}
                      >
                        မ
                      </span>{" "}
                      {
                        Number(cardData.gender) === 2 ?
                        <CheckBoxOutlinedIcon/>:
                        <CheckBoxOutlineBlankIcon></CheckBoxOutlineBlankIcon>
                      }
                    </Box>
                  </td>
                </tr>
                {/* job cat */}
                <tr>
                  <td colSpan={4}>
                    <JobCat></JobCat>
                  </td>
                </tr>
                <tr>
                  <td className={classes.tableCell}>၁၀။</td>
                  <td className={classes.tableCell}>
                    ကိုင်ဆောင်သည့်နိုင်ငံကူးလက်မှတ်
                  </td>
                  <td className={classes.tableCell}>
                    {cardData.passport ? cardData.passport :  <span>&nbsp;</span> }
                    <hr style={{ border: "1px solid #000" }}></hr>
                  </td>
                  <td style={{ minWidth: "150px" }}></td>
                </tr>
                <tr>
                  <td className={classes.tableCell}>၁၁။</td>
                  <td className={classes.tableCell}>သွားရောက်မည့်နိုင်ငံ</td>
                  <td className={classes.tableCell}>
                    ဂျပန်နိုင်ငံ
                    <hr style={{ border: "1px solid #000" }}></hr>
                  </td>
                  <td style={{ minWidth: "150px" }}></td>
                </tr>
                <tr>
                  <td className={classes.tableCell}>၁၂။</td>
                  <td className={classes.tableCell}>
                    အလုပ်လုပ်ကိုင်မည့်ကုမ္ပဏီအမည်
                  </td>
                  <td className={classes.tableCell}>
                    {cardData.company.nameEng ? cardData.company.nameEng :  <span>&nbsp;</span> }
                    <hr style={{ border: "1px solid #000" }}></hr>
                  </td>
                  <td style={{ minWidth: "150px" }}></td>
                </tr>
                <tr>
                  <td className={classes.tableCell}>၁၃။</td>
                  <td className={classes.tableCell} style={{ whiteSpace: 'nowrap' }}>
                    အလုပ်လုပ်ကိုင်မည့်လုပ်ငန်းလိပ်စာ
                  </td>
                  <td className={classes.tableCell}>
                    {cardData.company.addressEng ? cardData.company.addressEng :  <span>&nbsp;</span> }
                    <hr style={{ border: "1px solid #000" }}></hr>
                  </td>
                  <td style={{ minWidth: "150px" }}></td>
                </tr>
                {/* <tr>
                  <td className={classes.tableCell}></td>
                  <td className={classes.tableCell}></td>
                  <td className={classes.tableCell}>
                    <span>&nbsp;</span>
                    <hr style={{ border: "1px solid #000" }}></hr>
                  </td>
                  <td style={{ minWidth: "150px" }}></td>
                </tr> */}
              </tbody>
            </table>
          </Box>
        )}
      </Box>
    );
}

export default SmartCardForm;
