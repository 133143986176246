import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React from 'react'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const JobCat = () => {
    const useStyles = makeStyles({
        container: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end'
        },
        content: {
            fontFamily: 'PdsFont',
            fontSize: '20px',
            lineHeight: '40px'
        }
    })

    const classes = useStyles();

  return ( 
    <Box sx={{
        display: 'flex',
        border: '1px solid black',
        p: '10px',
        mb: '30px',
    }}>
        <table style={{ minWidth: '900px' }}>
            <tbody>
                <tr>
                    <td>
                        <Typography className={classes.content} >၉။ အလုပ်အကိုင်</Typography>
                    </td>
                    <td>
                        <Box className={classes.container}>
                            <Typography className={classes.content}  sx={{ pr: '5px' }}>ဆောက်လုပ်ရေး</Typography>
                            <CheckBoxOutlineBlankIcon />
                        </Box>
                    </td>
                    <td>
                        <Box className={classes.container}>
                            <Typography className={classes.content}  sx={{ pr: '5px' }}>အထွေထွေ</Typography>
                            <CheckBoxOutlineBlankIcon />
                        </Box>
                    </td>
                    <td>
                        <Box className={classes.container}>
                            <Typography className={classes.content}  sx={{ pr: '5px' }}>စိုက်ပျိုးရေး</Typography>
                            <CheckBoxOutlineBlankIcon />
                        </Box>
                    </td>
                    <td>
                        <Box className={classes.container}>
                            <Typography className={classes.content}  sx={{ pr: '5px' }}>ရေလုပ်ငန်း</Typography>
                            <CheckBoxOutlineBlankIcon />
                        </Box>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        <Box className={classes.container}>
                            <Typography className={classes.content}  sx={{ pr: '5px' }}>ကွန်ပျူတာနှင့်ဆက်စပ်လုပ်ငန်း</Typography>
                            <CheckBoxOutlineBlankIcon />
                        </Box>
                    </td>
                    <td>
                        <Box className={classes.container}>
                            <Typography className={classes.content}  sx={{ pr: '5px' }}>အင်ဂျင်နီယာ</Typography>
                            <CheckBoxOutlineBlankIcon />
                        </Box>
                    </td>
                    <td>
                        <Box className={classes.container}>
                            <Typography className={classes.content}  sx={{ pr: '5px' }}>စက်ရုံ</Typography>
                            <CheckBoxOutlineBlankIcon />
                        </Box>
                    </td>
                    <td>
                        <Box className={classes.container}>
                            <Typography className={classes.content}  sx={{ pr: '5px' }}>ငွေကိုင်</Typography>
                            <CheckBoxOutlineBlankIcon />
                        </Box>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        <Box className={classes.container}>
                            <Typography className={classes.content}  sx={{ pr: '5px' }}>သန့်ရှင်းရေး</Typography>
                            <CheckBoxOutlineBlankIcon />
                        </Box>
                    </td>
                    <td>
                        <Box className={classes.container}>
                            <Typography className={classes.content}  sx={{ pr: '5px' }}>စာရင်းကိုင်</Typography>
                            <CheckBoxOutlineBlankIcon />
                        </Box>
                    </td>
                    <td>
                        <Box className={classes.container}>
                            <Typography className={classes.content}  sx={{ pr: '5px' }}>အိမ်အကူ</Typography>
                            <CheckBoxOutlineBlankIcon />
                        </Box>
                    </td>
                    <td>
                        <Box className={classes.container}>
                            <Typography className={classes.content}  sx={{ pr: '5px' }}>ဧည့်ကြို</Typography>
                            <CheckBoxOutlineBlankIcon />
                        </Box>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'end',
                            height: '50px',
                        }}>
                            <Typography className={classes.content}  sx={{ pr: '5px' }}>အရောင်းစာရေး</Typography>
                            <CheckBoxOutlineBlankIcon />
                        </Box>
                    </td>
                    <td colSpan={3}>
                        <Box sx={{
                            display: 'flex',
                            pl: '30px'
                        }}>
                            <Typography className={classes.content}  sx={{ pr: '5px' }}>အခြား</Typography>
                            <Box sx={{
                                display: 'flex',
                                border: '1px solid black',
                                height: '50px',
                                width: '100%'
                            }} />
                        </Box>
                    </td>
                </tr>
            </tbody>
        </table>
    </Box>
  )
}

export default JobCat