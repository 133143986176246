import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { splitDate } from "../../../utils/splitDate";
import { calculateMonthDiff, calculateYearDiff } from "../../../utils/calculateYearDiff";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5px",
    border: '2px solid #000',
  },
  heading: {
    fontSize: '18px',
    lineHeight: '25px',
    fontFamily: "Mincho",
  },
  subHeading: {
    fontSize: '18px',
    lineHeight: '25px',
    fontFamily: "PdsFont",
  },
  centerText: {
    textAlign: 'center',
  },
  padding15: {
    padding: '15px',
    border: '2px solid #000',
  },
  padding10: {
    padding: '5px',
    border: '2px solid #000',
  },
  padding5: {
    padding: '5px',
    border: '2px solid #000',
  },
  // Add more styles as needed
}));

const Coe3_3 = ({info}) => {
  const classes = useStyles();
  const [ currentJob,setCurrentJob ] = useState({})
  const [ jobExp,setJobExp ] = useState([])
  const [ eduArr,setEduArr ] = useState([])
  const [ yearDiff,setYearDiff ] = useState(0)
  const [ monthDiff,setMonthDiff ] = useState(0)

  useEffect(()=>{
    if(info && info.experiences){
      const filledExpData = Array.from({ length: info.experiences?.length > 4 ?  info.experiences.length : 4 }, (_, index) =>
        info.experiences?.[index] || {}
      );
      const filledEduData = Array.from({ length: info.education?.length > 4 ?  info.education.length : 4 }, (_, index) =>
        info.education?.[index] || {}
      );
      setJobExp(filledExpData)
      setEduArr(filledEduData)
      const lastJob = info.experiences[info.experiences.length -1 ]
      setCurrentJob(lastJob)
      setYearDiff(calculateYearDiff(lastJob.start, dayjs().format('YYYY-MM')))
      setMonthDiff(calculateMonthDiff(lastJob.start, dayjs().format('YYYY-MM')))
    }
  },[info])


  return (
    <>
      {/* 5 -> ပညာအရည်အချင်း */}
      <tr>
        <td colSpan={2} rowSpan={eduArr.length + 1} className={classes.root}>
          <Box sx={{ display: "flex" }}>
            <Typography className={classes.heading} mr={1}>
              ⑧
            </Typography>
            <Box>
              <Typography className={classes.heading}>学歴</Typography>
              <Typography className={classes.subHeading}>
                ပညာအရည်အချင်း
              </Typography>
            </Box>
          </Box>
        </td>
        <td className={`${classes.centerText} ${classes.root}`}>
          <Typography className={classes.heading}>期間</Typography>
          <Typography className={classes.subHeading}>ကာလ</Typography>
        </td>
        <td colSpan={3} className={`${classes.centerText} ${classes.root}`}>
          <Typography className={classes.heading}>学校名</Typography>
          <Typography className={classes.subHeading}>
            တက်ရောက်ခဲ့သည့်ကျောင်းအမည်
          </Typography>
        </td>
      </tr>
      {eduArr?.map((edu, index) => (
        <tr key={index}>
          {Object.keys(edu).length === 0 ? (
            <>
              <td className={`${classes.padding15} ${classes.root}`}></td>
              <td
                colSpan={3}
                className={`${classes.padding15} ${classes.root}`}
              ></td>
            </>
          ) : (
            <>
              <td className={`${classes.root}`} style={{ padding: "0px" }}>
                <Typography
                  sx={{
                    fontFamily: "Mincho",
                    fontSize: "16px",
                    textAlign: "center",
                  }}
                >
                  {splitDate(edu.experience_start_date).year} 年 &nbsp;
                  {splitDate(edu.experience_start_date).month} 月
                  <span style={{ fontSize: "20px", fontFamily: "Mincho" }}>
                    ～
                  </span>
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Mincho",
                    fontSize: "16px",
                    textAlign: "center",
                    marginRight: "20px",
                  }}
                >
                  {splitDate(edu.experience_end_date).year} 年 &nbsp;
                  {splitDate(edu.experience_end_date).month} 月
                </Typography>
              </td>
              <td colSpan={3} className={`${classes.padding5} ${classes.root}`}>
                <Typography sx={{ fontFamily: "Mincho", fontSize: "16px" }}>
                  {edu.education_eng}
                  {/* {edu.major ? ` (${edu.major}) 卒業` : ""} */}
                  {/* {edu.lastAttandentYear ? ` (${edu.lastAttandentYear})` : ""} */}
                </Typography>
              </td>
            </>
          )}
        </tr>
      ))}
      {/* 6 -> လုပ်ငန်းအတွေအကြုံ */}
      <tr>
        <td colSpan={2} rowSpan={jobExp.length + 1} className={classes.root}>
          <Box sx={{ display: "flex" }}>
            <Typography className={classes.heading} mr={1}></Typography>
            <Typography className={classes.heading} mr={3}>
              ⑨&nbsp;職歴
            </Typography>
            <Typography className={classes.subHeading}>
              လုပ်ငန်းအတွေအကြုံ
            </Typography>
          </Box>
        </td>
        <td className={`${classes.centerText} ${classes.root}`}>
          <Typography className={classes.heading}>期間</Typography>
          <Typography className={classes.subHeading}>ကာလ</Typography>
        </td>
        <td colSpan={3} className={`${classes.centerText} ${classes.root}`}>
          <Typography className={classes.heading}>就職先名（職種）</Typography>
          <Typography className={classes.subHeading}>
            ကုမ္ပဏီအမည် (လုပ်ငန်းအမျိုးအစား)
          </Typography>
        </td>
      </tr>
      {jobExp?.map((exp, index) => (
        <tr key={index}>
          {Object.keys(exp).length === 0 ? (
            <>
              <td className={`${classes.padding15} ${classes.root}`}></td>
              <td
                colSpan={3}
                className={`${classes.padding15} ${classes.root}`}
              ></td>
            </>
          ) : (
            <>
              <td className={`${classes.padding5} ${classes.root}`}>
                <Typography
                  sx={{
                    fontFamily: "Mincho",
                    fontSize: "16px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {splitDate(exp.start).year} 年{splitDate(exp.start).month} 月
                  <span style={{ fontSize: "20px", fontFamily: "Mincho" }}>
                    {" "}
                    ～{" "}
                  </span>
                  {exp.end === ""
                    ? "現在"
                    : `${splitDate(exp.end).year} 年 ${
                        splitDate(exp.end).month
                      } 月`}
                </Typography>
              </td>
              <td colSpan={3} className={`${classes.padding5} ${classes.root}`}>
                <Typography sx={{ fontFamily: "Mincho", fontSize: "16px" }}>
                  {exp.company}({exp.businessType})
                </Typography>
              </td>
            </>
          )}
        </tr>
      ))}
      {/* 7 -> သင်ယူမည့်နည်းပညာနှင့် ပတ်သတ်သောလုပ်ငန်း အတွေအကြုံ */}
      <tr>
        <td colSpan={2} className={`${classes.root}`}>
          <Box sx={{ display: "flex" }}>
            <Typography className={classes.heading} mr={1}>
              ⑩
            </Typography>
            <Box>
              <Typography className={classes.heading}>
                修得等をしようとする技能等に係る職歴
              </Typography>
              <Typography className={classes.subHeading}>
                သင်ယူမည့်နည်းပညာနှင့် <br /> ပတ်သတ်သောလုပ်ငန်း <br /> အတွေအကြုံ
              </Typography>
            </Box>
          </Box>
        </td>
        <td className={`${classes.root}`} style={{ width: "250px" }}>
          <table>
            <tbody>
              <tr>
                <td className={classes.heading}>{currentJob.businessType}</td>
                <td className={classes.heading}>職&nbsp;</td>
                <td className={classes.subHeading}>အလုပ်</td>
              </tr>
              <tr>
                <td className={classes.heading} style={{ textAlign: "right" }}>
                  {yearDiff > 0 ? `${yearDiff}年` : ""}{" "}
                  {monthDiff > 0 ? `${monthDiff}月` : ""} &nbsp;
                </td>
                <td className={classes.heading}>年</td>
                <td className={classes.subHeading} style={{ textAlign: "end" }}>
                  နှစ်
                </td>
              </tr>
              <tr>
                <td className={`${classes.heading} ${classes.centerText}`}>
                  職
                </td>
                <td className={`${classes.heading}`} colSpan={2}>
                  年
                </td>
              </tr>
              <tr>
                <td className={`${classes.subHeading} ${classes.centerText}`}>
                  အလုပ်
                </td>
                <td className={`${classes.subHeading}`} colSpan={2}>
                  နှစ်
                </td>
              </tr>
            </tbody>
          </table>
        </td>
        <td className={`${classes.padding10} ${classes.root}`}>
          <Typography className={classes.heading}>
            母国語以外の語学力
          </Typography>
          <p>
            <span
              className={classes.subHeading}
              style={{ whiteSpace: "nowrap" }}
            >
              မိခင်ဘာသာစကားအပြင်
            </span>
            <br />
            <span className={classes.subHeading}>ကျွမ်းကျင်သောဘာသာ စကား</span>
          </p>
        </td>
        <td colSpan={2} className={`${classes.padding10} ${classes.root}`}>
          <Typography style={{ fontSize: "16px" }}>
            &nbsp;&nbsp;&nbsp;&nbsp;日本語&nbsp;&nbsp;&nbsp;（水準：　レベル ）
          </Typography>
          <Typography
            style={{
              fontSize: "16px",
              lineHeight: "35px",
              fontFamily: "PdsFont",
            }}
          >
            ဂျပန်စာ အရည်အချင်း
          </Typography>
          <Typography style={{ fontSize: "16px" }}>
            &nbsp;&nbsp;&nbsp;&nbsp;英語&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（水準：&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            ）
          </Typography>
          <Typography
            style={{
              fontSize: "16px",
              lineHeight: "35px",
              fontFamily: "PdsFont",
            }}
          >
            အင်္ဂလိပ်စာ အရည်အချင်း
          </Typography>
          <Typography style={{ fontSize: "16px" }}>
            &nbsp;&nbsp;&nbsp;&nbsp;その他（&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;）
            အခြား
          </Typography>
        </td>
      </tr>
    </>
  );
};

export default Coe3_3;
