import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import { splitDate } from "../../../utils/splitDate";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  screen: {
    pageBreakAfter: "always",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontSize: "16px",
    lineHeight: "35px",
    fontFamily: "Mincho",
  },
  subTitle: {
    fontSize: "16px",
    lineHeight: "35px",
    fontFamily: "Mincho",
  },
  centerTitle: {
    textAlign: "center",
    fontSize: "22px",
    letterSpacing: "15px",
    lineHeight: "35px",
    fontFamily: "Mincho",
    marginY: 2,
  },
  centerSubtitle: {
    textAlign: "center",
    fontSize: "21px",
    lineHeight: "35px",
    fontFamily: "Mincho",
    marginBottom: 3,
  },
  content: {
    fontSize: "20px",
    lineHeight: "35px",
    fontFamily: "Mincho",
  },
  table: {
    borderCollapse: "collapse",
    width: "100%",
    border: "2px solid black",
  },
  tableCell: {
    padding: "5px",
    border: "2px solid #000",
  },
});

const Coe2 = ({index}) => {
  const classes = useStyles();
  const { data: printData } = useSelector(state=> state.PrintData)
  const [ mmCompanyInfo, setMmCompanyInfo ] = useState(null)
  const [ memberData,setMemberData ] = useState([])

  useEffect(()=>{
    if(printData && printData.data && printData.data.members){
      const data = printData.data.members[index]
      setMemberData(data)
      setMmCompanyInfo(data.experiences[data.experiences.length - 1])
    }
  },[printData, index])

  return (
    <Box className={classes.screen} sx={{ px: 15, pt: 10 }}>
      {
        mmCompanyInfo &&
        <>
          <Box className={classes.header}>
          <Box>
            <Typography className={classes.title}>
              参考様式第１-28号（規則第８条第26号関係）
            </Typography>
            <Typography className={classes.subTitle}>
              Ｄ・Ｅ・Ｆ（規則第10条第２項第３号ホに適合することを証する書面）
            </Typography>
          </Box>
          <Typography className={classes.title}>（日本工業規格Ａ列４）</Typography>
        </Box>
        <Typography className={classes.centerTitle} sx={{ my: 2 }}>外国の所属機関による証明書</Typography>
        <Typography className={classes.centerSubtitle} sx={{ mb: 2 }}>
          （団体監理型技能実習）
        </Typography>
        <Typography className={classes.content} sx={{ my: 2 }}>
          技能実習生（候補者を含む。）について、下記の事項を証明します。
        </Typography>
        <Typography className={classes.content} sx={{ textAlign: 'center', mb: 1 }}>記</Typography>
        <table className={classes.table} border="2">
          <tbody>
          <tr>
              <td rowSpan={2} colSpan={2} className={classes.tableCell} width="35%">
                  <Typography className={classes.content}>①技能実習生の氏名</Typography>
                  <Typography sx={{ lineHeight: '25px', fontSize: "14px", fontFamily: 'MinCho' }}>
                  ※ 複数名について記載する場合には適宜欄を追加すること。記載しきれない場合には、別紙に記載することも可とし、当欄には「別紙のとおり」と記載すること。
                  </Typography>
              </td>
              <td className={classes.tableCell}>
                  <Typography className={classes.content}>ローマ字</Typography>
              </td>
              <td className={classes.tableCell}>
                  <Typography className={classes.content}>{memberData.nameEng}</Typography>
              </td>
              </tr>
              <tr>
              <td className={classes.tableCell}>
                  <Typography className={classes.content}>漢字</Typography>
              </td>
              <td className={classes.tableCell}>
                  {/* katakana name */}
                  <Typography className={classes.content}></Typography>
              </td>
              </tr>
              <tr>
              <td rowSpan={3} width="16%" className={classes.tableCell}>
                  <Typography className={classes.content}>②技能実習生の所属</Typography>
              </td>
              <td className={classes.tableCell}>
                  <Typography className={classes.content}>所属事業所名</Typography>
              </td>
              <td colSpan={2} className={classes.tableCell}>
                  <Typography className={classes.content}>{memberData.experiences[memberData.experiences.length - 1].company}</Typography>
              </td>
              </tr>
              <tr>
              <td className={classes.tableCell}>
                  <Typography className={classes.content}>所属部署名</Typography>
              </td>
              <td colSpan={2} className={classes.tableCell}></td>
              </tr>
              <tr>
              <td className={classes.tableCell}>
                  <Typography className={classes.content}>職種</Typography>
              </td>
              <td colSpan={2} className={classes.tableCell}>
                  <Typography className={classes.content}>{memberData.experiences[memberData.experiences.length - 1].businessType}</Typography>
              </td>
              </tr>
              <tr>
              <td colSpan={2} className={classes.tableCell}>
                  <Typography className={classes.content}>③技能実習の実施の経緯</Typography>
              </td>
              <td colSpan={2} className={classes.tableCell}>
                  <Typography className={classes.content}>
                  <CheckBoxOutlinedIcon className={classes.content} />
                  所属機関からの推薦
                  </Typography>
                  <Typography className={classes.content}>(推薦理由：{memberData.experiences[memberData.experiences.length - 1].businessType} に係る技能等を修得するため)</Typography>
                  <Typography className={classes.content}>
                  <CheckBoxOutlineBlankOutlinedIcon className={classes.content} />
                  技能実習生からの希望
                  </Typography>
                  <Typography className={classes.content}>
                  <CheckBoxOutlineBlankOutlinedIcon className={classes.content} />
                  その他（ ）
                  </Typography>
              </td>
              </tr>
              <tr>
              <td colSpan={2} className={classes.tableCell}>
                  <Typography className={classes.content}>④技能実習の期間中の処遇</Typography>
              </td>
              <td colSpan={2} className={classes.tableCell}>
                  <Typography className={classes.content}>
                  <CheckBoxOutlinedIcon className={classes.content} />
                  技能実習生との関係を継続（「現職にとどめる」、「休職とする」など）
                  </Typography>
                  <Typography className={classes.content}>
                  <CheckBoxOutlineBlankOutlinedIcon className={classes.content} />
                  退職
                  </Typography>
                  <Typography className={classes.content}>
                  <CheckBoxOutlineBlankOutlinedIcon className={classes.content} />
                  その他（ ）
                  </Typography>
              </td>
              </tr>
              <tr>
              <td colSpan={2} className={classes.tableCell}>
                  <Typography className={classes.content}>⑤技能実習の終了後の措置予定</Typography>
              </td>
              <td colSpan={2} className={classes.tableCell}>
                  <Typography className={classes.content}>
                  <CheckBoxOutlinedIcon className={classes.content} />
                  復職 （事業所：{memberData.experiences[memberData.experiences.length - 1].company} 、 職種：{memberData.experiences[memberData.experiences.length - 1].businessType} ）
                  </Typography>
                  <Typography className={classes.content}>
                  <CheckBoxOutlineBlankOutlinedIcon className={classes.content} />
                  復職予定なし
                  </Typography>
                  <Typography className={classes.content}>
                  <CheckBoxOutlineBlankOutlinedIcon className={classes.content} />
                  未定
                  </Typography>
              </td>
              </tr>
          </tbody>
        </table>
        <Typography className={classes.content}>（注意）</Typography>
        <Typography className={classes.content}>
          １ ①は、ローマ字で旅券（未発給の場合、発給申請において用いるもの）と同一の氏名を記載するほか、漢字の氏名がある場合にはローマ字の氏名と併せて、漢字の氏名も記載すること。
        </Typography>
        <Typography className={classes.content}>
          ２ ③は、所属機関からの推薦である場合には、その推薦理由を記載すること。
        </Typography>
        <Typography className={classes.content}>
          ３ 個人農業者や家族経営の事業に従事していた者等の場合は、地方政府、業界団体等による証明でも差し支えない。
        </Typography>
        <Typography className={classes.content}>
          上記の記載内容は、事実と相違ありません。また、団体監理型技能実習の準備に関し、技能実習に関する法令に違反することは、決していたしません。
        </Typography>
        <Typography className={classes.content} sx={{ textAlign: 'end' }}>
          {splitDate(memberData.coeDcDate).year} 年 &nbsp;
          {splitDate(memberData.coeDcDate).month} 月 &nbsp;
          {splitDate(memberData.coeDcDate).day} 日 &nbsp;
          作成</Typography>
        <Box sx={{ width: '70%', ml: 'auto' }}>
          <Box className={classes.header} sx={{ textAlign: 'end' }}>
            <Typography className={classes.content}>
              外国の所属機関の名称
            </Typography>
            <Typography className={classes.content}>
              {mmCompanyInfo.company}
            </Typography>
          </Box>
          <Box className={classes.header} sx={{ textAlign: 'end' }}>
            <Typography className={classes.content}>
              作成責任者 役職・氏名 代表者
            </Typography>
            <Typography className={classes.content}>{mmCompanyInfo.chairmanName} ㊞</Typography>
          </Box>
        </Box>
        </>
      }
    </Box>
  );
};

export default Coe2;
