import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { splitDate } from "../../../../utils/splitDate";
import { getFullMonthName } from "../../../../utils/getFullMonthName";

const useStyles = makeStyles({
  screen: {
    pageBreakAfter: "always",
  },
});

const DlEng = () => {
  const [dmData, setDmData] = useState(null);
  const pdfData = useSelector((state) => state.PrintData.data);
  const classes = useStyles();

  const demandList = dmData
    ? [
        {
          title: "Company Name",
          data: dmData.company.nameEng,
        },
        {
          title: "Company Address, Telephone, Email",
          data: `${dmData.company.addressEng}, TEL: ${dmData.company.phone}, EMAIL: ${dmData.company.email}`,
        },
        {
          title: "Number of workers",
          data: `${dmData.numberOfWorkers.total} persons`,
        },
        {
          title: "Gender",
          data: `${
            dmData.numberOfWorkers.male > 0 &&
            dmData.numberOfWorkers.female === 0
              ? "Male"
              : dmData.numberOfWorkers.male === 0 &&
                dmData.numberOfWorkers.female > 0
              ? "Female"
              : `Male(${dmData.numberOfWorkers.male})/ Female(${dmData.numberOfWorkers.female})`
          }`,
        },
        {
          title: "Age",
          data: dmData.ageLimitationENG,
        },
        {
          title: "Qualification",
          data: "Japanese Level N4,",
          data2: `${dmData.qualificationENG}`,
        },
        {
          title: "Health and Must be fit physically",
          data: "To pass the medical examination before departure",
        },
        {
          title: "Job category",
          data: dmData.jobCategoryENG,
        },
        {
          title: "Place of Working",
          data: dmData.workPlace,
        },
        {
          title: "Working hours",
          data: dmData.workingHoursENG,
        },
        {
          title: "Basic Salary",
          data: `${Number(dmData.basicSalary).toLocaleString()} Yen/Month`,
        },
        {
          title: "Salary Payment Method",
          data:
            Number(dmData.salaryPaymentMethod) === 1
              ? "Bank Transfer"
              : "By Cash",
        },
        {
          title: "Overtime",
          data: dmData.overtimeENG,
        },
        {
          title: "Working days",
          data: dmData.workingDayEng,
        },
        {
          title: "Holidays/ Rest days",
          data: dmData.holidayENG,
        },
        {
          title: "Accommodation",
          data: "Arrange by the company",
          data2: `Monthly accommodation payment ${Number(
            dmData.accommodation
          ).toLocaleString()}-Yen by worker`,
        },
        {
          title: "Transportation",
          data: dmData.transportationENG,
        },
        {
          title: "Air Ticket",
          data: dmData.airTicketENG,
        },
        {
          title: "Foods",
          data: "Arrange by the worker by themselves",
        },
        {
          title: "Contract Period",
          data: "1 year (will be updated every day)",
        },
        {
          title: "Health Insurance",
          data: "Health insurance (included in Social insurance)",
        },
        {
          title: "Other Condition",
          data: "Follow the employment regulations of company",
        },
      ]
    : [];

  useEffect(() => {
    if (pdfData) {
      const { data } = pdfData;
      setDmData(data);
    }
  }, [pdfData]);
  return (
    <Box className={classes.screen} sx={{ px: 15, py: 10 }}>
      {dmData && (
        <Box>
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <Typography
              sx={{
                fontFamily: "PdsFontNumber",
                fontSize: "20px",
                fontWeight: "bold",
                mb: 2,
              }}
            >
              {dmData.organization.nameEng}
            </Typography>
            <Typography
              sx={{
                fontFamily: "PdsFont",
                lineHeight: "30px",
                fontSize: "20px",
              }}
            >
              {dmData.organization.addressEng}
            </Typography>
            <hr style={{ margin: "10px 0px", border: "1px solid #000" }}></hr>
            <Typography
              sx={{
                fontFamily: "PdsFont",
                lineHeight: "30px",
                fontSize: "20px",
              }}
            >
              FAX: {dmData.organization.fax}, E-mail:{" "}
              {dmData.organization.email}
            </Typography>
            <hr style={{ margin: "10px 0px", border: "1px solid #000" }}></hr>
          </Box>
          <Typography
            sx={{
              my: 5,
              textAlign: "end",
              fontFamily: "PdsFont",
              lineHeight: "30px",
              fontSize: "20px",
            }}
          >
            Date {splitDate(dmData.demandLetterApplicationDate).day}{" "}
            {getFullMonthName(
              splitDate(dmData.demandLetterApplicationDate).month
            )}{" "}
            {splitDate(dmData.demandLetterApplicationDate).year}
          </Typography>
          <Typography
            sx={{ fontFamily: "PdsFont", lineHeight: "30px", fontSize: "22px" }}
          >
            CLOVER MANDALAY Co.,Ltd
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Typography
                sx={{
                  fontFamily: "PdsFont",
                  lineHeight: "30px",
                  fontSize: "20px",
                }}
              >
                Address
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Typography
                  sx={{
                    pr: 1,
                    fontFamily: "PdsFont",
                    lineHeight: "30px",
                    fontSize: "20px",
                  }}
                >
                  :
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "PdsFont",
                    lineHeight: "30px",
                    fontSize: "20px",
                  }}
                >
                  〒 05011၊ No.24, Kant Kaw Street, Between 22nd x 23rd , 88th x
                  89th Streets, Aung Myay Thar Zan Tsp, Mandalay, Myanmar
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Typography
                sx={{
                  fontFamily: "PdsFont",
                  lineHeight: "30px",
                  fontSize: "20px",
                }}
              >
                Telephone
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Typography
                  sx={{
                    pr: 1,
                    fontFamily: "PdsFont",
                    lineHeight: "30px",
                    fontSize: "20px",
                  }}
                >
                  :
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "PdsFont",
                    lineHeight: "30px",
                    fontSize: "20px",
                  }}
                >
                  +95-9-769-80-4488
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Typography
                sx={{
                  fontFamily: "PdsFont",
                  lineHeight: "30px",
                  fontSize: "20px",
                }}
              >
                Email
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Typography
                  sx={{
                    pr: 1,
                    fontFamily: "PdsFont",
                    lineHeight: "30px",
                    fontSize: "20px",
                  }}
                >
                  :
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "PdsFont",
                    lineHeight: "30px",
                    fontSize: "20px",
                  }}
                >
                  info@clovermandalay.com
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Typography
            sx={{
              fontSize: "30px",
              textAlign: "center",
              fontFamily: "PdsFont",
              lineHeight: "30px",
              my: 5,
            }}
          >
            Demand Letter for Skilled Worker Recruitment
          </Typography>
          <Typography
            sx={{
              textIndent: "50px",
              mb: 3,
              fontFamily: "PdsFont",
              lineHeight: "30px",
              fontSize: "20px",
              textAlign: "justify",
            }}
          >
            This is to confirm that we hereby appointment and authorize your
            agency to recruit Myanmar Specified Skilled Workers who completed
            technical intern training (2) years for ({dmData.jobCategoryENG}) to
            be employed in our Company. You are also to have the relevant
            authority’s approval regarding the recruitment on behalf.
          </Typography>
          <Typography
            sx={{
              mb: 3,
              fontFamily: "PdsFont",
              lineHeight: "30px",
              fontSize: "20px",
            }}
          >
            Our recruitment and terms of employment are as follows;
          </Typography>
          {demandList.map((demandData, index) => (
            <Grid
              key={index}
              container
              spacing={2}
              sx={{
                pb: 3,
                pageBreakAfter: index === 11 ? "always" : "",
                pt: index === 12 ? 14 : 0,
              }}
            >
              <Grid item xs={4}>
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Typography
                    sx={{
                      mr: 2,
                      fontFamily: "PdsFont",
                      lineHeight: "30px",
                      fontSize: "20px",
                    }}
                  >
                    {index + 1}.
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "PdsFont",
                      lineHeight: "30px",
                      fontSize: "20px",
                    }}
                  >
                    {demandData.title}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  sx={{
                    fontFamily: "PdsFont",
                    lineHeight: "30px",
                    fontSize: "20px",
                  }}
                >
                  : {demandData.data}
                </Typography>
                <Typography
                  sx={{
                    pl: 1,
                    fontFamily: "PdsFont",
                    lineHeight: "30px",
                    fontSize: "20px",
                  }}
                >
                  {demandData.data2}
                </Typography>
              </Grid>
            </Grid>
          ))}
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Box
              sx={{
                textAlign: "center",
                pt: 10,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "PdsFont",
                  lineHeight: "30px",
                  fontSize: "20px",
                  mb: 3,
                }}
              >
                Yours Sincerely,
              </Typography>
              {/* <Typography sx={{ fontFamily: 'PdsFont', lineHeight: '30px' , fontSize: '20px', mb: 3 }}>Signature with seal</Typography> */}
              <Typography
                sx={{
                  fontFamily: "PdsFont",
                  lineHeight: "30px",
                  fontSize: "20px",
                  mb: 3,
                }}
              >
                {dmData.organization.nameEng}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "PdsFont",
                  lineHeight: "30px",
                  fontSize: "20px",
                  mb: 3,
                }}
              >
                Chairman: {dmData.organization.chairmanJp}{" "}
                {dmData.organization.chairmanEng}
              </Typography>
            </Box>
          </Box>
          <Typography
            sx={{
              fontFamily: "PdsFont",
              fontSize: "20px",
              textAlign: "justify",
              textIndent: "50px",
              marginTop: "30px",
            }}
          >
            A new table has been added, which lists the list of demand letters
            that technical interns must submit to the embassy for review.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default DlEng;
