import { Typography } from '@mui/material'
import React from 'react'

const Coe11_11_tableRow = ({index}) => {
  return (
    <tr>
      <td
        style={{
          width: "40px",
          padding: "5px",
          fontFamily: "Mincho",
          fontSize: "16px",
          whiteSpace: "nowrap",
          textAlign: "center",
          border: "2px solid #000",
        }}
      >
        {index + 1}
      </td>
      <td
        style={{
          width: "150px",
          padding: "5px",
          fontFamily: "Mincho",
          fontSize: "16px",
          whiteSpace: "nowrap",
          textAlign: "center",
          border: "2px solid #000",
        }}
      ></td>
      <td
        style={{
          width: "150px",
          padding: "5px",
          fontFamily: "Mincho",
          fontSize: "16px",
          whiteSpace: "nowrap",
          textAlign: "center",
          border: "2px solid #000",
        }}
      ></td>
      <td
        style={{
          width: "150px",
          padding: "10px 0px",
          fontFamily: "Mincho",
          fontSize: "16px",
          whiteSpace: "nowrap",
          border: "2px solid #000",
          textAlign: 'center'
        }}
      >
        <Typography
          sx={{ textAlign: "center", fontFamily: "Mincho", fontSize: "16px" }}
        >
          種別
        </Typography>
        <hr style={{ border: "1px solid #000" }} />
        公的機関 <br />
        教育機関 
      </td>
      <td
        style={{
          width: "160px",
          padding: "5px",
          fontFamily: "Mincho",
          fontSize: "16px",
          whiteSpace: "nowrap",
          textAlign: "center",
          border: "2px solid #000",
        }}
      >
        
      </td>
      <td
        style={{
          width: "100px",
          padding: "5px",
          fontFamily: "Mincho",
          fontSize: "16px",
          whiteSpace: "nowrap",
          textAlign: "center",
          border: "2px solid #000",
          lineHeight: "20px",
          letterSpacing: '10px'
        }}
      >
        年 月 日 <br /> ～ <br />年 月 日
      </td>
      <td
        style={{
          width: "100px",
          verticalAlign: "bottom",
          padding: "5px",
          fontFamily: "Mincho",
          fontSize: "16px",
          whiteSpace: "nowrap",
          textAlign: "center",
          border: "2px solid #000",
        }}
      >
        時間
      </td>
    </tr>
  );
}

export default Coe11_11_tableRow