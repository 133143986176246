import React, { useEffect, useState } from 'react'
import CoverLetter from '../common/CoverLetter'
import EmployerContract from '../common/EmployerContract'
import AgencyContract from '../common/AgencyContract'
import AgencyList from '../common/AgencyList'
import DlEng from './DlEng'
import DlMm from './DlMm'
import { useDispatch, useSelector } from 'react-redux'
import { getDataOfDoc } from '../../../../slices/backOffice/pdfDataSlice'
import { useParams } from 'react-router-dom'
import Loading from '../../../utils/Loading'
import ErrorDialog from '../../../utils/ErrorDialog'
import DemandLetterTable from './DemandLetterTable'

const DlTit = ({gpOrMem,id,toggleDialog}) => {
  const dispatch = useDispatch()
  const gpId = useParams().id
  const [alertToggle, setAlertToggle] = useState(false);
  const { loading } = useSelector(state=> state.PrintData)

  const handleConfirmAlert = async () => {
    setAlertToggle(false);
    toggleDialog(false)
  };

  const getData = async()=>{
    const dispatchData = await dispatch(getDataOfDoc({gpId: gpId, docType: 'demand-letter', userId: gpOrMem === 'member' ? id : null}))
    if(dispatchData.error){
      setAlertToggle(true)
    }
  } 

  useEffect(()=>{
    getData()
  },[])

  return (
    <div>
      {
        loading ?
        <Loading></Loading> :
        <>
          <ErrorDialog
            type={'danger'}
            confrim={handleConfirmAlert}
            toggle={alertToggle}
            setToggle={setAlertToggle}
            title={"Oops - something went wrong!"}
            content={"This page isn't available at that moment, please try again later."} ></ErrorDialog>
          <CoverLetter type={'tit'}></CoverLetter>
          {/* <style>{getPageMargins()}</style> */}
          <EmployerContract type={'tit'}></EmployerContract>
          <AgencyContract type={'tit'}></AgencyContract>
          <AgencyList type={'tit'}></AgencyList>
          {/* <div style={{ height: '00px', width: '100%' }}></div> */}
          <DlEng></DlEng>
          <DlMm></DlMm>
          <DemandLetterTable />
        </>
      }
      
    </div>
  )
}

export default DlTit
