import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const NDCoverListOfWorker = ({ listOfWorkers }) => {
  const [preData, setPreData] = useState(null);
  const pdfData = useSelector((state) => state.PrintData.data);

  useEffect(() => {
    if (pdfData) {
      const { data } = pdfData;
      setPreData(data);
    }
  }, [pdfData]);

  return (
    <div
      style={{
        marginTop: "500px",
      }}
    >
      <table border="1" style={{ borderCollapse: "collapse" }}>
        <tbody>
          <tr>
            <td
              style={{
                textAlign: "center",
                verticalAlign: "middle",
                fontFamily: "PdsFont",
                fontSize: "18px",
                padding: "5px 10px",
              }}
            >
              Sr.No
            </td>
            <td
              style={{
                textAlign: "center",
                verticalAlign: "middle",
                fontFamily: "PdsFont",
                fontSize: "18px",
                padding: "5px 10px",
              }}
            >
              Name
            </td>
            <td
              style={{
                textAlign: "center",
                verticalAlign: "middle",
                fontFamily: "PdsFont",
                fontSize: "18px",
                padding: "5px 10px",
              }}
            >
              Male/ <br /> Female
            </td>
            <td
              style={{
                textAlign: "center",
                verticalAlign: "middle",
                fontFamily: "PdsFont",
                fontSize: "18px",
                padding: "5px 10px",
              }}
            >
              Date of Birth
            </td>
            <td
              style={{
                textAlign: "center",
                verticalAlign: "middle",
                fontFamily: "PdsFont",
                fontSize: "18px",
                padding: "5px 10px",
              }}
            >
              NRC.No/
              <br />
              Passport No.
            </td>
            <td
              style={{
                textAlign: "center",
                verticalAlign: "middle",
                fontFamily: "PdsFont",
                fontSize: "18px",
                padding: "5px 10px",
              }}
            >
              Name of Father
            </td>
            <td
              style={{
                textAlign: "center",
                verticalAlign: "middle",
                fontFamily: "PdsFont",
                fontSize: "18px",
                padding: "5px 10px",
              }}
            >
              Address
            </td>
            <td
              style={{
                textAlign: "center",
                verticalAlign: "middle",
                fontFamily: "PdsFont",
                fontSize: "18px",
                padding: "5px 10px",
              }}
            >
              Contact Phone
            </td>
          </tr>
          {preData?.listOfWorkers?.map((person, index) => (
            <tr key={index}>
              <td
                style={{
                  textAlign: "center",
                  verticalAlign: "middle",
                  fontFamily: "PdsFont",
                  fontSize: "18px",
                  padding: "5px 10px",
                }}
              >
                {index + 1}
              </td>
              <td
                style={{
                  verticalAlign: "middle",
                  fontFamily: "PdsFont",
                  fontSize: "18px",
                  padding: "5px 10px",
                }}
              >
                {person.nameEng}
              </td>
              <td
                style={{
                  textAlign: "center",
                  verticalAlign: "middle",
                  fontFamily: "PdsFont",
                  fontSize: "18px",
                  padding: "5px 10px",
                }}
              >
                {person.gender === "女" ? "Female" : "Male"}
              </td>
              <td
                style={{
                  textAlign: "center",
                  verticalAlign: "middle",
                  fontFamily: "PdsFont",
                  fontSize: "18px",
                  padding: "5px 10px",
                }}
              >
                {dayjs(person.dateOfBirth).format("DD/MM/YYYY")}
              </td>
              <td
                style={{
                  verticalAlign: "middle",
                  fontFamily: "PdsFont",
                  fontSize: "18px",
                  padding: "5px 10px",
                }}
              >
                {`NRC: ${person.nrcEng ? person.nrcEng : "-"}`}
                <br />
                {`Passport : ${person.passport ? person.passport : "-"}`}
              </td>
              <td
                style={{
                  verticalAlign: "middle",
                  fontFamily: "PdsFont",
                  fontSize: "18px",
                  padding: "5px 10px",
                }}
              >
                {person.fatherNameEng}
              </td>
              <td
                style={{
                  verticalAlign: "middle",
                  fontFamily: "PdsFont",
                  fontSize: "18px",
                  padding: "5px 10px",
                }}
              >
                {`${person.addressNoEng ? `${person.addressNoEng},` : ""}`}
                {`${person.roadEng ? `${person.roadEng} Street,` : ""}`}
                {`${
                  person.wardOrVillageEng ? `${person.wardOrVillageEng}, ` : ""
                }`}
                {`${
                  person.townshipEng ? `${person.townshipEng} Township,` : ""
                }`}
                {`${
                  person.stateOrDivisionEng
                    ? `${person.stateOrDivisionEng}`
                    : ""
                }`}
              </td>
              <td
                style={{
                  textAlign: "center",
                  verticalAlign: "middle",
                  fontFamily: "PdsFont",
                  fontSize: "18px",
                  padding: "5px 10px",
                }}
              >
                {person.phone}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default NDCoverListOfWorker;
