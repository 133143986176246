import React, { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
import { Box, Typography } from "@mui/material";
import letterHead from "../../../../assets/images/letter_head.png";
import { useSelector } from "react-redux";
import waterMark from "../../../../assets/images/clover-rm-bg.png";
import { splitDate } from "../../../../utils/splitDate";
import { convertToMyanmarNumbers } from "../../../../utils/enTomm";
import { getFullMonthName } from "../../../../utils/getFullMonthName";
import { getBurmeseMonthName } from "../../../../utils/getBurmeseMonthName";
import dayjs from "dayjs";

const useStyles = makeStyles({
    screen: {
        pageBreakAfter: 'always',
        // display: 'flex',
        // flexDirection: 'column',
        // alignItems: 'center',
        minHeight: '100vh',
    },
});

const NDCoverLetter = ({type}) => {
    const [ preData,setPreData ] = useState(null)
    const pdfData = useSelector(state=> state.PrintData.data)
    const classes = useStyles();
    
    useEffect(()=>{
      if(pdfData){
        const { data } = pdfData
        setPreData(data)
      }
    },[pdfData])


    return (
      <div className={classes.screen}>
        <img src={letterHead} className="letterHead"></img>
        {preData && preData.predeparture && (
          <Box
            sx={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.78), rgba(255, 255, 255, 0.78)), url(${waterMark})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "top",
              backgroundSize: "60%",
              mx: "auto",
              px: 15,
            }}
          >
            <Typography
              sx={{
                fontFamily: "PdsFont",
                fontSize: "20px",
                lineHeight: "35px",
              }}
            >
              သို့
            </Typography>
            <Box
              sx={{
                pl: "20px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "PdsFont",
                  fontSize: "20px",
                  lineHeight: "35px",
                }}
              >
                သင်တန်းကြီးကြပ်ရေးတာဝန်ခံအရာရှိ
              </Typography>
              <Typography
                sx={{
                  fontFamily: "PdsFont",
                  fontSize: "20px",
                  lineHeight: "35px",
                }}
              >
                ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်းကျောင်း
              </Typography>
              <Typography
                sx={{
                  fontFamily: "PdsFont",
                  fontSize: "20px",
                  lineHeight: "35px",
                }}
              >
                မြောက်ဒဂုံမြို့နယ်၊ရန်ကုန်တိုင်းဒေသကြီး။
              </Typography>
            </Box>
            <Typography
              sx={{
                my: "10px",
                textAlign: "end",
                fontFamily: "PdsFont",
                fontSize: "20px",
                lineHeight: "35px",
              }}
            >
              ရက်စွဲ၊ &nbsp;(
              {convertToMyanmarNumbers(
                splitDate(preData.predeparture.courseStartDate).day
              )}
              )ရက် &nbsp;
              {getBurmeseMonthName(
                getFullMonthName(
                  splitDate(preData.predeparture.courseStartDate).month
                )
              )}
              လ &nbsp;
              {convertToMyanmarNumbers(
                splitDate(preData.predeparture.courseStartDate).year
              )}
              ခုနှစ်
            </Typography>
            <Typography
              sx={{
                fontFamily: "PdsFont",
                fontSize: "20px",
                lineHeight: "35px",
              }}
            >
              အကြောင်းအရာ။&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;။ ပြည်ပအလုပ်အကိုင်
              လမ်းညွှန်သင်တန်း တက်ရောက်ခွင့်ပြုပါရန် တင်ပြခြင်း။
            </Typography>
            <Typography
              sx={{
                textIndent: "50px",
                mb: 2,
                fontFamily: "PdsFont",
                fontSize: "20px",
                lineHeight: "35px",
              }}
            >
              ပြည်ပအလုပ်အကိုင် လိုင်စင်ရအေဂျင်စီ Clover Mandalay Co.,Ltd မှ
              ဂျပန်နိုင်ငံသို့ အလုပ်သင်သင်တန်းသား မ (
              {convertToMyanmarNumbers(preData.totalFemaleWorkers)})ဦးနှင့် ကျား (
              {convertToMyanmarNumbers(preData.totalMaleWorkers)})ဦးတို့အား
              စေလွှတ်ရန်အတွက် ပြည်ပအလုပ်အကိုင် လမ်းညွှန်သင်တန်းသို့
              တတ်ရောက်ခွင့်ပြုပါရန် သင်တန်းသားစာရင်းနှင့်တကွ ပူးတွဲပါ
              စာရွက်စာတမ်းများဖြင့် တင်ပြအပ်ပါသည်
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                pr: "50px",
              }}
            >
              <Box
                sx={{
                  textAlign: "center",
                }}
              >
                <Typography
                  sx={{
                    mb: 15,
                    mt: 10,
                    fontFamily: "PdsFont",
                    fontSize: "20px",
                  }}
                >
                  လေးစားစွာဖြင့်
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "PdsFont",
                    fontSize: "20px",
                    lineHeight: "35px",
                  }}
                >
                  ချစ်စုဝေ
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "PdsFont",
                    fontSize: "20px",
                    lineHeight: "35px",
                  }}
                >
                  မန်နေဂျင်းဒါရိုက်တာ
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "PdsFont",
                    fontSize: "20px",
                    lineHeight: "35px",
                  }}
                >
                  Clover Mandalay Co., Ltd
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </div>
    );
}


export default NDCoverLetter