import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { convertToMyanmarNumbers } from "../../../../utils/enTomm";
import { useSelector } from "react-redux";
import { splitDate } from "../../../../utils/splitDate";
import { getFullMonthName } from "../../../../utils/getFullMonthName";
import { getBurmeseMonthName } from "../../../../utils/getBurmeseMonthName";

const useStyles = makeStyles({
    screen: {
        pageBreakAfter: 'always',
    },
});

const DlMm = () => {

    const [dmData, setDmData] = useState(null);
    const pdfData = useSelector((state) => state.PrintData.data);
    const classes = useStyles();
    
    const demandList = dmData
      ? [
          {
            title: "ကုမ္ပဏီအမည်",
            data: dmData.company.nameEng,
          },
          {
            title: "ကုမ္ပဏီလိပ်စာ",
            data: dmData.company.addressEng,
          },
          {
            title: "အလုပ်သမားဦးရေ",
            data: `${convertToMyanmarNumbers(dmData.numberOfWorkers.total)} ဦး`,
          },
          {
            title: "ကျား/မ",
            data: `${
              dmData.numberOfWorkers.male > 0 &&
              dmData.numberOfWorkers.female === 0
                ? "ကျား"
                : dmData.numberOfWorkers.male === 0 &&
                  dmData.numberOfWorkers.female > 0
                ? "မ"
                : `ကျား(${dmData.numberOfWorkers.male})/ မ(${dmData.numberOfWorkers.female})`
            }`,
          },
          {
            title: "အသက်",
            data: dmData.ageLimitationMM,
          },
          {
            title: "အရည်အချင်း",
            data: "ဂျပန်ဘာသာစကား N5 အဆင့် ပြောဆိုတတ်ရန်",
          },
          {
            title: "ကျန်းမာရေးနှင့် ကိုယ်ခန္ဓာအခြေအနေ",
            data: "မြန်မာပြည်တွင်စစ်ဆေးသော ကျန်းမာရေးဆေးစစ်ချက်အောင်ရမည်။",
          },
          {
            title: "အလုပ်အမျိုးအစား",
            data: dmData.jobCategoryMM,
          },
          {
            title: "အလုပ်နေရာ",
            data: dmData.workPlace,
          },
          {
            title: "အလုပ်ချိန်",
            data: dmData.workingHoursMM,
          },
          {
            title: "အခြေခံလစာ",
            data: `${Number(dmData.basicSalary).toLocaleString()}-ယန်း`,
          },
          {
            title: "လခပေးချေနည်း",
            data:
              Number(dmData.salaryPaymentMethod) === 1
                ? "ဘဏ်မှငွေလွှဲပေးခြင်း"
                : "ငွေသားဖြင့်ပေးချေခြင်း",
          },
          {
            title: "အချိန်ပို",
            data: dmData.overtimeMM,
          },
          {
            title: "အလုပ်လုပ်ရက်",
            data: dmData.workingDayMM,
          },
          {
            title: "အားလပ်ရက်",
            data: dmData.holidayMM,
          },
          {
            title: "နေစရာ",
            data: "ကုမ္ပဏီမှစီစဉ်ပေးသည်။",
            data2: `အလုပ်သင်သင်တန်းသား၏လစဉ်လစာထဲမှ ${Number(
              dmData.accommodation
            ).toLocaleString()} ယန်း ပေးချေရမည်။`,
          },
          {
            title: "သွားလာစရိတ်/ လေယာဉ်လက်မှတ်",
            data: dmData.transportationMM,
          },
          {
            title: "အစားအသောက်",
            data: "အလုပ်သင်သင်တန်းသားမှစီစဉ်ရမည်၊၊",
          },
          {
            title: "လေ့ကျင့်ရေးကာလ",
            data: "၁လ",
          },
          {
            title: "လေ့ကျင့်ရေးကာလထောက်ပံ့ငွေ",
            data: `ယန်း ${Number(
              dmData.trainingPeriodWageScale
            ).toLocaleString()}`,
          },
          {
            title: "စာချုပ်ကာလ",
            data: "၃ နှစ်",
          },
          {
            title: "ကျန်းမာရေးအာမခံ",
            data: "JITCO သင်တန်းသားဘက်စုံအာမခံ",
          },
          {
            title: "ကုန်ကျစရိတ် ဝန်ဆောင်ခမှာ ဒေါ်လာ ၂၈၀၀ ဖြစ်ပါသည်။",
            data: "",
          },
        ]
      : [];

    useEffect(() => {
        if (pdfData) {
          const { data } = pdfData;
          setDmData(data);
        }
    }, [pdfData]);

    return (
      <Box className={classes.screen} sx={{ px: 15, py: 10 }}>
        {dmData && (
          <>
            <Box
              sx={{
                width: "100%",
                mb: 5,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "PdsFont",
                  fontSize: "20px",
                  lineHeight: "40px",
                }}
              >
                ကြီးကြပ်အဖွဲ့အစည်းအမည်: {dmData.organization.nameEng}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "PdsFont",
                  fontSize: "20px",
                  lineHeight: "40px",
                }}
              >
                ကြီးကြပ်အဖွဲ့အစည်းလိပ်စာ: {dmData.organization.addressEng}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "PdsFont",
                  fontSize: "20px",
                  lineHeight: "40px",
                }}
              >
                ဖုန်းနံပါတ်: {dmData.organization.phone}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "PdsFont",
                  fontSize: "20px",
                  lineHeight: "40px",
                }}
              >
                ဖက်(စ်)နံပါတ်: {dmData.organization.fax}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "80%",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "PdsFont",
                  fontSize: "20px",
                  lineHeight: "40px",
                }}
              >
                Ms. Chit Su Wai
              </Typography>
              <Typography
                sx={{
                  fontFamily: "PdsFont",
                  fontSize: "20px",
                  lineHeight: "40px",
                }}
              >
                မန်နေးဂျင်းဒါရိုက်တာ
              </Typography>
              <Typography
                sx={{
                  fontFamily: "PdsFont",
                  fontSize: "20px",
                  lineHeight: "40px",
                }}
              >
                Clover Mandalay Co.,Ltd.
              </Typography>
              <Typography
                sx={{
                  fontFamily: "PdsFont",
                  fontSize: "20px",
                  lineHeight: "40px",
                }}
              >
                အမှတ် (၂၄)၊ ကံ့ကော်လမ်း၊ ၂၂ x ၂၃ လမ်းကြား၊ ၈၈ x ၈၉ လမ်းကြား၊
                <br></br> အောင်မြေသာစံမြို့နယ်၊ မန္တလေးမြို့ မြန်မာနိုင်ငံ
              </Typography>
            </Box>
            <Typography
              sx={{
                my: "10px",
                textAlign: "end",
                fontFamily: "PdsFont",
                fontSize: "20px",
              }}
            >
              ရက်စွဲ၊
              {/* changing month into burmese name */}
              &nbsp;
              {getBurmeseMonthName(
                getFullMonthName(
                  splitDate(dmData.demandLetterApplicationDate).month
                )
              )}
              လ &nbsp;
              {convertToMyanmarNumbers(
                splitDate(dmData.demandLetterApplicationDate).day
              )}
              ရက်၊
              {convertToMyanmarNumbers(
                splitDate(dmData.demandLetterApplicationDate).year
              )}
              ခုနှစ်
            </Typography>
            <Typography
              sx={{
                fontSize: "20px",
                fontFamily: "PdsFont",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              အလုပ်သင်သင်တန်းသားခေါ်ယူရန်ကမ်းလှမ်းစာ
            </Typography>
            <Typography
              sx={{
                my: 3,
                fontFamily: "PdsFont",
                fontSize: "20px",
              }}
            >
              Dear Ms. Chit Su Wai,
            </Typography>
            <Typography
              sx={{
                mb: "10px",
                textIndent: 5,
                fontSize: "20px",
                my: 3,
                fontFamily: "PdsFont",
              }}
            >
              ဤကမ်းလှမ်းစာသည် ကျွန်ုပ်တို့ကုမ္ပဏီတွင်
              မြန်မာလူမျိုးအလုပ်သမားများကို အလုပ်ခန့်အပ်ရန်အတွက် သင်၏ အေဂျင်စီမှ
              အလုပ်သမားများခေါ်ယူနိုင်ရန် အပ်နှင်းသောစာဖြစ်ပါသည်။
              ကျွန်ုပ်တို့၏အလုပ်သမားများခေါ်ယူခြင်းသတ်မှတ်ချက်မှာ
              အောက်ပါအတိုင်းဖြစ်ပါသည်။
            </Typography>
            {demandList.map((demandData, index) => (
              <Grid
                key={index}
                container
                spacing={2}
                sx={{
                  pb: 3,
                  pageBreakAfter: index === 12 ? "always" : "",
                  pt: index === 13 ? 14 : 0,
                }}
              >
                <Grid item xs={index === demandList.length - 1 ? 12 : 4}>
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <Typography
                      sx={{
                        mr: 2,
                        fontFamily: "PdsFont",
                        fontSize: "20px",
                      }}
                    >
                      {convertToMyanmarNumbers(index + 1)}။
                    </Typography>
                    <Typography
                      sx={{ fontFamily: "PdsFont", fontSize: "20px" }}
                    >
                      {demandData.title}
                    </Typography>
                  </Box>
                </Grid>
                {index === demandList.length - 1 ? (
                  <></>
                ) : (
                  <Grid item xs={8}>
                    <Typography
                      sx={{ fontFamily: "PdsFont", fontSize: "20px" }}
                    >
                      : {demandData.data}
                    </Typography>
                    <Typography
                      sx={{
                        pl: "10px",
                        fontFamily: "PdsFont",
                        fontSize: "20px",
                      }}
                    >
                      {demandData.data2}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            ))}
            <Box
              sx={{
                textAlign: "end",
                pt: 10,
              }}
            >
              <Typography
                sx={{ fontFamily: "PdsFont", fontSize: "20px", mb: 3 }}
              >
                လေးစားစွာဖြင့်
              </Typography>
              <Typography
                sx={{ fontFamily: "PdsFont", fontSize: "20px", mb: 3 }}
              >
                {dmData.organization.nameEng}
              </Typography>
              <Typography
                sx={{ fontFamily: "PdsFont", fontSize: "20px", mb: 3 }}
              >
                ဒါရိုက်တာကိုယ်စားလှယ် : {dmData.organization.chairmanEng}
              </Typography>
              <Typography sx={{ fontFamily: "PdsFont", fontSize: "20px" }}>
                (လက်မှတ်) (တံဆိပ်တုံး)
              </Typography>
            </Box>
            <Typography
              sx={{
                fontFamily: "PdsFont",
                fontSize: "20px",
                textAlign: "justify",
                textIndent: "50px",
                marginTop: "30px",
              }}
            >
              နည်းပညာအလုပ်သင်သည် Demand letter
              များကိုစိစစ်ပေးနိုင်ရေးအတွက်သံအရာရှိထံအကြောင်းကြားပေးနိုင်ရန်တင်ပြသည့်စာရင်း
              ဟူပြီး ဇယားအသစ်တိုးလာပါသည်။
            </Typography>
          </>
        )}
      </Box>
    );
}

export default DlMm