import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import img from '../../assets/images/waitingImg.png';
import pdfFile from '../../assets/files/CV instruction.pdf'; // Adjust the path to your PDF file
import { Box, Typography, Button } from '@mui/material';
import theme from '../../utils/theme';
import { useSelector } from 'react-redux';

const AfterRegister = () => {
    const rootContainer = document.getElementById('root');
    const profileData = useSelector(state=> state.User.profile?.data)
    const [showPdf, setShowPdf] = useState(false);

    const handleView = () => {
        setShowPdf(true);
    }

    return ReactDOM.createPortal(
        <Box
            sx={{
                height: '100vh',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            { showPdf ? (
                <Box sx={{ width: 500, height: 500 }}>
                    <iframe
                        title="CV Instruction"
                        src={`${pdfFile}?page=hsn#toolbar=0`}
                        width="100%"
                        height="100%"
                        style={{ border: 'none' }}
                    ></iframe>
                    <Button onClick={()=> setShowPdf(false) } variant='contained' sx={{ float: 'right', m: 3 }}>Back</Button>
                </Box>
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        whiteSpace: 'pre-wrap'
                    }}
                >
                    <img
                        srcSet={`${img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                        src={`${img}?w=248&fit=crop&auto=format`}
                        alt={'something went wrong'}
                        loading="lazy"
                    />
                    <Typography sx={{ textAlign: 'center' }} variant='h4' mb={2}>Signup Successful...</Typography>
                    <Typography sx={{ textAlign: 'center' }} variant='body2' mb={1}>Wait for the admin's approve to create your CV form.</Typography>
                    { Number(profileData?.is_engineer) === 0 && (
                        <>
                            <Typography sx={{ textAlign: 'center' }} variant='body2' mb={3}>You can download the instruction file to fill out the CV form here.</Typography>
                            <Typography variant='text' sx={{ textDecoration: 'underline', cursor: 'pointer', color: theme.palette.primary.main }} onClick={handleView}>View the instruction file</Typography>
                        </>
                    )}
                </Box>
            )}
        </Box>,
        rootContainer
    );
}

export default AfterRegister;
