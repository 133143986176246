import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  root: {
    fontSize: "22px",
    fontFamily: "Mincho",
    marginBottom: 2,
  },
  table: {
    borderCollapse: "collapse",
    width: "100%",
  },
  cell: {
    padding: "5px",
    border: "2px solid #000",
  },
  headingCell: {
    padding: "5px",
    whiteSpace: "nowrap",
    fontFamily: "Mincho",
    fontSize: "20px",
  },
  attention: {
    fontSize: "20px",
    fontFamily: "Mincho",
    marginTop: 1,
  },
  attentionText: {
    textIndent: "20px",
    fontSize: "20px",
    fontFamily: "Mincho",
    marginBottom: 3,
  },
});

const Coe7_1_1 = ({ index }) => {
  const classes = useStyles();
  const { data: printData } = useSelector((state) => state.PrintData);
  const [memberData, setMemberData] = useState([]);
  const [companyInfo, setCompanyInfo] = useState(null);
  const [orgInfo, setOrgInfo] = useState(null);

  useEffect(() => {
    if (
      printData &&
      printData.data &&
      printData.data.company &&
      printData.data.organization &&
      printData.data.members
    ) {
      const data = printData.data.members[index];
      setMemberData(data);
      setCompanyInfo(printData.data.company);
      setOrgInfo(printData.data.organization);
    }
  }, [printData, index]);

  return (
    <>
      <Typography
        className={classes.root}
        sx={{
          display: "flex",
        }}
      >
        １　送出の概要 <Typography>ခြုံငုံသုံးသပ် ပေးပို့မှု</Typography>
      </Typography>

      <table className={classes.table}>
        <tbody>
          <tr>
            <td rowSpan={2} className={classes.cell}>
              <Typography className={classes.headingCell}>
                ①技能実習生の氏名
                <Typography>နည်းပညာဆိုင်ရာအလုပ်သင်၏အမည်</Typography>
              </Typography>
            </td>
            <td className={classes.cell}>
              <Typography className={classes.headingCell}>
                ローマ字 <Typography>အင်္ဂလိပ်ဘာသာ </Typography>
              </Typography>
            </td>
            <td colSpan={15} className={classes.cell}>
              <Typography className={classes.headingCell}>
                {memberData.nameEng}
              </Typography>
            </td>
          </tr>
          <tr>
            <td className={classes.cell}>
              <Typography className={classes.headingCell}>
                漢字 <Typography>ဂျပန်ဘာသာ </Typography>
              </Typography>
            </td>
            <td colSpan={15} className={classes.cell}>
              <Typography className={classes.headingCell}></Typography>
            </td>
          </tr>
          <tr>
            <td colSpan={2} rowSpan={2} className={classes.cell}>
              <Typography className={classes.headingCell}>
                ②取次送出機関の氏名又は名称
                <Typography>
                  အလုပ်သမားပို့ဆောင်ရေး အေဂျင်စီ ကိုယ်စားလှယ် <br />
                  အမည်အပြည့်အစုံ သို့မဟုတ် နာမည်
                </Typography>
                （送出機関番号又は整理番号を
                <br />
                記載すること。）
                <br />
                <Typography>
                  (စေလွှတ်သည့် အဖွဲအစည်း နံပါတ် သို့မဟုတ် <br /> စာရွက်စာတမ်း
                  နံပါတ်ကို ဖြည့်စွက်ပါ)
                </Typography>
              </Typography>
            </td>
            <td colSpan={15} className={classes.cell}>
              <Typography className={classes.headingCell}>
                CLOVER MANDALAY CO.,LTD
              </Typography>
            </td>
          </tr>
          <tr>
            <td className={classes.cell}>
              <Typography className={classes.headingCell}>
                送出機関番号
                <Typography>
                  အလုပ်သမား <br /> ပို့ဆောင်ရေး <br /> အေဂျင်စီ
                </Typography>
              </Typography>
            </td>
            <td
              width="25px"
              style={{ textAlign: "center", borderRight: "1px dotted #808080" }}
            >
              <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                M
              </Typography>
            </td>
            <td
              width="25px"
              style={{ textAlign: "center", borderRight: "1px dotted #808080" }}
            >
              <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                M
              </Typography>
            </td>
            <td
              width="25px"
              style={{ textAlign: "center", borderRight: "2px solid #000" }}
            >
              <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                R
              </Typography>
            </td>
            <td
              width="25px"
              style={{ textAlign: "center", borderRight: "1px dotted #808080" }}
            >
              <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                0
              </Typography>
            </td>
            <td
              width="25px"
              style={{ textAlign: "center", borderRight: "1px dotted #808080" }}
            >
              <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                0
              </Typography>
            </td>
            <td
              width="25px"
              style={{ textAlign: "center", borderRight: "1px dotted #808080" }}
            >
              <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                0
              </Typography>
            </td>
            <td
              width="25px"
              style={{ textAlign: "center", borderRight: "1px dotted #808080" }}
            >
              <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                2
              </Typography>
            </td>
            <td
              width="25px"
              style={{ textAlign: "center", borderRight: "1px dotted #808080" }}
            >
              <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                3
              </Typography>
            </td>
            <td width="25px" style={{ textAlign: "center" }}>
              <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                0
              </Typography>
            </td>
            <td style={{ padding: "10px", border: "2px solid #000" }}>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontFamily: "Mincho",
                  whiteSpace: "nowrap",
                }}
              >
                整理番号{" "}
                <Typography>
                  စာရွက်စာတမ်း <br /> နံပါတ်
                </Typography>
              </Typography>
            </td>
            <td
              style={{
                textAlign: "center",
                width: "30px",
                borderRight: "1px dotted #808080",
              }}
            ></td>
            <td
              style={{
                textAlign: "center",
                width: "30px",
                borderRight: "1px dotted #808080",
              }}
            ></td>
            <td
              style={{
                textAlign: "center",
                width: "30px",
                borderRight: "1px dotted #808080",
              }}
            ></td>
            <td
              style={{
                textAlign: "center",
                width: "30px",
                borderRight: "2px solid #000",
              }}
            ></td>
          </tr>
          <tr>
            <td colSpan={2} className={classes.cell}>
              <Typography className={classes.headingCell}>
                ③実習実施者の氏名又は名称 <br />{" "}
                <Typography>
                  လက်တွေ့သင်တန်းပို့ချမည့်သူအမည်အပြည့်အစုံ <br /> သို့မဟုတ် အမည်
                </Typography>
              </Typography>
            </td>
            <td colSpan={15} className={classes.cell}>
              <Typography className={classes.headingCell}>
                {companyInfo?.nameJp}
              </Typography>
            </td>
          </tr>
          <tr>
            <td colSpan={2} className={classes.cell}>
              <Typography className={classes.headingCell}>
                ④監理団体の名称{" "}
                <Typography>
                  ကြီးကြပ်မှုအဖွဲ့အစည်းအမည်အပြည့်အစုံ သို့မဟုတ် <br /> အမည်
                </Typography>
              </Typography>
            </td>
            <td colSpan={15} className={classes.cell}>
              <Typography className={classes.headingCell}>
                {orgInfo?.nameJp}
              </Typography>
            </td>
          </tr>
        </tbody>
      </table>

      <Typography
        className={classes.attention}
        sx={{
          display: "flex",
        }}
      >
        （注意）<Typography>မှတ်ချက်</Typography>
      </Typography>
      <Typography className={classes.attentionText}>
        ①は、ローマ字で旅券（未発給の場合、発給申請において用いるもの）と同一の氏名を記載するほか、漢字の氏名がある場合にはローマ字の氏名と併せて、漢字の氏名も記載すること。
        <Typography>
          အမှတ်စဉ် (၁) သည် အင်္ဂလိပ်ဘာသာဖြင့် နိုင်ငံကူးလက်မှတ် (မထုတ်ရသေးလျှင်
          လျှောက်ထားဆဲ စာရွက်စာတမ်း)တွင် ပါရှိမည့် အမည်နှင့် ထပ်တူဖော်ပြရန်
          နှင့် တရုတ်ဘာသာစကားဖြင့် အမည်မရှိလျှင် အင်္ဂလိပ်ဘာသာနာမည်နှင့်
          တူညီသောအမည်ကိုဖော်ပြရန်ဖြစ်သည်။
        </Typography>
      </Typography>
    </>
  );
};

export default Coe7_1_1;
