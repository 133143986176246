import React, { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { splitDate } from '../../../utils/splitDate'
import dayjs from "dayjs";

const useStyles = makeStyles({
  screen: {
    pageBreakAfter: 'always',
  },
  subTitleJp: {
    fontSize: '16px',
    fontFamily: 'Mincho'
  },
  subTitleMm: {
    fontSize: '16px',
    fontFamily: 'PdsFont'
  },
  titleJp: {
    fontSize: '22px',
    fontFamily: 'Mincho'
  },
  titleMm: {
    fontSize: '22px',
    fontFamily: 'PdsFont'
  }
});

const Coe8_1 = () => {
  const classes = useStyles();
  const { data: printData } = useSelector(state=> state.PrintData)
  const [ companyInfo,setCompanyInfo ] = useState(null)
  const [ orgInfo,setOrgInfo ] = useState(null)
  const [ firstMember,setFirstMember ] = useState(null)
  const [ memberCount,setMemberCount ] = useState(0)
  const [ depDate,setDepDate ] = useState(null)
  const [ currentJob,setCurrentJob ] = useState(null)

  useEffect(()=>{
    if(printData && printData.data && printData.data.tables){
      const formattedDate =  printData.data.initialDepartureDate ? dayjs(printData.data.initialDepartureDate).format('YYYY-MM-DD') : '';
      const members = printData.data.members
      const firstMem = members[0]
      setDepDate(formattedDate)
      setFirstMember(firstMem)
      setCurrentJob(firstMem.experiences[firstMem.experiences.length -1])
      setMemberCount(members.length -1)
      setCompanyInfo(printData.data.company)
      setOrgInfo(printData.data.organization)
    }
  },[printData])

  return (
    <Box className={classes.screen} sx={{ px: 15, pt: 10 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <Typography className={classes.subTitleJp}>参考様式第１-23号（規則第８条第21号関係）</Typography>
          <Typography className={classes.subTitleMm}>ရည်ညွှန်းပုံစံ အမှတ် 1 - 23 (စည်းမျဉ်းစာပိုဒ် 8 အမှတ်စဉ် 21)</Typography>
          <Typography className={classes.subTitleJp}>Ｄ・Ｅ・Ｆ</Typography>
        </Box>
        <Box>
          <Typography className={classes.subTitleJp}>（日本工業規格Ａ列４）</Typography>
          <Typography className={classes.subTitleMm}>(ဂျပန်စက်မှု သတ်မှတ်စံနှုန်း A - 4)</Typography>
        </Box>
      </Box>
      <Box sx={{ py: '30px', textAlign: 'center' }}>
        <Typography className={classes.titleJp} sx={{ fontSize: '18px', letterSpacing: '30px', pb: 5 }}>技能実習生の推薦状</Typography>
        <Typography className={classes.titleMm} sx={{ fontSize: '18px', pb: 5 }}>နည်းပညာသင်တန်းသားများအတွက် ထောက်ခံစာ</Typography>
      </Box>
      <Typography sx={{ textIndent: 50, lineHeight: '50px', textAlign: 'justify', fontFamily: 'Mincho', fontSize: '20px' }}>
        我が国の送出機関である 
        <span style={{ textDecoration: 'underline', textUnderlineOffset: 5, padding: '0px 50px', fontFamily: 'Mincho', fontSize: '20px' }}>CLOVER MANDALAY CO. LTD</span> が送り出す、
        技能実習生 <span style={{ textDecoration: 'underline', textUnderlineOffset: 5, padding: '0px 20px', fontFamily: 'Mincho', fontSize: '20px' }}>
        <br/>{firstMember ? firstMember.nameEng : ""} ほか {memberCount}（別添名簿のとおり）</span>
        について、日本国の監理団体である 
        <span style={{ textDecoration: 'underline', textUnderlineOffset: 5, padding: '0px 20px', fontFamily: 'Mincho', fontSize: '20px' }}>{orgInfo ? orgInfo.nameJp : ''}</span> を通じて、実習
        実施者である 
        <span style={{ textDecoration: 'underline', textUnderlineOffset: 5, padding: '0px 20px', fontFamily: 'Mincho', fontSize: '20px' }}>{companyInfo ? companyInfo.nameJp : ''}</span> に受 け入れられて、
        <span style={{ textDecoration: 'underline', textUnderlineOffset: 5, padding: '0px 20px', fontFamily: 'Mincho', fontSize: '20px' }}>{splitDate(depDate).year} 年 {splitDate(depDate).month} 月</span> から、
        <span style={{ textDecoration: 'underline', textUnderlineOffset: 5, padding: '0px 20px', fontFamily: 'Mincho', fontSize: '20px' }}>{currentJob ? currentJob.jobCategoryJp : ''}</span> に係る技能実
        習を行うことについて推薦します。
      </Typography>
      <Typography sx={{ textIndent: 50, lineHeight: '50px', textAlign: 'justify', fontFamily: 'PdsFont', fontSize: '20px' }}>
        ကျွန်တော်တို့နိုင်ငံ၏ ပြည်ပအလုပ်အကိုင်ဝန်ဆောင်မှုလုပ်ငန်းဖြစ်သည့် 
        <span style={{ textDecoration: 'underline', textUnderlineOffset: 5, padding: '0px 20px', fontFamily: 'PdsFont', fontSize: '20px' }}>CLOVER MANDALAY CO., LTD</span> မှ စေလွှတ်မည့်
        အလုပ်သင်သင်တန်းသား
        <span style={{ textDecoration: 'underline', textUnderlineOffset: 5, padding: '0px 20px', fontFamily: 'Mincho', fontSize: '20px' }}>
          {firstMember ? firstMember.nameEng : ""}
        </span>
        <span style={{ textDecoration: 'underline', textUnderlineOffset: 5, padding: '0px 20px', fontFamily: 'PdsFont', fontSize: '20px' }}>
          နှင့်အခြား {memberCount} ဦး(နောက်စာမျက်နှာပါ အမည်စာရင်းအတိုင်း)
        </span> နှင့်ပတ်သတ်၍
        ဂျပန်နိုင်ငံရှိ 
        <span style={{ textDecoration: 'underline', textUnderlineOffset: 5, padding: '0px 20px', fontFamily: 'PdsFont', fontSize: '20px' }}>{orgInfo ? orgInfo.nameEng : ''}</span> ဟုခေါ်သော ပူးပေါင်းအဖွဲ့အစည်းအကူအညီဖြင့် 
        <span style={{ textDecoration: 'underline', textUnderlineOffset: 5, padding: '0px 20px', fontFamily: 'PdsFont', fontSize: '20px' }}>{companyInfo ? companyInfo.nameEng : ''}</span> တွင်ပို့ဆောင်ပြီး
        <span style={{ textDecoration: 'underline', textUnderlineOffset: 5, padding: '0px 20px', fontFamily: 'PdsFont', fontSize: '20px' }}>{splitDate(depDate).year} ခုနှစ် {splitDate(depDate).month} လ</span> မှစတင်ပြီး 
        <span style={{ textDecoration: 'underline', textUnderlineOffset: 5, padding: '0px 20px', fontFamily: 'PdsFont', fontSize: '20px' }}>{currentJob ? currentJob.jobCategoryMm : ''}</span> နှင့်ပတ်သက်သည်များကို သင်ကြားစေကြောင်း ထောက်ခံအပ်ပါသည်။
      </Typography>
      <Typography sx={{ textIndent: '30px', lineHeight: '50px', fontFamily: 'Mincho', fontSize: '20px' }}>なお、本推薦状の効力は作成日以降１年間とします。</Typography>
      <Typography sx={{ textIndent: '30px', lineHeight: '50px', fontFamily: 'Mincho', fontSize: '20px' }}>ယခုထောက်ခံစာမှာ စတင်ပြုလုပ်သည့်နေ့မှစပြီး တစ်နှစ်အကျုံးဝင်ပါသည်။</Typography>
      <table style={{ width: '450px', margin: '20px 0px 20px auto' }}>
        <tbody>
          <tr>
            <td style={{ textAlign: 'end', fontFamily: 'Mincho', lineHeight: '50px', fontSize: '20px' }}>{firstMember ? splitDate(firstMember.coeDcDate).year : ""} 年</td>
            <td style={{ textAlign: 'end', fontFamily: 'Mincho', lineHeight: '50px', fontSize: '20px' }}>{firstMember ? splitDate(firstMember.coeDcDate).month : ""} 月</td>
            <td style={{ textAlign: 'end', fontFamily: 'Mincho', lineHeight: '50px', fontSize: '20px' }}>{firstMember ? splitDate(firstMember.coeDcDate).day : ""} 日</td>
            <td style={{ textAlign: 'end', fontFamily: 'Mincho', lineHeight: '50px', fontSize: '20px' }}>作成</td>
          </tr>
          <tr>
            <td style={{ textAlign: 'end', fontFamily: 'PdsFont', lineHeight: '50px', fontSize: '20px' }}>နှစ်</td>
            <td style={{ textAlign: 'end', fontFamily: 'PdsFont', lineHeight: '50px', fontSize: '20px' }}>လ</td>
            <td style={{ textAlign: 'end', fontFamily: 'PdsFont', lineHeight: '50px', fontSize: '20px' }}>ရက်</td>
            <td style={{ textAlign: 'end', fontFamily: 'PdsFont', lineHeight: '50px', fontSize: '20px' }}>ရက်စွဲ</td>
          </tr>
        </tbody>
      </table>
      <Box sx={{ width: '60%', ml: 'auto', mt: 5 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
          <Box>
            <Box sx={{ textAlign: 'end' }}>
              <Typography sx={{ fontSize: '20px', lineHeight: '50px', fontFamily: 'Mincho' }}>公的機関の名称</Typography>
              <Typography sx={{ fontSize: '20px', lineHeight: '50px', fontFamily: 'PdsFont' }}>အဖွဲ့အစည်းအမည်</Typography>
            </Box>
            <Box sx={{ textAlign: 'center', pt: '20px' }}>
              <Typography sx={{ fontSize: '20px', lineHeight: '50px', fontFamily: 'Mincho' }}>作成責任者 役職・氏名</Typography>
              <Typography sx={{ fontSize: '20px', lineHeight: '50px', fontFamily: 'PdsFont' }}>တာဝန်ရှိသူ ရာထူး၊ အမည်</Typography>
            </Box>
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <Typography sx={{ fontSize: '20px', lineHeight: '50px', fontFamily: 'Mincho' }}>印</Typography>
            <Typography sx={{ fontSize: '20px', lineHeight: '50px', fontFamily: 'PdsFont' }}>လက်မှတ်</Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <Box sx={{ textAlign: 'center' }}>
            <Typography sx={{ fontSize: '20px', lineHeight: '50px', fontFamily: 'Mincho' }}>（公印）</Typography>
            <Typography sx={{ fontSize: '20px', lineHeight: '50px', fontFamily: 'PdsFont' }}>တံဆိပ်တုံး</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Coe8_1;
