import React from "react";
import { makeStyles } from '@mui/styles';
import { Box, Typography } from "@mui/material";

const useStyles = makeStyles({
  screen: {
    pageBreakAfter: 'always',
  },
  tableCell: {
    padding: '5px',
    border: '2px solid #000',
  },
  checkBoxCell: {
    display: 'flex',
    px: '10px',
  },
  checkBox: {
    fontSize: "18px",
    fontFamily: 'Mincho',
  },
  checkBoxLabel: {
    fontSize: "18px",
    fontFamily: 'PdsFont',
  },
  sectionHeader: {
    fontSize: "18px",
    fontFamily: 'Mincho',
  },
  sectionContent: {
    fontSize: "18px",
    fontFamily: 'PdsFont',
  },
  attention: {
    fontSize: "18px",
    fontFamily: 'Mincho',
    mb: 3,
    mt: 1,
  },
  note: {
    fontSize: "18px",
    fontFamily: 'PdsFont',
  },
});

const Coe3_4 = () => {
  const classes = useStyles();

  return (
    <Box className={classes.screen} sx={{ px: 15, pt: 10 }}>
      <table style={{ borderCollapse: 'collapse', width: '100%' }}>
        <tbody>
          {/* 8 -> ဂျပန်သို့ ရောက်ဖူးခြင်း ရှိ/မရှိ */}
          <tr>
            <td colSpan={2} className={classes.tableCell}>
              <Typography className={classes.sectionHeader}>⑪ 訪日経験</Typography>
              <Typography className={classes.sectionContent}>ဂျပန်သို့ ရောက်ဖူးခြင်း ရှိ/မရှိ</Typography>
            </td>
            <td colSpan={3} className={classes.tableCell} style={{ minWidth: '550px'}}>
                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <td style={{ fontSize: '18px', fontFamily: "Mincho" }}>有</td>
                            <td style={{ fontSize: '18px', fontFamily: "Mincho" }}>.</td>
                            <td>
                              <p style={{ fontSize: '18px', fontFamily: "Mincho", paddingLeft: '2px' }} className="roundedBorder">無</p>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: '18px', fontFamily: "PdsFont" }}>ရှိ</td>
                            <td style={{ fontSize: '18px', fontFamily: "PdsFont" }}>.</td>
                            <td style={{ fontSize: '18px', fontFamily: "PdsFont" }}>မရှိ</td>
                        </tr>
                    </tbody>
                </table>
            </td>
          </tr>
          {/* 9 -> နည်းပညာဆိုင်ရာ အလုပ်သင် အတွေအကြုံနှင့် လုပ်ငန်းခွဲခြားမှု */}
          <tr>
            <td colSpan={2} style={{ verticalAlign: 'top' }} className={classes.tableCell}>
              <Typography className={classes.sectionHeader}>⑫ 技能実習経験及びその区分</Typography>
              <Typography className={classes.sectionContent}>နည်းပညာဆိုင်ရာ အလုပ်သင် အတွေ့အကြုံနှင့် လုပ်ငန်းခွဲခြားမှု</Typography>
            </td>
            <td colSpan={3} className={classes.tableCell}>
                <table style={{ width: '100%', marginBottom: '20px' }}>
                    <tbody>
                        <tr>
                            <td className="borderRounded" style={{ fontSize: '18px', fontFamily: "Mincho" }}>有</td>
                            <td style={{ fontSize: '18px', fontFamily: "Mincho" }}>.</td>
                            <td>
                                <p style={{ fontSize: '18px', fontFamily: "Mincho", paddingLeft: '2px' }} className="roundedBorder">無</p>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: '18px', fontFamily: "PdsFont" }}>ရှိ</td>
                            <td style={{ fontSize: '18px', fontFamily: "PdsFont" }}>.</td>
                            <td style={{ fontSize: '18px', fontFamily: "PdsFont" }}>မရှိ</td>
                        </tr>
                    </tbody>
                </table>
              <div className={classes.checkBoxCell}>
                <Box mb={2} width='50%'>
                  <Typography><span>□</span>Ａ（第１号企業単独型技能実習）</Typography>
                  <Typography>အမှတ်စဉ် ၁ လုပ်ငန်းတစ်ခုချင်းနည်းပညာပိုင်း ဆိုင်ရာအလုပ်သင်</Typography>
                </Box>
                <Box mb={2} width='50%'>
                  <Typography><span>□</span>Ｄ（第１号団体監理型技能実習）</Typography>
                  <Typography>အမှတ်စဉ် ၁ အဖွဲ့အစည်းတစ်ခုချင်းနည်းပညာပိုင်း ဆိုင်ရာအလုပ်သင်</Typography>
                </Box>
              </div>
              <div className={classes.checkBoxCell}>
                <Box mb={2} width='50%'>
                  <Typography><span>□</span>Ｂ（第２号企業単独型技能実習）</Typography>
                  <Typography>အမှတ်စဉ် ၂ လုပ်ငန်းတစ်ခုချင်းနည်းပညာပိုင်း ဆိုင်ရာအလုပ်သင်</Typography>
                </Box>
                <Box mb={2} width='50%'>
                  <Typography><span>□</span>Ｅ（第２号団体監理型技能実習）</Typography>
                  <Typography>အမှတ်စဉ် ၂ အဖွဲ့အစည်းတစ်ခုချင်းနည်းပညာပိုင်း ဆိုင်ရာအလုပ်သင်</Typography>
                </Box>
              </div>
              <div className={classes.checkBoxCell}>
                <Box mb={2} width='50%'>
                  <Typography><span>□</span>Ｃ（第３号企業単独型技能実習）</Typography>
                  <Typography>အမှတ်စဉ် ၃ လုပ်ငန်းတစ်ခုချင်းနည်းပညာပိုင်း ဆိုင်ရာအလုပ်သင်</Typography>
                </Box>
                <Box mb={2} width='50%'>
                  <Typography><span>□</span>Ｆ（第３号団体監理型技能実習）</Typography>
                  <Typography>အမှတ်စဉ် ၃ အဖွဲ့အစည်းတစ်ခုချင်းနည်းပညာပိုင်း ဆိုင်ရာအလုပ်သင်</Typography>
                </Box>
              </div>
            </td>
          </tr>
          {/* 10 -> ယခင်နေထိုင်ခွင့်လျှောက်ထားခြင်း အပယ်ခံရခြင်းရှိ/မရှိ */}
          <tr>
            <td colSpan={2} className={classes.tableCell}>
              <Typography className={classes.sectionHeader}>⑬ 過去の在留資格認定証明書不交付の有無</Typography>
              <Typography className={classes.sectionContent}>ယခင်နေထိုင်ခွင့်လျှောက်ထားခြင်း အပယ်ခံရခြင်းရှိ/မရှိ</Typography>
            </td>
            <td colSpan={3} className={classes.tableCell}>
                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <td className="borderRounded" style={{ fontSize: '18px', fontFamily: "Mincho" }}>有</td>
                            <td style={{ fontSize: '18px', fontFamily: "Mincho" }}>.</td>
                            <td>
                              <p style={{ fontSize: '18px', fontFamily: "Mincho", paddingLeft: '2px' }} className="roundedBorder">無</p>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ fontSize: '18px', fontFamily: "PdsFont" }}>ရှိ</td>
                            <td style={{ fontSize: '18px', fontFamily: "PdsFont" }}>.</td>
                            <td style={{ fontSize: '18px', fontFamily: "PdsFont" }}>မရှိ</td>
                        </tr>
                    </tbody>
                </table>
            </td>
          </tr>
          {/* 11 -> အခြား */}
          <tr>
            <td colSpan={2} className={classes.tableCell}>
              <Typography className={classes.sectionHeader}>⑭ その他</Typography>
              <Typography className={classes.sectionContent}>အခြား</Typography>
            </td>
            <td colSpan={3} className={classes.tableCell}>
              <Typography className={classes.sectionContent}>特に事項なし</Typography>
            </td>
          </tr>
          {/* 12 -> နည်းပညာသင်တန်းသားလက်မှတ် */}
          <tr>
            <td colSpan={2} className={classes.tableCell}>
              <Typography className={classes.sectionHeader}>⑮ 技能実習生の署名</Typography>
              <Typography className={classes.sectionContent}>နည်းပညာသင်တန်းသားလက်မှတ်</Typography>
            </td>
            <td colSpan={3} className={classes.tableCell}>
              <Typography className={classes.signature}></Typography>
            </td>
          </tr>
        </tbody>
      </table>
      <Typography className={classes.sectionHeader} sx={{ fontSize: "18px", fontFamily: 'Mincho', mb: 3, mt: 1 }}>（注意）</Typography>
      <Typography className={classes.sectionContent} sx={{ fontSize: "18px", fontFamily: 'PdsFont' }}>( မှတ်ချက် )</Typography>
      <Typography className={classes.sectionHeader} sx={{ fontSize: "18px", fontFamily: 'Mincho' }}>① は、ローマ字で旅券（未発給の場合、発給申請において用いるもの）と同一の氏名を記載するほか、漢字の氏名がある場合にはローマ字の氏名と併せて、漢字の氏名も記載すること。</Typography>
      <Typography className={classes.sectionContent} sx={{ fontSize: "18px", fontFamily: 'PdsFont' }}>အမှတ်စဉ် (၁) သည် အင်္ဂလိပ်စာလုံးဖြင့်ရေးသားထားသော နိုင်ငံကူးလက်မှတ် ( မထုတ်ရသေးလျှင် လျှောက်ထားဆဲ စာရွက်စာတမ်း ) တွင် ပါရှိမည့် အမည်နှင့် ထပ်တူ ဖော်ပြရန်နင့်ခန်းဂျီးစာလုံးဖြင့် အမည် မရှိလျှင် အင်္ဂလိပ်စာလုံးနာမည်နှင့် တူညီသောအမည်ကို ဖော်ပြရန်ဖြစ် သည်။</Typography>
    </Box>
  );
};

export default Coe3_4;
