import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  screen: {
    pageBreakAfter: "always",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontSize: "16px",
    lineHeight: "35px",
    fontFamily: "Mincho",
  },
  subTitle: {
    fontSize: "16px",
    lineHeight: "35px",
    fontFamily: "Mincho",
  },
  centerTitle: {
    textAlign: "center",
    fontSize: "22px",
    letterSpacing: "15px",
    lineHeight: "35px",
    fontFamily: "Mincho",
    marginY: 2,
  },
  centerSubtitle: {
    textAlign: "center",
    fontSize: "21px",
    lineHeight: "35px",
    fontFamily: "Mincho",
    marginBottom: 3,
  },
  content: {
    fontSize: "20px",
    lineHeight: "35px",
    fontFamily: "Mincho",
  },
  table: {
    borderCollapse: "collapse",
    width: "100%",
    border: "2px solid black",
  },
  tableCell: {
    padding: "5px",
    border: "2px solid #000",
  },
});

const HousinForm_1_27 = ({ index }) => {
  const classes = useStyles();
  const { data: printData } = useSelector((state) => state.PrintData);
  const [memberData, setMemberData] = useState([]);
  const [members, setMembers] = useState([]);

  useEffect(() => {
    if (printData && printData.data && printData.data.members) {
      const data = printData.data.members[index];
      setMemberData(data);
      const [, ...members] = printData.data.members; // Skips the first element
      setMembers(members);
    }
  }, [printData, index]);

  return (
    <Box className={classes.screen} sx={{ px: 15, pt: 10 }}>
      <>
        <Box className={classes.header}>
          <Box>
            <Typography className={classes.title}>
              参考様式第１-27号（規則第８条第26号関係）　　
            </Typography>
            <Typography className={classes.subTitle}>
              Ｄ・Ｅ・Ｆ（規則第10条第２項第３号ホに適合することを証する書面）
            </Typography>
          </Box>
          <Typography className={classes.title}>
            （日本産業規格Ａ列４）
          </Typography>
        </Box>
        <Typography className={classes.centerTitle} sx={{ my: 2 }}>
          同種業務従事経験等説明書
        </Typography>
        <Typography className={classes.centerSubtitle} sx={{ mb: 2 }}>
          （団体監理型技能実習）
        </Typography>
        <Typography
          className={classes.content}
          sx={{ my: 2, textAlign: "center" }}
        >
          受け入れようとする技能実習生（候補者を含む。）に関し、下記の事項について説明します。
        </Typography>
        <Typography
          className={classes.content}
          sx={{ textAlign: "center", mb: 1 }}
        >
          記
        </Typography>
        <table className={classes.table} border="2">
          <tbody>
            <tr>
              <td
                rowSpan={2 + ((members?.length || 0) * 2)}
                width="35%"
                className={classes.tableCell}
              >
                <Typography className={classes.content}>
                  ①技能実習生の氏名
                </Typography>
                <Typography
                  sx={{
                    lineHeight: "25px",
                    fontSize: "14px",
                    fontFamily: "MinCho",
                  }}
                >
                  ①技能実習生の氏名
                  ※　複数名について記載する場合には適宜欄を追加すること。記載しきれない場合には、別紙に記載することも可とし、当欄には「別紙のとおり」と記載すること。
                </Typography>
              </td>
              <td className={classes.tableCell}>
                <Typography className={classes.content}>ローマ字</Typography>
              </td>
              <td className={classes.tableCell}>
                <Typography className={classes.content}>
                  {memberData.nameEng}
                </Typography>
              </td>
            </tr>
            <tr>
              <td className={classes.tableCell}>
                <Typography className={classes.content}>漢字</Typography>
              </td>
              <td className={classes.tableCell}>
                {/* katakana name */}
                <Typography className={classes.content}>
                  {memberData.nameJp}
                </Typography>
              </td>
            </tr>
            {members?.map((memberData) => (
              <>
                <tr>
                  <td className={classes.tableCell}>
                    <Typography className={classes.content}>
                      ローマ字
                    </Typography>
                  </td>
                  <td className={classes.tableCell}>
                    <Typography className={classes.content}>
                      {memberData.nameEng}
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <td className={classes.tableCell}>
                    <Typography className={classes.content}>漢字</Typography>
                  </td>
                  <td className={classes.tableCell}>
                    {/* katakana name */}
                    <Typography className={classes.content}>
                      {" "}
                      {memberData.nameJp}
                    </Typography>
                  </td>
                </tr>
              </>
            ))}

            <tr>
              <td rowSpan={3} width="35%" className={classes.tableCell}>
                <Typography className={classes.content}>②証明内容</Typography>
              </td>
              <td
                colSpan={2}
                style={{
                  padding: "20px",
                }}
              >
                <Box sx={{ display: "flex", gap: "50px" }}>
                  <Typography>Ａ</Typography>
                  <Box>
                    <Typography>
                      ■　本邦において従事しようとする業務と同種の業務に外国において従事した経験を有する場合
                    </Typography>
                    <Typography>
                      →　技能実習生が所属している機関がある場合にあっては外国の所属機関による証明書（参考様式第1-28号）を添付（Ｂによる証明の場合は添付不要）
                    </Typography>
                    <Typography>
                      →　個人農業者や家族経営の事業に従事していた者等については、地方政府、業界団体等による証明書（参考様式第1-28号）を添付（Ｂによる証明の場合は添付不要）
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", gap: "50px" }}>
                  <Typography>B</Typography>
                  <Box>
                    <Typography>
                      ☐　団体監理型技能実習に従事することを必要とする特別な事情がある場合
                    </Typography>
                    <Box sx={{ display: "flex", gap: "20px" }}>
                      <Typography>ａ</Typography>
                      <Box>
                        <Typography>
                          ☐　申請者又は監理団体と送出国との間の技術協力上特に必要があると認められる場合
                        </Typography>
                        <Typography>
                          →　申請者又は監理団体と送出国の公的機関との間の技術協力上の必要性を立証する資料を添付
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex", gap: "20px" }}>
                      <Typography>b</Typography>
                      <Box>
                        <Typography>
                          ☐　教育機関において、同種の業務に関連する教育課程を修了している場合（修了見込みの場合も含む。）
                        </Typography>
                        <Typography>
                          →　教育機関の概要を明らかにする書面（同種の業務に関連する分野の教育を行っていることが分かる書類に限る。）を添付（参考様式第1-33号）
                        </Typography>
                        <Typography>
                          →　技能実習生が当該教育機関において関連する教育課程を修了したことを証明する書面（修了見込みの証明も含む。）を添付
                        </Typography>
                        <Typography>
                          →　実習生が教育機関に在籍したまま技能実習を行う場合は、教育機関と申請者や監理団体及び外国の送出機関との間において締結された協定書の写し又は協定内容証明書（参考様式第1-32号）を添付
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex", gap: "20px" }}>
                      <Typography>c</Typography>
                      <Box>
                        <Typography>
                          ☐　申請者が当該技能実習を行わせる必要性を具体的に説明でき、かつ、技能実習生が当該技能実習を本邦で行うために必要な最低限の訓練を受けている場合
                        </Typography>
                        <Typography>
                          →　技能実習生に当該技能実習を行う必要性について具体的に記載させた理由書（技能実習を行わせる理由書（参考様式第1-22号））を添付
                        </Typography>
                        <Typography>
                          →　２か月以上の期間かつ320時間以上の課程を有し、そのうち１か月以上の期間かつ160時間以上の課程が本邦での円滑な技能等の修得等に資する知識の科目（規則第10条第２項第７号ロ(4)）に充てられた入国前講習実施（予定）表（参考様式第1-29号）又はこれに相当する訓練の実施（予定）表を添付（参考様式第1-34号）
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </td>
            </tr>
          </tbody>
        </table>
        <Typography className={classes.content}>
          （※　複数名について記載する場合には適宜欄を追加すること。記載しきれない場合には別紙に記載することも可とし、当欄には「別紙のとおり」と記載すること。　
        </Typography>
      </>
    </Box>
  );
};

export default HousinForm_1_27;
