import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Coe11_11_tableRow from "./Coe11_11_tableRow";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { splitDate } from "../../../utils/splitDate";

const useStyles = makeStyles({
  screen: {
    pageBreakAfter: "always",
  },
});
const Coe11_12 = () => {
  const classes = useStyles();
  const { data: printData } = useSelector((state) => state.PrintData);
  const [firstMember, setFirstMember] = useState(null);
  const [comInfo, setComInfo] = useState(null);
  const [orgInfo, setOrgInfo] = useState(null);

  useEffect(() => {
    if (printData && printData.data && printData.data.members) {
      const members = printData.data.members;
      const company = printData.data.company;
      const organization = printData.data.organization;
      const firstMem = members[0];
      setOrgInfo(organization);
      setComInfo(company);
      setFirstMember(firstMem);
    }
  }, [printData]);

  const renderRows = () => {
    const numRows = 2;

    return Array.from({ length: numRows }, (_, index) => (
      <Coe11_11_tableRow index={index + 1} key={index} />
    ));
  };

  return (
    <Box className={classes.screen} sx={{ p: 10 }}>
      <table style={{ borderCollapse: "collapse", width: "100%" }}>
        <tbody>
          {renderRows()}
          <tr>
            <td
              colSpan={6}
              style={{
                padding: "5px",
                fontFamily: "Mincho",
                fontSize: "16px",
                whiteSpace: "nowrap",
                textAlign: "center",
                border: "2px solid #000",
              }}
            >
              合　計　時　間
            </td>
            <td
              style={{
                padding: "5px",
                fontFamily: "Mincho",
                fontSize: "16px",
                whiteSpace: "nowrap",
                textAlign: "end",
                border: "2px solid #000",
              }}
            >
              時間
            </td>
          </tr>
        </tbody>
      </table>
      <Typography
        sx={{ fontFamily: "Mincho", fontSize: "20px", textAlign: "justify" }}
      >
        （注意）
      </Typography>
      <Typography
        sx={{ fontFamily: "Mincho", fontSize: "20px", textAlign: "justify" }}
      >
        1&nbsp;&nbsp;&nbsp;申請者（企業単独型の場合）又は監理団体（団体監理型の場合）において講習の内容が入国後講習に相当すると認めたものについてのみ記載すること。
      </Typography>
      <Typography
        sx={{ fontFamily: "Mincho", fontSize: "20px", textAlign: "justify" }}
      >
        2&nbsp;&nbsp;&nbsp;外国の教育機関とは、現地において正規の教育機関として認定されている学校であって義務教育修了後に入学するものをいうものであること。
      </Typography>
      <Typography
        sx={{ fontFamily: "Mincho", fontSize: "20px", textAlign: "justify" }}
      >
        3&nbsp;&nbsp;&nbsp;外国の公私の機関とは、法第２条第２項第１号に規定する外国の公私の機関をいうものであること（企業単独型の場合）。
      </Typography>
      <Typography
        sx={{ fontFamily: "Mincho", fontSize: "20px", textAlign: "justify" }}
      >
        4&nbsp;&nbsp;&nbsp;実施機関の種別については、該当する種別に丸印を付すこと。
      </Typography>
      <Typography
        sx={{ fontFamily: "Mincho", fontSize: "20px", textAlign: "justify" }}
      >
        5&nbsp;&nbsp;&nbsp;技能実習生が上記科目を履修したことにつき、実施機関が証明する文書を添付すること。
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "50px",
        }}
      >
        <Box>
          <Typography sx={{ fontFamily: "Mincho", fontSize: "16px" }}>
            参考様式第１-29号（規則第８条第26号関係）
          </Typography>
          <Typography sx={{ fontFamily: "Mincho", fontSize: "16px" }}>
            D(規則第 10 条第2項第7号ハに適合することを証する書面)
          </Typography>
        </Box>
        <Box
          sx={{
            textAlign: "end",
          }}
        >
          <Typography sx={{ fontFamily: "Mincho", fontSize: "16px" }}>
            （日本産業規格Ａ列４）
          </Typography>
          <Typography sx={{ fontFamily: "Mincho", fontSize: "16px" }}>
            (入国前講習実施(予定)表別紙)
          </Typography>
        </Box>
      </Box>
      <Typography
        sx={{
          textAlign: "center",
          pt: 3,
          fontFamily: "Mincho",
          fontSize: "22px",
        }}
      >
        技 能 実 習 生 一 覧 表
      </Typography>
      <Typography
        sx={{
          textAlign: "center",
          fontFamily: "Mincho",
          fontSize: "22px",
        }}
      >
        (実施(予定)期間 2024 年 06 月 03 日 から 2024 年 08 月 12 日まで)
      </Typography>
      {printData && printData?.data?.members && (
        <table
          border="1"
          style={{
            borderCollapse: "collapse",
            width: "100%",
            margin: "50px 0px",
          }}
        >
          <thead>
            <tr>
              <td
                style={{
                  fontFamily: "Mincho",
                  fontSize: "18px",

                  textAlign: "center",
                }}
              >
                番号
              </td>
              <td
                style={{
                  fontFamily: "Mincho",
                  fontSize: "18px",

                  textAlign: "center",
                }}
              >
                技能実習生氏名
              </td>
              <td
                style={{
                  fontFamily: "Mincho",
                  fontSize: "18px",

                  textAlign: "center",
                }}
              >
                入国予定日
              </td>
              <td
                style={{
                  fontFamily: "Mincho",
                  fontSize: "18px",

                  textAlign: "center",
                  width: "45%",
                }}
              >
                備考
              </td>
            </tr>
          </thead>
          <tbody>
            {printData &&
              printData?.data?.members?.map((member, index) => (
                <tr key={index}>
                  <td
                    style={{
                      fontFamily: "Mincho",
                      fontSize: "18px",

                      textAlign: "center",
                    }}
                  >
                    {index + 1}
                  </td>
                  <td
                    style={{
                      fontFamily: "Mincho",
                      fontSize: "18px",

                      textAlign: "center",
                    }}
                  >
                    {member?.nameEng}
                  </td>
                  <td
                    style={{
                      fontFamily: "Mincho",
                      fontSize: "18px",

                      textAlign: "center",
                    }}
                  >
                    {member?.coeDcDate}
                  </td>
                  <td
                    style={{
                      fontFamily: "Mincho",
                      fontSize: "18px",

                      textAlign: "center",
                    }}
                  ></td>
                </tr>
              ))}
          </tbody>
        </table>
      )}

      <Typography
        sx={{ fontFamily: "Mincho", fontSize: "20px", textAlign: "right" }}
      >
        {firstMember && `${splitDate(firstMember.coeDcDate).year} 年 `}
        &nbsp;&nbsp;
        {firstMember && `${splitDate(firstMember.coeDcDate).month} 月 `}
        &nbsp;&nbsp;
        {firstMember && `${splitDate(firstMember.coeDcDate).day} 日 `}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "end", flexDirection: "column" }}>
        <Typography
          sx={{
            fontFamily: "Mincho",
            width: "50%",
            fontSize: "20px",
            textAlign: "left",
          }}
        >
          監理団体の名称
        </Typography>
        <Box sx={{ width: "50%", display: "flex", gap: "150px" }}>
          <Typography
            sx={{
              fontFamily: "Mincho",

              fontSize: "20px",
              textAlign: "left",
            }}
          >
            作成責任者 役職・氏名
          </Typography>
          <Typography
            sx={{
              fontFamily: "Mincho",

              fontSize: "20px",
              textAlign: "left",
              border: "1px solid black",
              borderRadius: "50%",
              padding: "0px 5px",
            }}
          >
            印
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Coe11_12;
