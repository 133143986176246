import React, { useRef, useState, forwardRef, useEffect, Fragment, useMemo } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import PrintIcon from '@mui/icons-material/Print';
import { useReactToPrint } from 'react-to-print';
import DlTit from './demand/tit/DlTit';
import DlSsw from './demand/ssw/DlSsw';
import DlSw from './demand/sw/DlSw';
import PredepTitssw from './preDep/titssw/PrdepTitssw';
import PredepSw from './preDep/sw/PredepSw';
import SmartCardTitssw from './smartCard/titssw/SmartCardTitssw';
import SmartCardSw from './smartCard/sw/SmartCardSw';
import { useDispatch } from 'react-redux';
import { resetData } from '../../slices/backOffice/pdfDataSlice';
import Coe from './coe/Coe';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PrintDialog = ({ isOpen, setIsOpen, type, dataType, visaType, typeId }) => {
  const [open, setOpen] = useState(isOpen);
  const printRef = useRef();
  const dispatch = useDispatch()

  const handleClose = async () => {
    await dispatch(resetData())
    setIsOpen(false);
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: Date.now(),
  });

  const renderOption = useMemo(() => {
    console.log(dataType)
    switch (dataType) {
      case 'demand_letter':
        return (
          (Number(visaType) === 1 && <DlTit id={typeId} gpOrMem={type} toggleDialog={setIsOpen} />) ||
          (Number(visaType) === 2 && <DlSsw id={typeId} gpOrMem={type} toggleDialog={setIsOpen} />) ||
          (Number(visaType) === 3 && <DlSw id={typeId} gpOrMem={type} toggleDialog={setIsOpen} />)
        );
      case 'coe':
        return <Coe id={typeId} gpOrMem={type} toggleDialog={setIsOpen}></Coe>
      case 'pre_dep':
          return (
            ((Number(visaType) === 1 || Number(visaType) === 2) && <PredepTitssw id={typeId} gpOrMem={type} toggleDialog={setIsOpen} />) ||
            (Number(visaType) === 3 && <PredepSw id={typeId} gpOrMem={type} toggleDialog={setIsOpen}/>)
          );
      case 'smartcard':
        return (
          ((Number(visaType) === 1 || Number(visaType) === 2) && <SmartCardTitssw id={typeId} gpOrMem={type} toggleDialog={setIsOpen} />) ||
          (Number(visaType) === 3 && <SmartCardSw id={typeId} gpOrMem={type} toggleDialog={setIsOpen}/>)
        );
      default:
        return null;
    }
  }, [dataType, Number(visaType), typeId, type]);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Fragment>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <Toolbar sx={{ mb: 3 }}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{ ml: 3 }}
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            { dataType === 'demand_letter' && `Demand Letter` ||
              dataType === 'coe' && "COE" ||
              dataType === 'pre_dep' && "Pre departure" ||
              dataType === 'smartcard' && "Smart Card"}
          </Typography>
          <Button
            sx={{ mr: 3 }}
            startIcon={<PrintIcon />}
            autoFocus
            color="primary"
            variant="text"
            onClick={() => handlePrint()}
          >
            Print
          </Button>
        </Toolbar>
        <div
          ref={printRef}
          style={{
            width: { xs: '100%', lg: '100%' },
            height: '100%',
            padding: 0,
            '@media print': {
              width: { xs: '210mm', lg: '210mm' },
              height: '297mm',
              boxSizing: 'border-box',
            },
          }}
          className="custom-table-container"
        >
          {/* body */}
          {renderOption}
        </div>
      </Dialog>
    </Fragment>
  );
};

export default PrintDialog;
