import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { convertToMyanmarNumbers } from "../../../../utils/enTomm";
import { useSelector } from "react-redux";
import { splitDate } from "../../../../utils/splitDate";

const useStyles = makeStyles({
  screen: {
    pageBreakAfter: "always",
    // minHeight: "100vh",
  },
  table: {
    borderCollapse: "collapse",
    width: "100%",
    lineHeight: "40px",
    marginTop: "15px",
  },
  tableHeaderCell: {
    textAlign: "center",
    verticalAlign: "middle",
    fontFamily: "PdsFont",
    fontSize: "20px",
    padding: "5px",
    border: "2px solid #000",
  },
  tableCell: {
    textAlign: "center",
    verticalAlign: "middle",
    fontFamily: "PdsFont",
    fontSize: "20px",
    padding: "5px",
    border: "2px solid #000",
  },
});

const ListOfWorkerNd = () => {
    const [ cardData,setCardData ] = useState(null)
    const pdfData = useSelector(state=> state.PrintData.data)
    const classes = useStyles();
    
    useEffect(()=>{
        if(pdfData){
            const { data } = pdfData
            setCardData(data)
        }
    },[pdfData])
  return (
    <Box className={classes.screen} sx={{ p: 15 }}>
      {
        cardData &&
        <>
          <Box
              sx={{
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "PdsFont",
                  fontSize: "20px",
                  lineHeight: "40px",
                  fontWeight: "bold",
                }}
              >
                CLOVER MANDALAY CO.,LTD
              </Typography>
              <Typography
                sx={{ fontFamily: "PdsFont", fontSize: "20px", lineHeight: "40px" }}
              >
                {`${splitDate(cardData.smartCardDateNorthDagon).day}-${splitDate(cardData.smartCardDateNorthDagon).month}-${splitDate(cardData.smartCardDateNorthDagon).year}`}
                တွင် Owic Card ထုတ်ယူသူစာရင်း
              </Typography>
            </Box>
            <table className={classes.table}>
              <tbody>
                <tr>
                  <td className={classes.tableHeaderCell}></td>
                  <td className={classes.tableHeaderCell}>မြန်မာကုမ္ပဏီ</td>
                  <td className={classes.tableHeaderCell}>ဂျပန်ကုမ္ပဏီ</td>
                  <td className={classes.tableHeaderCell}>အလုပ်သမားအမည်</td>
                  <td className={classes.tableHeaderCell}>
                    ကျား/မ
                  </td>
                  <td className={classes.tableHeaderCell}>ဦးရေ</td>
                </tr>
                {cardData.listOfWorkers.map((person, index) => (
                  <tr key={index}>
                    <td className={classes.tableCell}>
                      {convertToMyanmarNumbers(String(index + 1))}
                    </td>
                    <td className={classes.tableCell}>Clover Mandalay Co.,Ltd</td>
                    <td
                      className={classes.tableCell}
                      style={{ whiteSpace: "nowrap" }}
                    >
                        {cardData?.company?.nameEng}
                    </td>
                    <td
                      className={classes.tableCell}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {person.nameEng}
                    </td>
                    <td
                      className={classes.tableCell}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {person.gender === "男" ? "ကျား" : "မ"}
                    </td>
                    <td
                      className={classes.tableCell}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      ၁
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          <div style={{ height: "400px", width: "100%" }}></div>
        </>
      }
    </Box>
  );
};


export default ListOfWorkerNd;