import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { splitDate } from "../../../utils/splitDate";

const useStyles = makeStyles({
  tableCellStyle: {
    textAlign: "center",
    padding: "5px",
    border: "2px solid #000",
  },
  screen: {
    pageBreakAfter: "always",
  },
  jpContent: {
    fontFamily: "Mincho",
    fontSize: "20px",
    lineHeight: "30px",
  },
});

const Coe7_2 = ({ index }) => {
  const classes = useStyles();
  const { data: printData } = useSelector((state) => state.PrintData);
  const [memberData, setMemberData] = useState([]);

  useEffect(() => {
    if (printData && printData.data && printData.data.members) {
      const data = printData.data.members[index];
      setMemberData(data);
    }
  }, [printData, index]);

  const renderTableRow = (
    number,
    institution,
    title,
    mmtittle,
    date,
    amount
  ) => (
    <tr key={number}>
      <td
        className={classes.tableCellStyle}
        style={{ width: "35px", border: "2px solid #000" }}
      >
        <Typography className={classes.jpContent} sx={{ textAlign: "center" }}>
          {number}
        </Typography>
      </td>
      <td
        className={classes.tableCellStyle}
        style={{ textAlign: "end", width: "360px" }}
      >
        <Typography
          className={classes.jpContent}
          dangerouslySetInnerHTML={{ __html: institution }}
        />
      </td>
      <td className={classes.tableCellStyle} style={{ width: "150px" }}>
        <Typography className={classes.jpContent}>
          {title} <br /> {mmtittle}
        </Typography>
      </td>
      <td
        className={classes.tableCellStyle}
        style={{ textAlign: "end", width: "150px" }}
      >
        <Typography className={classes.jpContent}>
          {date}{" "}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "20px",
              fontWeight: "normal",
            }}
          >
            <Typography>နှစ်</Typography>
            <Typography>လ</Typography>
            <Typography>ရက် </Typography>
          </Box>
        </Typography>
      </td>
      <td className={classes.tableCellStyle}>
        <Typography className={classes.jpContent}>
          {amount} <br />
          <Typography
            sx={{
              marginLeft: "30px",
            }}
          >
            {" "}
            ယန်း
          </Typography>
        </Typography>
      </td>
    </tr>
  );

  return (
    <Box className={classes.screen} sx={{ px: 15, pt: 10 }}>
      <table border={1} style={{ borderCollapse: "collapse", width: "100%" }}>
        <tbody>
          {renderTableRow(
            3,
            "(             )",
            <>
              {" "}
              その他
              <br />
              (           )
            </>,
            "အခြား",
            " 年 月 日",
            "（     円 ）"
          )}
          {renderTableRow(
            4,
            "(             )",
            <>
              {" "}
              その他
              <br />
              (           )
            </>,
            "အခြား",
            " 年 月 日",
            "（     円 ）"
          )}
          {renderTableRow(
            5,
            "(             )",
            <>
              {" "}
              その他
              <br />
              (           )
            </>,
            "အခြား",
            " 年 月 日",
            "（     円 ）"
          )}
          <tr>
            <td
              colSpan={4}
              style={{
                borderLeft: "1px dotted white",
                borderBottom: "1px dotted white",
              }}
            ></td>
            <td
              align="right"
              style={{
                textAlign: "center",
                padding: "5px",
                border: "2px solid #000",
              }}
            >
              <Typography className={classes.jpContent}>
                計&nbsp; （     円 ）
                <br />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <Typography>စုစုပေါင်း</Typography>{" "}
                  <Typography>ယန်း</Typography>
                </Box>
              </Typography>
            </td>
          </tr>
        </tbody>
      </table>
      <Box height="10px" />
      <Typography className={classes.jpContent}>（注意）</Typography>
      <Typography className={classes.jpContent}>
        １　外国の準備機関には、技能実習生の本国での勤務先、入国前講習を実施する機関など技能実習の準備に関与する一切の機関が含まれる。
        <br /> နိုင်ငံခြားတိုင်းပြည်များ၏ ပြင်ဆင်မှုကာလသည် နည်းပညာအလုပ်သင်
        သင်တန်းသားများ၏ မိခင်တိုင်းပြည်တွင်ရှိသော အလုပ်လုပ်ကိုင်သည့် နေရာ
        ဂျပန်နိုင်ငံသို့ မဝင်မီ လေ့ကျင့်ရေးပြုလုပ်မည့် အချိန်ကာလ နှင့်
        နည်းပညာအလုပ်သင်၏ ပြင်ဆင်မှုတွင်ပါဝင်သည့် မည်သည့် အဖွဲ့အစည်းမဆို
        ပါဝင်ပါသည်။
      </Typography>

      <Typography className={classes.jpContent}>
        ２　徴収した機関については、名称のほか、括弧書きで技能実習生の送出において果たした役割を記載すること。
        <br /> ငွေကောက်ခံခြင်းအဖွဲ့အစည်းအနေဖြင့် အမည်နာမည်များအပြင်
        ပြန်လည်သုံးသပ်စီစစ်ပြီး နည်းပညာအလုပ်သင် သင်တန်းသားများ၏
        ပို့ဆောင်မှုအပိုင်းတွင် ပါဝင်လုပ်ဆောင်မှု အခန်းကဏ္ဍများကို ဖော်ပြရမည်။
      </Typography>
      <Typography className={classes.jpContent}>
        ３　「その他」の徴収費用については、括弧書きで名目を記載すること。
        <br /> “အခြား” ၏ငွေကောက်ခံခြင်းကုန်ကျစရိတ်နှင့်ပတ်သက်၍သည်
        လက်သည်းကွင်းဖြင့် အပြည့်အစုံအမည်ဖော်ပြရန်ဖြစ်သည်။
      </Typography>
      <Typography className={classes.jpContent}>
        ４　額については、現地通貨又は米ドルで記載し、括弧書きで日本円に換算した金額を記載すること。
        <br /> စုစုပေါင်း ကုန်ကျစရိတ်များသည် ဒေသသုံးငွေကျပ် သို့မဟုတ်
        အမေရိကန်ဒေါ်လာ ငွေကြေးများနှင့် ညီမျှသော ဂျပန်းယန်းငွေပမာဏကို
        ဖော်ပြရန်ဖြစ်သည်။
      </Typography>
      <Typography
        className={classes.jpContent}
        sx={{ textIndent: "20px", py: "30px", letterSpacing: 3 }}
      >
        技能実習生から２に記載の金額の費用を徴収し、その内訳について技能実習生に十分に理解させるとともに、送出に関与した他の機関が技能実習生から３に記載の金額の費用を徴収したことを把握しました。また、２及び３に記載の費用以外の費用については、技能実習生が徴収されていないことを確認しました。
        <br /> နည်းပညာ သင်တန်းသားမှ အပိုဒ် (၂) တွင် ဖော်ပြထားသည့်ငွေပမာဏ
        ကုန်ကျစရိတ်ကို သိမ်းဆည်းခြင်း ထိုရှင်းလင်းချက်နှင့်ပတ်သက်ပြီး
        နည်းပညာသင်တန်း သားကို ကောင်းစွာနားလည်စေပြီး စေလွှတ်ခြင်းနှင့် ပတ်သက်သည့်
        အခြားအဖွဲ့အစည်းများမှ နည်းပညာ လက်တွေ့သင်တန်းသားထံမှ အပိုဒ် (၃) တွင်
        ဖော်ပြ ထားသည့် ငွေပမာဏ ကုန်ကျစရိတ်ကို သိမ်းဆည်းထားခြင်းကို နားလည်ပါသည်။
        ထို့အပြင် ၂ နှင့် ၃ တွင် ဖော်ပြထားသည့် ကုန်ကျစရိတ်မှအပ အခြားသော
        ကုန်ကျစရိတ်များ နှင့်ပတ်သက်၍ နည်းပညာသင်တန်းသားထံမှ
        ရယူထားခြင်းမရှိကြောင်းအတည်ပြုပါသည်။
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "end", py: "10px" }}>
        <Box sx={{ display: "flex" }}>
          <Typography className={classes.jpContent} sx={{ mr: 3 }}>
            {splitDate(memberData.coeDcDate).year} 年
            <Typography
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              နှစ်
            </Typography>
          </Typography>
          <Typography className={classes.jpContent} sx={{ mr: 3 }}>
            {splitDate(memberData.coeDcDate).month} 月{" "}
            <Typography
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              လ
            </Typography>
          </Typography>
          <Typography className={classes.jpContent} sx={{ mr: 3 }}>
            {splitDate(memberData.coeDcDate).day} 日{" "}
            <Typography
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              ရက်
            </Typography>
          </Typography>
          <Typography className={classes.jpContent}>
            作成{" "}
            <Typography
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              ရက်စွဲ
            </Typography>
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <table border="1" style={{ borderCollapse: "collapse", width: "80%" }}>
          <tbody>
            <tr>
              <td colSpan={6} style={{ padding: "10px" }}>
                <Typography className={classes.jpContent}>
                  取次送出機関の氏名又は名称{" "}
                  <Typography>
                    အေဂျင်စီစေလွှတ်သည့်အဖွဲ့အစည်းအမည်အပြည့်အစုံ(သို့) အမည်
                  </Typography>
                </Typography>
              </td>
              <td colSpan={9} style={{ padding: "10px" }}>
                <Typography className={classes.jpContent}>
                  CLOVER MANDALAY CO.,LTD
                </Typography>
              </td>
            </tr>
            <tr>
              <td style={{ padding: "10px" }}>
                <Typography className={classes.jpContent}>
                  送出機関番号{" "}
                  <Typography>အလုပ်သမား ပို့ဆောင်ရေး အေဂျင်စီ</Typography>
                </Typography>
              </td>
              <td
                width="25px"
                style={{
                  textAlign: "center",
                  borderRight: "1px dotted #808080",
                }}
              >
                <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                  M
                </Typography>
              </td>
              <td
                width="25px"
                style={{
                  textAlign: "center",
                  borderRight: "1px dotted #808080",
                }}
              >
                <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                  M
                </Typography>
              </td>
              <td width="25px" style={{ textAlign: "center" }}>
                <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                  R
                </Typography>
              </td>
              <td
                width="25px"
                style={{
                  textAlign: "center",
                  borderRight: "1px dotted #808080",
                }}
              >
                <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                  0
                </Typography>
              </td>
              <td
                width="25px"
                style={{
                  textAlign: "center",
                  borderRight: "1px dotted #808080",
                }}
              >
                <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                  0
                </Typography>
              </td>
              <td
                width="25px"
                style={{
                  textAlign: "center",
                  borderRight: "1px dotted #808080",
                }}
              >
                <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                  0
                </Typography>
              </td>
              <td
                width="25px"
                style={{
                  textAlign: "center",
                  borderRight: "1px dotted #808080",
                }}
              >
                <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                  2
                </Typography>
              </td>
              <td
                width="25px"
                style={{
                  textAlign: "center",
                  borderRight: "1px dotted #808080",
                }}
              >
                <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                  3
                </Typography>
              </td>
              <td width="25px" style={{ textAlign: "center" }}>
                <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                  0
                </Typography>
              </td>
              <td style={{ padding: "10px" }}>
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontFamily: "Mincho",
                    whiteSpace: "nowrap",
                  }}
                >
                  整理番号 <Typography>စာရွက်စာတမ်း နံပါတ်</Typography>
                </Typography>
              </td>
              <td
                style={{
                  textAlign: "center",
                  width: "30px",
                  borderRight: "1px dotted #808080",
                }}
              ></td>
              <td
                style={{
                  textAlign: "center",
                  width: "30px",
                  borderRight: "1px dotted #808080",
                }}
              ></td>
              <td
                style={{
                  textAlign: "center",
                  width: "30px",
                  borderRight: "1px dotted #808080",
                }}
              ></td>
              <td style={{ textAlign: "center", width: "30px" }}></td>
            </tr>
            <tr>
              <td colSpan={6} style={{ padding: "20px 10px" }}>
                <Typography className={classes.jpContent}>
                  作成責任者　役職・氏名{" "}
                  <Typography>တာဝန်ရှိသူ ရာထူး ၊ အမည်</Typography>
                </Typography>
              </td>
              <td colSpan={9} style={{ padding: "20px 10px" }}>
                <Typography className={classes.jpContent}>
                  代表者　CHIT SU WAI
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>
      </Box>
      <Typography
        className={classes.jpContent}
        sx={{ textIndent: "20px", py: "30px" }}
      >
        取次送出機関及び送出に関与した他の機関に２及び３に記載の金額を支払い、その内訳について理解しました。また、２及び３に記載の費用以外の費用については、徴収されていません。
        <Typography>
          အေဂျင်စီစေလွှတ်သည့်အဖွဲ့အစည်းနှင့် စေလွှတ်ခြင်းနှင့်
          ပတ်သက်သည့်အခြားသော အဖွဲ့အစည်းက (၂) နှင့် (၃) တွင်
          ဖော်ပြထားသောငွေပမာဏကို ပေးအပ်ခြင်း ၊ ၎င်းကို ရှင်းလင်းပြခြင်းကို
          နားလည်ပါသည် ။ ထို့ပြင် (၂) နှင့် (၃) တွင် ဖော်ပြထားသည့်
          ကုန်ကျစရိတ်အပြင်အခြားသော ကုန်ကျစရိတ်များကို ကောက်ခံခြင်းမရှိပါ ။
        </Typography>
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "end",
          py: "10px",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Typography className={classes.jpContent} sx={{ mr: 3 }}>
            {splitDate(memberData.coeDcDate).year} 年{" "}
            <Typography
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              နှစ်
            </Typography>
          </Typography>
          <Typography className={classes.jpContent} sx={{ mr: 3 }}>
            {splitDate(memberData.coeDcDate).month} 月{" "}
            <Typography
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              လ
            </Typography>
          </Typography>
          <Typography className={classes.jpContent} sx={{ mr: 3 }}>
            {splitDate(memberData.coeDcDate).day} 日{" "}
            <Typography
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              ရက်
            </Typography>
          </Typography>
          <Typography className={classes.jpContent}>
            作成{" "}
            <Typography
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              ရက်စွဲ
            </Typography>
          </Typography>
        </Box>
        <Box sx={{ display: "flex", pt: "50px" }}>
          <Typography className={classes.jpContent}>
            技能実習生の署名 <Typography>နည်းပညာ သင်တန်းသား လက်မှတ်</Typography>
          </Typography>
          <Box
            sx={{
              borderBottom: "1px solid black",
              height: "20px",
              width: "200px",
              ml: "30px",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Coe7_2;
