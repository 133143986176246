import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import Coe3_2 from "./Coe3_2";
import Coe3_3 from "./Coe3_3";
import { splitDate } from "../../../utils/splitDate";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  screen: {
    pageBreakAfter: "always",
  },
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  centerText: {
    textAlign: "center",
    paddingTop: 3,
    paddingBottom: 3,
  },
  tableCell: {
    textAlign: "end",
    fontSize: 18,
    fontFamily: "Mincho",
    padding: 10,
    lineHeight: "25px",
  },
  borderedTable: {
    borderCollapse: "collapse",
    width: "100%",
  },
  fontSize24: {
    fontSize: 24,
    letterSpacing: 5,
    fontFamily: "Mincho",
  },
  fontSize18: {
    fontSize: 18,
    fontFamily: "Mincho"
  },
  fontSize16: {
    fontSize: 16,
    fontFamily: "Mincho"
  },
  fontFamilyPdsFont: {
    fontFamily: "PdsFont",
  },
});

const Coe3_1 = ({index}) => {
  const classes = useStyles();
  const { data: printData } = useSelector(state=> state.PrintData)
  const [ memberData,setMemberData ] = useState([])

  useEffect(()=>{
    if(printData && printData.data && printData.data.members){
      const data = printData.data.members[index]
      setMemberData(data)
    }
  },[printData, index])

  return (
    <Box className={classes.screen} sx={{ px: 15, pt: 10 }}>
      <Box className={classes.flexContainer}>
        <Box>
          <Typography className={classes.fontSize16} lineHeight="30px" fontFamily="Mincho">
            参考様式第１-３号（規則第８条第４号関係）ミャンマー語
          </Typography>
          <Typography className={classes.fontSize16} lineHeight="30px" fontFamily="PdsFont">
            ရည်ညွှန်းပုံစံ အမှတ် - 1 - 3 ( စည်းမျဉ်းစာပိုဒ် 8 အမှတ်စဉ် 4 ) မြန်မာဘာသာ
          </Typography>
          <Typography className={classes.fontSize16} fontFamily="PdsFont">Ａ・Ｂ・Ｃ・Ｄ・Ｅ・Ｆ</Typography>
        </Box>
        <Box>
          <Typography className={classes.fontSize16} fontFamily="Mincho">（日本工業規格Ａ列４）</Typography>
          <Typography className={classes.fontSize16} fontFamily="PdsFont">
            (ဂျပန်စက်မှု သတ်မှတ်စံနှုန်း A - 4)
          </Typography>
        </Box>
      </Box>

      <Box className={classes.centerText}>
        <Typography className={classes.fontSize24} py={5}>
          技 能 実 習 生 の 履 歴 書
        </Typography>
        <Typography className={classes.fontSize18} pb={5} fontFamily="PdsFont">
          နည်းပညာအလုပ်သင် သင်တန်းသားကိုယ်ရေးရာဇဝင်အကျဉ်း
        </Typography>
      </Box>

      <Box>
        <table style={{ width: '40%', marginLeft: 'auto' }}>
          <tbody>
            <tr>
              <td className={classes.tableCell} style={{ lineHeight: '20px' }}>{splitDate(memberData.coeDcDate).year} 年</td>
              <td className={classes.tableCell} style={{ lineHeight: '20px' }}>{splitDate(memberData.coeDcDate).month} 月</td>
              <td className={classes.tableCell} style={{ lineHeight: '20px' }}>{splitDate(memberData.coeDcDate).day} 日</td>
              <td className={classes.tableCell} style={{ lineHeight: '20px' }}>作成</td>
            </tr>
            <tr>
              <td className={classes.tableCell} style={{ lineHeight: '20px' }}>နှစ်</td>
              <td className={classes.tableCell} style={{ lineHeight: '20px' }}>လ</td>
              <td className={classes.tableCell} style={{ lineHeight: '20px' }}>ရက်</td>
              <td className={classes.tableCell} style={{ lineHeight: '20px' }}>ဖြည့်ရက်</td>
            </tr>
          </tbody>
        </table>
      </Box>

      <table border='2' className={classes.borderedTable}>
        <tbody>
          <tr>
            <td rowSpan="2" style={{ padding: '5px', border: '2px solid #000' }}>
              <Typography className={classes.fontSize18}>① 氏名</Typography>
              <Typography className={classes.fontFamilyPdsFont}>&nbsp;	&nbsp;&nbsp;&nbsp;&nbsp;အမည်</Typography>
            </td>
            <td style={{ textAlign: 'center', padding: '5px', border: '2px solid #000' }}>
              <Typography className={classes.fontSize18}>ローマ字</Typography>
              <Typography className={classes.fontFamilyPdsFont}>အင်္ဂလိပ်စာလုံး</Typography>
            </td>
            <td colSpan={2} style={{ padding: '5px', border: '2px solid #000' }}>
              <Typography className={classes.fontSize18}>{memberData.nameEng}</Typography>
            </td>
            <td style={{ padding: '5px', border: '2px solid #000' }}>
              <Typography className={classes.fontSize18}>② 性別</Typography>
              <Typography className={classes.fontFamilyPdsFont}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ကျား/မ</Typography>
            </td>
            <td style={{ textAlign: 'center', padding: '5px', border: '2px solid #000', width: '200px' }}>
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td className={Number(memberData.gender) === 1 ? "roundedBorder" : ""} style={{ fontSize: '18px', fontFamily: "Mincho", padding: '1px 0px' }}>男</td>
                    <td style={{ fontSize: '20px', fontFamily: "Mincho" }}>.</td>
                    <td className={Number(memberData.gender) === 2 ? "roundedBorder" : ""} style={{ fontSize: '18px', fontFamily: "Mincho", padding: '0px 5px' }}>女</td>
                  </tr>
                  <tr>
                    <td style={{ fontSize: '18px', fontFamily: "PdsFont" }}>ကျား</td>
                    <td style={{ fontSize: '18px', fontFamily: "PdsFont" }}>.</td>
                    <td style={{ fontSize: '18px', fontFamily: "PdsFont" }}>မ</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: 'center', padding: '5px', border: '2px solid #000' }}>
              <Typography className={classes.fontSize18} whiteSpace="nowrap">漢字</Typography>
              <Typography className={classes.fontFamilyPdsFont} whiteSpace="nowrap">ခန်းဂျီးစာလုံး</Typography>
            </td>
            <td colSpan={2} style={{ padding: '5px', border: '2px solid #000' }}>
              {/* <Typography className={classes.fontSize18}>{memberData.nameJp}</Typography> */}
            </td>
            <td style={{ padding: '5px', border: '2px solid #000' }}>
              <Typography className={classes.fontSize18}>③ 配偶者</Typography>
              <Typography className={classes.fontFamilyPdsFont} whiteSpace="nowrap">&nbsp;&nbsp;&nbsp;&nbsp;အိမ်ထောင်ရှိ/မရှိ</Typography>
            </td>
            <td style={{ textAlign: 'center', padding: '5px', border: '2px solid #000' }}>
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td className={Number(memberData.marriageStatus) === 1 ? "roundedBorder" : ""}  style={{ fontSize: '18px', fontFamily: "Mincho", padding: '0px 5px' }}>有</td>
                    <td style={{ fontSize: '18px', fontFamily: "Mincho" }}>.</td>
                    <td className={Number(memberData.marriageStatus) === 2 ? "roundedBorder" : ""} style={{ fontSize: '18px', fontFamily: "Mincho", padding: '0px 5px' }}>無</td>
                  </tr>
                  <tr>
                    <td style={{ fontSize: '18px', fontFamily: "PdsFont" }}>ရှိ</td>
                    <td style={{ fontSize: '18px', fontFamily: "PdsFont" }}>.</td>
                    <td style={{ fontSize: '18px', fontFamily: "PdsFont" }}>မရှိ</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <Coe3_2 info={memberData}/>
          <Coe3_3 info={memberData}/>
        </tbody>
      </table>
    </Box>
  );
}

export default Coe3_1;
