import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Typography } from "@mui/material";
import { convertToMyanmarNumbers } from "../../../../utils/enTomm";
import { useSelector } from "react-redux";
import { splitDate } from "../../../../utils/splitDate";
import { getBurmeseMonthName } from "../../../../utils/getBurmeseMonthName";
import { getFullMonthName } from "../../../../utils/getFullMonthName";

const useStyles = makeStyles({
  screen: {
    pageBreakAfter: "always",
  },
});

const DlMm = () => {
  const [ dmData,setDmData ] = useState(null)
  const pdfData = useSelector(state=> state.PrintData.data)
  const classes = useStyles();
  
  const demandList = dmData
    ? [
        {
          title: "ကုမ္ပဏီအမည်",
          data: dmData.company.nameEng,
        },
        {
          title: "ကုမ္ပဏီလိပ်စာ၊ ဖုန်း၊ အီးမေးလ်",
          data: `${dmData.company.addressEng}, TEL: ${dmData.company.phone}, EMAIL: ${dmData.company.email}`,
        },
        {
          title: "အလုပ်သမားဦးရေ",
          data: `${convertToMyanmarNumbers(dmData.numberOfWorkers.total)} ဦး`,
        },
        {
          title: "ကျား၊ မအမျိုးအစား",
          data: `${
            dmData.numberOfWorkers.male > 0 &&
            dmData.numberOfWorkers.female === 0
              ? "ကျား"
              : dmData.numberOfWorkers.male === 0 &&
                dmData.numberOfWorkers.female > 0
              ? "မ"
              : `ကျား(${dmData.numberOfWorkers.male})/ မ(${dmData.numberOfWorkers.female})`
          }`,
        },
        {
          title: "အသက်",
          data: dmData.ageLimitationMM,
        },
        {
          title: "အရည်အချင်း",
          data: "(၁) ဂျပန်စာ Level N4 (သို့မဟုတ်) JFT လက်မှတ်၊",
          data2: `(၂) ${dmData.qualificationMM}`,
        },
        {
          title: "ကျန်းမာရေး၊ ကိုယ်ခန္ဓာကျန်းမာရမည်",
          data: "ပြည်ပသို့ မထွက်ခွာမီစစ်ဆေးသော ကျန်းမာရေးဆေးစစ်ချက်အောင်ရမည်။",
        },
        {
          title: "အလုပ်အမျိုးအစား",
          data: dmData.jobCategoryMM,
        },
        {
          title: "အလုပ်နေရာ",
          data: dmData.workPlace,
        },
        {
          title: "အလုပ်ချိန်",
          data: dmData.workingHoursMM,
        },
        {
          title: "အခြေခံလစာ",
          data: `တစ်လလျှင် ${Number(dmData.basicSalary).toLocaleString()} ယန်း`,
        },
        {
          title: "လခပေးချေနည်း",
          data:
            Number(dmData.salaryPaymentMethod) === 1
              ? "ဘဏ်မှငွေလွှဲပေးခြင်း"
              : "ငွေသားဖြင့်ပေးချေခြင်း",
        },
        {
          title: "အချိန်ပို",
          data: "အခြေခံလုပ်ခနှုန်း၏၂၅ ရာခိုင်နှုန်း",
        },
        {
          title: "အလုပ်လုပ်ရက်",
          data: dmData.workingDayMM,
        },
        {
          title: "ပိတ်ရက်၊ နားရက်",
          data: dmData.holidayMM,
        },
        {
          title: "နေစရာ",
          data: "ကုမ္ပဏီမှစီစဉ်ပေးသည်။",
          data2: "ကျွမ်းကျင်လုပ်သား၏လစဉ်လစာထဲမှပေးချေရမည်။",
        },
        {
          title: "သွားလာရေး",
          data: dmData.transportationMM,
        },
        {
          title: "လေယာဉ်လက်မှတ်",
          data: dmData.airTicketMM,
        },
        {
          title: "အစားအသောက်",
          data: "ကျွမ်းကျင်လုပ်သားမှကိုယ်တိုင်ပေးချေရမည်",
        },
        {
          title: "စာချုပ်ကာလ",
          data: "၁ နှစ် (၁ နှစ်စီသက်တမ်းတိုးသွားမည်)",
        },
        {
          title: "ကျန်းမာရေးအာမခံ",
          data: "(၁)ကျန်းမာရေးအာမခံ (လူမှုရေးအာမခံပါဝင်သည်။)",
          data2: "(၂)JITCO သင်တန်းသားဘက်စုံအာမခံ",
        },
        {
          title: "အခြားသတ်မှတ်ချက်",
          data: "ကုမ္ပဏီ၏အလုပ်ခန့်အပ်မှုစည်းမျဉ်းများကိုလိုက်နာရမည်။",
        },
        {
          title: "ကုန်ကျစရိတ် ဝန်ဆောင်ခမှာ ဒေါ်လာ ၁၅၀၀ ဖြစ်ပါသည်။",
          data: "",
        },
      ]
    : [];
  
  useEffect(()=>{
    if(pdfData){
      const { data } = pdfData
      setDmData(data)
    }
  },[pdfData])

  return (
    <Box className={classes.screen} sx={{ px: 15, py: 10 }}>
      {dmData && (
        <Box>
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <Typography
              sx={{
                fontFamily: "PdsFontNumber",
                fontSize: "20px",
                fontWeight: "bold",
                mb: 2,
              }}
            >
              {dmData.organization.nameEng}
            </Typography>
            <Typography
              sx={{
                fontFamily: "PdsFont",
                lineHeight: "30px",
                fontSize: "20px",
              }}
            >
              {dmData.organization.addressEng}
            </Typography>
            <hr style={{ margin: "10px 0px", border: "1px solid #000" }}></hr>
            <Typography
              sx={{
                fontFamily: "PdsFont",
                lineHeight: "30px",
                fontSize: "20px",
              }}
            >
              FAX: {dmData.organization.fax}, E-mail:{" "}
              {dmData.organization.email}
            </Typography>
            <hr style={{ margin: "10px 0px", border: "1px solid #000" }}></hr>
          </Box>
          <Typography
            sx={{
              my: "10px",
              textAlign: "end",
              fontFamily: "PdsFont",
              fontSize: "20px",
            }}
          >
            ရက်စွဲ၊
            {/* changing month into burmese name */}
            &nbsp;
            {getBurmeseMonthName(
              getFullMonthName(
                splitDate(dmData.demandLetterApplicationDate).month
              )
            )}
            လ &nbsp;
            {convertToMyanmarNumbers(
              splitDate(dmData.demandLetterApplicationDate).day
            )}
            ရက်၊
            {convertToMyanmarNumbers(
              splitDate(dmData.demandLetterApplicationDate).year
            )}
            ခုနှစ်
          </Typography>
          <Typography sx={{ fontFamily: "PdsFont", fontSize: "20px", mb: 2 }}>
            CLOVER MANDALAY Co.,Ltd
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Typography
                sx={{
                  fontFamily: "PdsFont",
                  lineHeight: "30px",
                  fontSize: "20px",
                }}
              >
                လိပ်စာ
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Typography
                  sx={{
                    pr: "5px",
                    fontFamily: "PdsFont",
                    lineHeight: "30px",
                    fontSize: "20px",
                  }}
                >
                  :
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "PdsFont",
                    lineHeight: "30px",
                    fontSize: "20px",
                  }}
                >
                  〒05011၊ အမှတ် (၂၄)၊ ကံ့ကော်လမ်း၊ ၂၂ x ၂၃ လမ်းကြား၊၈၈ x ၈၉
                  လမ်းကြား၊ အောင်မြေသာစံမြို့နယ်၊ မန္တလေးမြို့၊ မြန်မာနိုင်ငံ
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Typography
                sx={{
                  fontFamily: "PdsFont",
                  lineHeight: "30px",
                  fontSize: "20px",
                }}
              >
                ဖုန်း
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Typography
                  sx={{
                    pr: "5px",
                    fontFamily: "PdsFont",
                    lineHeight: "30px",
                    fontSize: "20px",
                  }}
                >
                  :
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "PdsFont",
                    lineHeight: "30px",
                    fontSize: "20px",
                  }}
                >
                  +95-9-769-80-4488
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Typography
                sx={{
                  fontFamily: "PdsFont",
                  lineHeight: "30px",
                  fontSize: "20px",
                }}
              >
                အီးမေးလ်
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Box
                sx={{
                  display: "flex",
                  fontFamily: "PdsFont",
                  lineHeight: "30px",
                  fontSize: "20px",
                }}
              >
                <Typography
                  sx={{
                    pr: "5px",
                    fontFamily: "PdsFont",
                    lineHeight: "30px",
                    fontSize: "20px",
                  }}
                >
                  :
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "PdsFont",
                    lineHeight: "30px",
                    fontSize: "20px",
                  }}
                >
                  info@clovermandalay.com
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Typography
            sx={{
              textAlign: "center",
              py: "30px",
              fontFamily: "PdsFont",
              lineHeight: "30px",
              fontSize: "20px",
            }}
          >
            အထူးကျွမ်းကျင်လုပ်သားခေါ်ယူရန်ကမ်းလှမ်းစာ
          </Typography>
          <Typography
            sx={{
              textIndent: "50px",
              fontFamily: "PdsFont",
              lineHeight: "40px",
              fontSize: "20px",
              mb: 3,
            }}
          >
            ဤကမ်းလှမ်းစာသည် ကျွန်ုပ်တို့၏ ကုမ္ပဏီတွင်
            နည်းပညာအလုပ်သင်သင်တန်း(၂)နှစ် ပြီးမြောက်သူ {dmData.jobCategoryMM}{" "}
            ဆိုင်ရာ ကျွမ်းကျင်လုပ်သား စာမေးပွဲများ အောင်မြင်ထားသည့်
            မြန်မာနိုင်ငံတွင် သတ်မှတ်ထားသော ကျွမ်းကျင်လုပ်သားများကို ခေါ်ယူရန်
            သင်၏ အေဂျင်စီမှ အလုပ်သမားများခေါ်ယူနိုင်ရန် အပ်နှင်းသောစာဖြစ်ပါသည်။
            သင့်တွင် အထက်ပါ အလုပ်သမားခေါ်ယူခြင်းအတွက် ကိုယ်စားပြုသူအဖြစ်
            လုပ်ပိုင်ခွင့်ရှိပါသည်။
            ကျွန်ုပ်တို့၏အလုပ်သမားများခေါ်ယူခြင်းသတ်မှတ်ချက်မှာ
            အောက်ပါအတိုင်းဖြစ်ပါသည်။
          </Typography>
          {demandList.map((demandData, index) => (
            <Grid
              key={index}
              container
              spacing={2}
              sx={{
                pb: 3,
                pageBreakAfter: index === 11 ? "always" : "",
                pt: index === 12 ? 14 : 0,
              }}
            >
              <Grid item xs={index === demandList.length - 1 ? 12 : 4}>
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Typography
                    sx={{
                      mr: "20px",
                      fontFamily: "PdsFont",
                      fontSize: "20px",
                      lineHeight: "30px",
                    }}
                  >
                    {convertToMyanmarNumbers(String(index + 1))}။
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "PdsFont",
                      lineHeight: "30px",
                      fontSize: "20px",
                    }}
                  >
                    {demandData.title}
                  </Typography>
                </Box>
              </Grid>
              {index === demandList.length - 1 ? (
                <></>
              ) : (
                <Grid item xs={8}>
                  <Typography
                    sx={{
                      fontFamily: "PdsFont",
                      lineHeight: "30px",
                      fontSize: "20px",
                    }}
                  >
                    : {demandData.data}
                  </Typography>
                  <Typography
                    sx={{
                      pl: 1,
                      fontFamily: "PdsFont",
                      lineHeight: "30px",
                      fontSize: "20px",
                    }}
                  >
                    {demandData.data2}
                  </Typography>
                </Grid>
              )}
            </Grid>
          ))}
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Box
              sx={{
                textAlign: "center",
                p: "70px 50px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "PdsFont",
                  lineHeight: "30px",
                  fontSize: "20px",
                  mb: 3,
                }}
              >
                လေးစားစွာဖြင့်
              </Typography>
              <Typography
                sx={{
                  fontFamily: "PdsFont",
                  lineHeight: "30px",
                  fontSize: "20px",
                  mb: 3,
                }}
              >
                {dmData.organization.nameEng}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "PdsFont",
                  lineHeight: "30px",
                  fontSize: "20px",
                  mb: 3,
                }}
              >
                ဒါရိုက်တာကိုယ်စားလှယ် : {dmData.organization.chairmanEng}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "PdsFont",
                  lineHeight: "30px",
                  fontSize: "20px",
                  mb: 3,
                }}
              >
                (လက်မှတ်) (တံဆိပ်တုံး)
              </Typography>
            </Box>
          </Box>
          <Typography
            sx={{
              fontFamily: "PdsFont",
              fontSize: "20px",
              textAlign: "justify",
              textIndent: "50px",
              marginTop: "30px",
            }}
          >
            နည်းပညာအလုပ်သင်သည် Demand letter
            များကိုစိစစ်ပေးနိုင်ရေးအတွက်သံအရာရှိထံအကြောင်းကြားပေးနိုင်ရန်တင်ပြသည့်စာရင်း
            ဟူပြီး ဇယားအသစ်တိုးလာပါသည်။
          </Typography>
        </Box>
      )}
    </Box>
  );
};


export default DlMm;
