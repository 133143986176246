import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import Coe11_11_table from "./Coe11_11_table";
import Coe11_11_tableRow from "./Coe11_11_tableRow";

const useStyles = makeStyles({
  screen: {
    pageBreakAfter: "always",
  },
});

const Coe11_11 = () => {
  const classes = useStyles();
  const renderRows = () => {
    const numRows = 1;

    return Array.from({ length: numRows }, (_, index) => (
      <Coe11_11_tableRow index={index} key={index} />
    ));
  };

  return (
    <Box className={classes.screen} sx={{ px: 10, pt: 10 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography sx={{ fontFamily: "Mincho", fontSize: "16px" }}>
            参考様式第１-29号（規則第８条第26号関係）
          </Typography>
          <Typography sx={{ fontFamily: "Mincho", fontSize: "16px" }}>
            Ａ・Ｄ（規則第10条第２項第７号ハに適合することを証する書面）
          </Typography>
        </Box>
        <Typography sx={{ fontFamily: "Mincho", fontSize: "16px" }}>
          （日本産業規格Ａ列４）
        </Typography>
      </Box>
      <Typography
        sx={{
          textAlign: "center",
          py: 3,
          fontFamily: "Mincho",
          fontSize: "22px",
        }}
      >
        入国前 講習実施(予定) 表
      </Typography>

      <Typography
        sx={{
          fontFamily: "Mincho",
          fontSize: "20px",
          letterSpacing: 3,
        }}
      >
        １　監理団体が講習を実施した場合(外部委託を含む。)
      </Typography>
      <Coe11_11_table></Coe11_11_table>
      <Typography sx={{ fontFamily: "Mincho", fontSize: "20px", pt: "5px" }}>
        （注意）
      </Typography>
      <Typography sx={{ fontFamily: "Mincho", fontSize: "20px" }}>
        1&nbsp;&nbsp;&nbsp;
        外部委託の有無については、該当するものに丸印を付すこと。
      </Typography>
      <Typography sx={{ fontFamily: "Mincho", fontSize: "20px" }}>
        2&nbsp;&nbsp;&nbsp;
        対象とした技能実習生は別紙「技能実習生一覧表」に記載すること。{" "}
      </Typography>
      <Typography sx={{ fontFamily: "Mincho", fontSize: "20px" }}>
        3&nbsp;&nbsp;&nbsp;
        外部委託によって実施した場合は当該外部機関との契約書の写しを添付すること。{" "}
      </Typography>
      <Typography sx={{ fontFamily: "Mincho", fontSize: "20px", mt: 2 }}>
        ２　外国の公的機関又は教育機関が講習を実施した場合
      </Typography>
      <table style={{ borderCollapse: "collapse", width: "100%" }}>
        <tbody>
          <tr>
            <td style={{ width: "40px", border: "2px solid #000" }}></td>
            <td
              style={{
                width: "150px",
                padding: "5px",
                fontFamily: "Mincho",
                fontSize: "18px",
                whiteSpace: "nowrap",
                textAlign: "center",
                border: "2px solid #000",
              }}
            >
              科目（内容）
            </td>
            <td
              colSpan={2}
              style={{
                maxWidth: "200px",
                padding: "5px",
                fontFamily: "Mincho",
                fontSize: "16px",
                whiteSpace: "nowrap",
                textAlign: "center",
                border: "2px solid #000",
              }}
            >
              実施機関の氏名又は名称、所在地及び種別
            </td>
            <td
              style={{
                maxWidth: "160px",
                padding: "5px",
                fontFamily: "Mincho",
                fontSize: "18px",
                whiteSpace: "nowrap",
                textAlign: "center",
                border: "2px solid #000",
              }}
            >
              実施場所 <br />
              (施設名・所在地等)
            </td>
            <td
              style={{
                minWidth: "100px",
                padding: "5px",
                fontFamily: "Mincho",
                fontSize: "18px",
                whiteSpace: "nowrap",
                textAlign: "center",
                border: "2px solid #000",
              }}
            >
              実施期間
            </td>
            <td
              style={{
                padding: "5px",
                fontFamily: "Mincho",
                fontSize: "18px",
                whiteSpace: "nowrap",
                textAlign: "center",
                border: "2px solid #000",
              }}
            >
              実施 <br />
              時間数
            </td>
          </tr>
          {renderRows()}
        </tbody>
      </table>
    </Box>
  );
};

export default Coe11_11;
