import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import CreateDemand from "../../../pages/back_office/demand/CreateDemand";
import CreateCoe from "../../../pages/back_office/coe/CreateCoe";
import CreatePredep from "../../../pages/back_office/predeparture/CreatePredep";
import CreateOwic from "../../../pages/back_office/smartCard/CreateOwic";
import Departure from "../../../pages/back_office/departure/Departure";
import CreateDemandSw from "../../../pages/back_office/demand/CreateDeamandSw";
import PrintDialog from "../../printViews/PrintDialog";

const DEMAND_LETTER_STEP = 0;

const getSteps = (type) => {
  if (type === 1) {
    return ["Demand Letter", "COE", "Pre-departure", "Departure Date", "Smart Card"];
  } else {
    return ["Demand Letter", "Pre-departure", "Departure Date", "Smart Card"];
  }
};

const DocSteps = () => {
  const { id } = useParams()
  // const dispatch = useDispatch()
  const path = useLocation().pathname;
  const [activeStep, setActiveStep] = useState(DEMAND_LETTER_STEP);
  const [passedMembers, setPassedMembers] = useState([]);
  // const [ isDisable, setIsDisable ] = useState(false)
  const singleGroup = useSelector((state) => state.IntGroup.group);
  const type = path.includes("titssw") ? "titssw" : "sw";
  const [steps, setSteps] = useState(getSteps(type));
  const [ printView,setPrintView ] = useState(false)
  const [ gpOrMem,setGpOrMem ] = useState(null)
  const [ dataType,setDataType ] = useState(null)

  const [modals, setModals] = useState({
    demandOpen: false,
    coeOpen: false,
    predepOpen: false,
    smartCardOpen: false,
    depOpen: false,
  });

  const downloadByGroup = async (gpId, gpName, type) => {
    setPrintView(true)
    setDataType(type)
    setGpOrMem('group')
  };

  useEffect(() => {
    if (singleGroup) {
      const { data } = singleGroup;
      // Number(data.type) === 1 ? setIsDisable(Boolean(data.demand_letter_date && data.coe_datas && data.predeparture && data.final_departure_date && data.smart_card_date_nay_pyi_taw)) :
      // setIsDisable(Boolean(data.demand_letter_date && data.predeparture && data.final_departure_date && data.smart_card_date_nay_pyi_taw));
      const filteredMembers = data.member_data.filter(
        (member) => member.interview_status === "pass"
      );
      setPassedMembers(filteredMembers);
      if (Number(data.type)=== 1) {
        if(data.demand_letter_date) setActiveStep(1)
        if(data.demand_letter_date && data.coe_datas) setActiveStep(2)
        if(data.demand_letter_date && data.coe_datas && data.predeparture) setActiveStep(3)
        if(data.demand_letter_date && data.coe_datas && data.predeparture && data.final_departure_date) setActiveStep(4)
        if(data.demand_letter_date && data.coe_datas && data.predeparture && data.final_departure_date && !data.smart_card_date_nay_pyi_taw) setActiveStep(4)
        if(data.demand_letter_date && data.coe_datas && data.predeparture && data.final_departure_date && data.smart_card_date_nay_pyi_taw) setActiveStep(5)
        setSteps(getSteps(Number(data.type)));
      } else if (Number(data.type) === 2 || Number(data.type) === 3) {
        if (data.demand_letter_date) setActiveStep(1);
        if (data.demand_letter_date && data.predeparture && !data.final_departure_date) setActiveStep(2);
        if (data.demand_letter_date && data.predeparture && data.final_departure_date && !data.smart_card_date_nay_pyi_taw) setActiveStep(3);
        if (data.demand_letter_date && data.predeparture && data.final_departure_date && data.smart_card_date_nay_pyi_taw) setActiveStep(4);
        setSteps(getSteps(Number(data.type)));
      }
      
    }
  }, [singleGroup, steps.length]);

  const renderOptionalText = (index) => {
    const demandDate = singleGroup?.data?.demand_letter_date;
    const coeCreatedDate = singleGroup?.data?.coe_datas?.coe_dc_date;
    const predepDate = singleGroup?.data?.predeparture?.date;
    const departureDate = singleGroup?.data?.final_departure_date;
    const smartCardDate = singleGroup?.data?.smart_card_date_nay_pyi_taw;
    return (
      index < activeStep && (
        <Typography variant="body2">
          {index === 0 && demandDate && `Demand Date: ${demandDate}`}
          {index === 1 && Number(singleGroup?.data.type) === 1 && coeCreatedDate && `COE document created at: ${coeCreatedDate}`}
          {(index === 2 && Number(singleGroup?.data.type) === 1 || (index === 1 && Number(singleGroup?.data.type) !== 1)) && predepDate && `Predeparture date: ${predepDate}`}
          {(index === 3 && Number(singleGroup?.data.type) === 1 || (index === 2 && Number(singleGroup?.data.type) !== 1)) && departureDate && `Departure Date: ${departureDate}`}
          {(index === 4 && Number(singleGroup?.data.type) === 1 || (index === 3 && Number(singleGroup?.data.type) !== 1)) && smartCardDate && `Smartcard date: ${smartCardDate}`}
        </Typography>
      )
    );
  };

  useEffect(() => {
    setActiveStep(DEMAND_LETTER_STEP);
  }, []);

  const handleModalOpen = (modal) => {
    setModals((prevModals) => ({ ...prevModals, [modal]: true }));
  };

  const handleEditClick = (index) => {
    switch (index) {
      case 0:
        handleModalOpen("demandOpen");
        break;
      case 1:
        if (Number(singleGroup.data.type) === 1) handleModalOpen("coeOpen");
        else handleModalOpen("predepOpen");
        break;
      case 2:
        if (Number(singleGroup.data.type) === 1) handleModalOpen("predepOpen");
        else handleModalOpen("depOpen");
        break;
      case 3:
        if (Number(singleGroup.data.type) === 1) handleModalOpen("depOpen");
        else handleModalOpen("smartCardOpen");
        break;
      case 4:
        handleModalOpen("smartCardOpen");
        break;
      default:
        break;
    }
  };

  const handleDownloadClick = async (index) => {
    const { id, name } = singleGroup?.data;
    const { data } = singleGroup;
    switch (index) {
      case 0:
        downloadByGroup(id, name, "demand_letter");
        break;
      case 1:
        downloadByGroup(id, name, Number(data.type) === 1 ? "coe" : "pre_dep");
        break;
      case 2:
        downloadByGroup(id, name, Number(data.type) === 1 ? "pre_dep" : "dep");
        break;
      case 3:
        downloadByGroup(id, name, Number(data.type) === 1 ? "dep" : "smartcard");
        break;
      case 4:
        downloadByGroup(id, name, "smartcard");
        break;
      default:
        break;
    }
  };

  const renderStepActions = (index) => {
    const isCurrentStep = index === activeStep;
    const canDownload =
      isCurrentStep ||
      (index < activeStep &&
        !(Number(singleGroup?.data.type) === 1 && index === 3) &&
        !(Number(singleGroup?.data.type) !== 1 && index === 2));

      return (
        <Box>
          {index < activeStep && (
            <>
              <Button
                onClick={() => handleEditClick(index)}
                variant="text"
                color="dark"
              >
                Edit
              </Button>
              {
                (Number(singleGroup.data.type) === 1 && index === 3) || !(Number(singleGroup.data.type) !== 1 && index === 2 ) &&
                <Button
                    variant="text"
                    color="success"
                    onClick={() => handleDownloadClick(index)}
                  >
                    Print
                </Button>
              }
            </>
          )}
          {isCurrentStep && (
            <Button
              variant="text"
              color="warning"
              disabled={!(passedMembers.length > 0)}
              onClick={() => handleEditClick(index)}
            >
              {canDownload ? "create" : "print"}
            </Button>
          )}
        </Box>
      );
    };

    return (
      <Box sx={{ mt: 3 }}>
        {singleGroup?.data?.type === 3 ? (
          <CreateDemandSw
            isOpen={modals.demandOpen}
            setIsOpen={(value) => setModals({ ...modals, demandOpen: value })}
          />
        ) : (
          <CreateDemand
            isOpen={modals.demandOpen}
            setIsOpen={(value) => setModals({ ...modals, demandOpen: value })}
          />
        )}
        {/* print section */}
        {
          gpOrMem && dataType &&
          <PrintDialog isOpen={printView} setIsOpen={setPrintView} type={gpOrMem} dataType={dataType} visaType={singleGroup?.data?.type} typeId={id}></PrintDialog>
        }
        {Number(singleGroup?.data?.type) === 1 && (
          <CreateCoe
            isOpen={modals.coeOpen}
            setIsOpen={(value) => setModals({ ...modals, coeOpen: value })}
          />
        )}
        <Departure
          isOpen={modals.depOpen}
          setIsOpen={(value) => setModals({ ...modals, depOpen: value })}
        />
        <CreatePredep
          isOpen={modals.predepOpen}
          setIsOpen={(value) => setModals({ ...modals, predepOpen: value })}
        />
        <CreateOwic
          isOpen={modals.smartCardOpen}
          setIsOpen={(value) => setModals({ ...modals, smartCardOpen: value })}
        />
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step}>
              <StepLabel optional={renderOptionalText(index)}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Typography>{step}</Typography>
                  {renderStepActions(index)}
                </Box>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    );
};

export default DocSteps;
