import dayjs from "dayjs";

export const splitDate = (date) => {
    const dateObject = dayjs(date);
  
    return {
      year: dateObject.year(),
      month: dateObject.format("MM"),
      day: dateObject.format("DD"),
    };
};