import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";

const useStyles = makeStyles({
  screen: {
    pageBreakAfter: "always",
  },

  bodyMm: {
    fontFamily: "PdsFont",
    fontSize: "22px",
  },
  bodyJp: {
    fontFamily: "Mincho",
    fontSize: "22px",
  },
  centeredText: {
    textAlign: "center",
    lineHeight: "55px",
    fontFamily: "Mincho",
    fontSize: "18px",
  },
  centeredSubtitle: {
    textAlign: "justify",
    fontSize: "20px",
    fontFamily: "PdsFont",
    lineHeight: "50px",
  },
  title: {
    textAlign: "center",
    fontSize: "24px",
    fontFamily: "Mincho",
    lineHeight: "50px",
  },
  subtitle: {
    textAlign: "center",
    fontSize: "22px",
    fontFamily: "PdsFont",
    lineHeight: "55px",
    letterSpacing: 2,
  },
  secondaryTitle: {
    fontSize: "24px",
    fontFamily: "PdsFont",
  },
  text: {
    fontSize: "16px",
    fontFamily: "Mincho",
  },
});

const Kaigo_2 = () => {
  const classes = useStyles();

  return (
    <Box className={classes.screen} sx={{ px: 15, pt: 10 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography className={classes.text}>
            介護参考様式第４-2号（告示第１条第２号ハ関係）
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Typography className={classes.text}>
            （日本産業規格Ａ列４）
          </Typography>
        </Box>
      </Box>

      <Box sx={{ py: 5, textAlign: "center" }}>
        <Typography className={classes.text}>
          日本語科目の講師の誓約書
        </Typography>
        <Typography className={classes.text}>（入国前講習）</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          flexDirection: "column",
        }}
      >
        <Typography className={classes.text}>
          作成日：2024年　　　10月　　　7日
        </Typography>
        <Typography
          className={classes.text}
          sx={{
            marginRight: "200px",
          }}
        >
          {" "}
          講師の氏名：HTOO HTOO{" "}
        </Typography>
      </Box>
      <Box>
        <Typography className={classes.text}>
          下記の事項を誓約します。
        </Typography>
      </Box>
      <Typography sx={{ textAlign: "center", mb: 1 }}>記</Typography>
      <Box>
        <Typography className={classes.text}>【誓約事項】　</Typography>
        <Typography
          className={classes.text}
          sx={{
            marginLeft: "50px",
          }}
        >
          下記のいずれかの要件に該当しています。
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Typography className={`${classes.text}`}>□　</Typography>
          <Typography className={`${classes.text}`}>
            学校教育法（昭和22年法律第26号）に基づく大学（短期大学を除く。）又は大学院において日本語教育に関する課程を修めて当該大学を卒業し又は当該大学院の課程を修了した者
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Typography className={`${classes.text}`}>□　</Typography>
          <Typography className={`${classes.text}`}>
            校教育法に基づく大学（短期大学を除く。）又は大学院において日本語教育に関する科目の単位を26単位以上修得して当該大学を卒業し又は当該大学院の課程を修了した者
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Typography className={`${classes.text}`}>□　</Typography>
          <Typography className={`${classes.text}`}>
            公益財団法人日本国際教育支援協会（昭和32年３月１日に財団法人日本国際教育協会として設立された法人をいう。）が実施する日本語教育能力検定試験に合格した者
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Typography className={`${classes.text}`}>□　</Typography>
          <Typography className={`${classes.text}`}>
            学士の学位を有する者であって、日本語教育に関する研修で適当と認められるもの（420単位時間（１単位時間は45分以上とする。）以上の課程を有するものに限る。）を修了したもの{" "}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Typography className={`${classes.text}`}>□　</Typography>
          <Typography className={`${classes.text}`}>
            学校教育法に基づく大学（短期大学を除く。）又は大学院に相当する海外の大学又は大学院において日本語教育に関する課程を修めて当該大学を卒業し又は当該大学院の課程を修了した者{" "}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Typography className={`${classes.text}`}>□　</Typography>
          <Typography className={`${classes.text}`}>
            学士の学位を有する者であって、技能実習計画の認定の申請の日から遡り３年以内の日において出入国管理及び難民認定法第七条第一項第二号の基準を定める省令の留学の在留資格に係る基準の規定に基づき日本語教育機関等を定める件（平成２年法務省告示第145号）別表第１、別表第２及び別表第３に掲げる日本語教育機関で日本語教員として１年以上従事した経験を有し、かつ、現に当該日本語教育機関の日本語教員の職を離れていないもの
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Typography className={`${classes.text}`}>□　</Typography>
          <Typography className={`${classes.text}`}>
            学士、修士又は博士の学位を有する者であって、大学（短期大学を含む。）又は大学院において、26単位以上の授業科目による日本語教員養成課程等を履修し、当該課程等の単位を教育実習１単位以上含む26単位以上修得（通信による教育の場合には、26単位以上の授業科目のうち、６単位以上は面接授業等により修得）しているもの
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Typography className={`${classes.text}`}>☑　</Typography>
          <Typography className={`${classes.text}`}>
            海外の大学を卒業又は海外の大学院の課程を修了した者であって、技能実習計画の認定の申請の日から遡り３年以内の日において外国における日本語教育機関で日本語教員として１年以上従事した経験を有し、かつ、現に日本語教員の職を離れていないもの{" "}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Kaigo_2;
