import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { splitDate } from "../../../utils/splitDate";

const useStyles = makeStyles({
  screen: {
    pageBreakAfter: "always",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  table: {
    borderCollapse: "collapse",
    width: "100%",
    marginBottom: "2rem",
  },
  tableCell: {
    padding: "5px",
    border: "2px solid #000",
    fontSize: "20px",
    fontFamily: "Mincho",
    lineHeight: "40px",
  },
  note: {
    marginTop: "2rem",
    paddingX: "3px",
    fontSize: "20px",
    fontFamily: "Mincho",
  },
  sectionTitle: {
    textAlign: "center",
    paddingY: "8px",
    fontSize: "22px",
    letterSpacing: "1px",
    fontFamily: "Mincho",
  },
  textIndent: {
    textIndent: "30px",
    paddingBottom: "4px",
    fontSize: "21px",
    fontFamily: "Mincho",
  },
  listItem: {
    fontSize: "20px",
    fontFamily: "Mincho",
    lineHeight: "35px",
  },
  subTable: {
    borderCollapse: "collapse",
    width: "80%",
    marginBottom: "2rem",
    marginLeft: "auto",
  },
  subTableCell: {
    padding: "10px",
    fontSize: "20px",
    fontFamily: "Mincho",
  },
});

const Coe4 = () => {
  const classes = useStyles();
  const { data: printData } = useSelector((state) => state.PrintData);
  const [members, setMembers] = useState([]);
  // const [ coeDate,setCoeDate ] = useState(null)

  useEffect(() => {
    if (printData && printData.data) {
      const sortedMembers = [...printData.data?.members]?.sort(
        (a, b) => a.gender - b.gender
      );
      setMembers(sortedMembers);
    }
  }, [printData]);

  return (
    <>
      {printData && (
        <Box className={classes.screen} sx={{ px: 15, pt: 10 }}>
          <Box className={classes.header}>
            <Box>
              <Typography className={classes.listItem}>
                参考様式第１-10号（規則第８条第８号関係）
              </Typography>
              <Typography className={classes.listItem}>Ｄ・Ｅ・Ｆ</Typography>
            </Box>
            <Typography className={classes.listItem}>
              （日本産業規格Ａ列４）
            </Typography>
          </Box>
          <Typography className={classes.sectionTitle} sx={{ my: 7 }}>
            技能実習計画の認定に関する取次送出機関の誓約書
          </Typography>
          <Typography className={classes.textIndent} sx={{ mb: 3 }}>
            次の申請者の次の技能実習生に係る団体監理型技能実習を取り次ぐに当たり、下記の事項を誓約します。
          </Typography>
          <Box className={classes.textIndent}>
            <table className={classes.table}>
              <tbody>
                <tr>
                  <td className={classes.tableCell} width="30%" height="50px">
                    申請者（実習実施者）の氏名又は名称
                  </td>
                  <td className={classes.tableCell} colSpan={2}>
                    {printData?.data?.company?.nameJp}
                  </td>
                </tr>
                <tr>
                  <td className={classes.tableCell} height="50px">
                    監理団体の名称
                  </td>
                  <td className={classes.tableCell} colSpan={2}>
                    {printData?.data?.organization?.nameJp}
                  </td>
                </tr>
                <tr>
                  <td
                    className={classes.tableCell}
                    rowSpan={members.length + 1}
                  >
                    技能実習生の氏名（国籍）
                  </td>
                </tr>
                {members.length > 0 &&
                  members.map((mem, index) => (
                    <tr key={index}>
                      <td className={classes.tableCell}>{mem.nameEng}</td>
                      <td className={classes.tableCell}>{"ミャンマー"}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <Typography className={classes.note}>
              ※　複数名について記載する場合には適宜欄を追加すること。記載しきれない場合には別紙に記載することも可とし、当欄には「別紙のとおり」と記載すること。
            </Typography>
          </Box>
          <Typography
            className={classes.listItem}
            sx={{ textAlign: "center", mt: 3 }}
          >
            記
          </Typography>
          <Typography className={classes.listItem} sx={{ my: 3 }}>
            【誓約事項】
          </Typography>
          <Typography className={classes.listItem}>
            １　保証金の徴収その他名目のいかんを問わず、団体監理型技能実習生又はその親族その他の関係者の財産を管理することは、決していたしません。
          </Typography>
          <Typography className={classes.listItem}>
            ２　団体監理型技能実習生が技能実習に係る契約を履行しなかった場合に備えて、団体監理型技能実習生、団体監理型実習実施者、監理団体又は外国の準備機関との間で、違約金等の制裁を定めることは、決していたしません。
          </Typography>
          <Typography className={classes.listItem}>
            ３　団体監理型技能実習生等が団体監理型技能実習の申込みの取次ぎ又は外国における団体監理型技能実習の準備に関して当機関に支払う費用について、団体監理型技能実習生等にその額及び内訳を十分に理解させた上で合意しています。
          </Typography>
          <Typography className={classes.listItem}>
            ４　上記のほか、技能実習に関する法令に違反することは、決していたしません。
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 3,
              mb: 2,
              ml: "auto",
              width: 300,
            }}
          >
            <Typography className={classes.listItem}>
              {splitDate(members[0]?.coeDcDate).year} 年
            </Typography>
            <Typography className={classes.listItem}>
              {splitDate(members[0]?.coeDcDate).month} 月
            </Typography>
            <Typography className={classes.listItem}>
              {splitDate(members[0]?.coeDcDate).day} 日
            </Typography>
            <Typography className={classes.listItem}>作成</Typography>
          </Box>
          <Box className={classes.header}>
            <table className={classes.subTable} border="1">
              <tbody>
                <tr>
                  <td colSpan={6} className={classes.subTableCell}>
                    取次送出機関の氏名又は名称
                  </td>
                  <td colSpan={9} className={classes.subTableCell}>
                    CLOVER MANDALAY CO.,LTD
                  </td>
                </tr>
                <tr>
                  <td className={classes.subTableCell}>送出機関番号</td>
                  <td
                    width="25px"
                    style={{
                      textAlign: "center",
                      borderRight: "1px dotted #808080",
                    }}
                  >
                    <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                      M
                    </Typography>
                  </td>
                  <td
                    width="25px"
                    style={{
                      textAlign: "center",
                      borderRight: "1px dotted #808080",
                    }}
                  >
                    <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                      M
                    </Typography>
                  </td>
                  <td width="25px" style={{ textAlign: "center" }}>
                    <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                      R
                    </Typography>
                  </td>
                  <td
                    width="25px"
                    style={{
                      textAlign: "center",
                      borderRight: "1px dotted #808080",
                    }}
                  >
                    <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                      0
                    </Typography>
                  </td>
                  <td
                    width="25px"
                    style={{
                      textAlign: "center",
                      borderRight: "1px dotted #808080",
                    }}
                  >
                    <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                      0
                    </Typography>
                  </td>
                  <td
                    width="25px"
                    style={{
                      textAlign: "center",
                      borderRight: "1px dotted #808080",
                    }}
                  >
                    <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                      0
                    </Typography>
                  </td>
                  <td
                    width="25px"
                    style={{
                      textAlign: "center",
                      borderRight: "1px dotted #808080",
                    }}
                  >
                    <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                      2
                    </Typography>
                  </td>
                  <td
                    width="25px"
                    style={{
                      textAlign: "center",
                      borderRight: "1px dotted #808080",
                    }}
                  >
                    <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                      3
                    </Typography>
                  </td>
                  <td width="25px" style={{ textAlign: "center" }}>
                    <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                      0
                    </Typography>
                  </td>
                  <td style={{ padding: "10px" }}>
                    <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                      整理番号
                    </Typography>
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      width: "30px",
                      borderRight: "1px dotted #808080",
                    }}
                  ></td>
                  <td
                    style={{
                      textAlign: "center",
                      width: "30px",
                      borderRight: "1px dotted #808080",
                    }}
                  ></td>
                  <td
                    style={{
                      textAlign: "center",
                      width: "30px",
                      borderRight: "1px dotted #808080",
                    }}
                  ></td>
                  <td style={{ textAlign: "center", width: "30px" }}></td>
                </tr>
                <tr>
                  <td
                    colSpan={6}
                    className={classes.subTableCell}
                    height="40px"
                  >
                    作成責任者　役職・氏名
                  </td>
                  <td colSpan={9} style={{ padding: "10px", height: "40px" }}>
                    <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                      代表者　CHIT SU WAI
                    </Typography>
                  </td>
                </tr>
              </tbody>
            </table>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Coe4;
