import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { splitDate } from "../../../utils/splitDate";
import dayjs from "dayjs";

const useStyles = makeStyles({
  screen: {
    pageBreakAfter: "always",
    paddingTop: "70px",
  },
  text: {
    fontSize: "16px",
    fontFamily: "Mincho",
  },
});


const Kaigo_3 = () => {
  const classes = useStyles();
  const { data: printData } = useSelector((state) => state.PrintData);
  const [realMember, setRealMember] = useState(null);
  const [memberData, setMemberData] = useState(null);
  const [depDate, setDepDate] = useState(null);

  useEffect(() => {
    if (printData && printData.data && printData.data.members) {
      const data = printData.data.members;
      setRealMember(data);
      const formattedDate = printData.data.initialDepartureDate
        ? dayjs(printData.data.initialDepartureDate).format("YYYY-MM-DD")
        : "";
      setDepDate(formattedDate);
      const filledMemberData = Array.from(
        { length: data?.length > 17 ? data?.length : 17 },
        (_, index) => data?.[index] || {}
      );
      setMemberData(filledMemberData);
    }
  }, [printData]);
  return (
    <Box className={classes.screen} sx={{ px: 15, pt: 25 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography className={classes.text}>
            介護参考様式第５号（告示第１条第２号ハ関係）　
          </Typography>
        </Box>
        <Box sx={{ textAlign: "end" }}>
          <Typography sx={{ fontSize: "16px", fontFamily: "Mincho", mb: 5 }}>
            （日本産業規格Ａ列４）
          </Typography>
        </Box>
      </Box>
      <Typography
        sx={{
          fontSize: "22px",
          fontFamily: "Mincho",
          textAlign: "center",
          my: 5,
        }}
      >
        日本語科目の講師の履歴書
      </Typography>
      <Box sx={{ textAlign: "end" }}>
        <Typography sx={{ fontSize: "16px", fontFamily: "Mincho" }}>
          作成日：　　　　2024年　　　10月　　　7日
        </Typography>
        <Typography sx={{ fontSize: "16px", fontFamily: "Mincho", mb: 5 }}>
          申請者（実習実施者）：
        </Typography>
      </Box>

      <table border="1" style={{ borderCollapse: "collapse", width: "100%" }}>
        <tbody>
          <tr>
            <td
              colSpan={2}
              rowSpan={2}
              style={{ padding: "5px", textAlign: "center" }}
            >
              <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                <Typography>{"(ふりがな）"}</Typography>
                <Typography>1 氏名</Typography>
              </Typography>
            </td>
            <td
              colSpan={6}
              style={{ padding: "5px", textAlign: "center"}}
            >
              <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                トゥートゥー
              </Typography>
            </td>
            <td style={{ padding: "5px", textAlign: "center" }}>
              <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                ②性別
              </Typography>
            </td>
            <td style={{ padding: "5px", textAlign: "center" }}>
              <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                男　・　㊛
              </Typography>
            </td>
          </tr>
          <tr>
            <td
              colSpan={6}
              style={{ padding: "5px", textAlign: "center"}}
            >
              <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                HTOO HTOO
              </Typography>
            </td>
            <td style={{ padding: "5px", textAlign: "center" }}>
              <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                2 生年 <br /> 月日
              </Typography>
            </td>
            <td style={{ padding: "5px", textAlign: "center" }}>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontFamily: "Mincho",
                }}
              >
                1989 年　 04月　29 日
              </Typography>
            </td>
          </tr>
          <tr>
            <td colSpan={2} style={{ textAlign: "center", padding: "5px" }}>
              <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                ④国籍（国又は地域）
              </Typography>
            </td>
            <td colSpan={8} style={{ textAlign: "center", padding: "5px" }}>
              <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                ミャンマー
              </Typography>
            </td>
          </tr>
          <tr>
            <td colSpan={2} style={{ padding: "5px" }}>
              <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                ⑤住所
              </Typography>
            </td>
            <td colSpan={8} style={{ padding: "5px" }}>
              <Box>
                <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                  〒 － 05012
                </Typography>
                <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                  82 Street, Between 34 Street & 35 Street, Chanayethazan
                  Township, Mandalay
                </Typography>
                <Typography
                  sx={{
                    fontSize: "20px",
                    textAlign: "end",
                    fontFamily: "Mincho",
                  }}
                >
                  （電話 +959 200 9021）
                </Typography>
              </Box>
            </td>
          </tr>
          <tr>
            <td colSpan={2} style={{ padding: "5px" }}>
              <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                ⑥勤務先
              </Typography>
            </td>
            <td colSpan={8} style={{ padding: "5px" }}>
              <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                KAKEHASHI Japanese Language Centre
              </Typography>
            </td>
          </tr>
          <tr>
            <td colSpan={2} style={{ padding: "5px" }}>
              <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                ⑦勤務先住所
              </Typography>
            </td>
            <td colSpan={8} style={{ padding: "5px" }}>
              <Box>
                <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                  〒 － 05012
                </Typography>
                <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                  Aungthazin No.3, Between 30x31 Streets, Between 62x63 Streets,
                  Chanayethazan Township, Mandalay, Myanmar
                </Typography>
                <Typography
                  sx={{
                    fontSize: "20px",
                    textAlign: "end",
                    fontFamily: "Mincho",
                  }}
                >
                  （電話　+959 777　510 878）
                </Typography>
              </Box>
            </td>
          </tr>
          <tr>
            <td colSpan={2} rowSpan={7} style={{ padding: "5px" }}>
              <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                ⑧学歴・職歴
              </Typography>
            </td>
            <td style={{ padding: "5px", textAlign: "center" }}>
              <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                年
              </Typography>
            </td>
            <td style={{ padding: "5px", textAlign: "center" }}>
              <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                月
              </Typography>
            </td>
            <td colSpan={6} style={{ padding: "5px", textAlign: "center" }}>
              <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                最終学歴・主たる職歴
              </Typography>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "5px", textAlign: "center" }}>
              <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                2005年
              </Typography>
            </td>
            <td style={{ padding: "5px", textAlign: "center" }}>
              <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                12月
              </Typography>
            </td>
            <td colSpan={6} style={{ padding: "5px", textAlign: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                  {"マンダレー外国語大学　（入学)"}
                </Typography>
                <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                  専門：日本語
                </Typography>
              </Box>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "5px", textAlign: "center" }}>
              <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                2008年
              </Typography>
            </td>
            <td style={{ padding: "5px", textAlign: "center" }}>
              <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                09月
              </Typography>
            </td>
            <td colSpan={6} style={{ padding: "5px", textAlign: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                  {"マンダレー外国語大学　（入学)"}
                </Typography>
                <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                  専門：日本語
                </Typography>
              </Box>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "5px", textAlign: "center" }}>
              <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                2021年
              </Typography>
            </td>
            <td style={{ padding: "5px", textAlign: "center" }}>
              <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                9月
              </Typography>
            </td>
            <td colSpan={6} style={{ padding: "5px", textAlign: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                  {"KAKEHASHI Japanese Language Centre（入社）"}
                </Typography>
                <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                  （日本語講師）
                </Typography>
              </Box>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "5px", textAlign: "center" }}>
              <Typography
                sx={{ fontSize: "20px", fontFamily: "Mincho" }}
              ></Typography>
            </td>
            <td style={{ padding: "5px", textAlign: "center" }}>
              <Typography
                sx={{ fontSize: "20px", fontFamily: "Mincho" }}
              ></Typography>
            </td>
            <td colSpan={6} style={{ padding: "5px", textAlign: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                  現在に至る
                </Typography>
              </Box>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "5px", textAlign: "center" }}>
              <Typography
                sx={{ fontSize: "20px", fontFamily: "Mincho" }}
              ></Typography>
            </td>
            <td style={{ padding: "5px", textAlign: "center" }}>
              <Typography
                sx={{ fontSize: "20px", fontFamily: "Mincho" }}
              ></Typography>
            </td>
            <td colSpan={6} style={{ padding: "5px", textAlign: "center" }}>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontFamily: "Mincho",
                  visibility: "hidden",
                }}
              >
                hidden text
              </Typography>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "5px", textAlign: "center" }}>
              <Typography
                sx={{ fontSize: "20px", fontFamily: "Mincho" }}
              ></Typography>
            </td>
            <td style={{ padding: "5px", textAlign: "center" }}>
              <Typography
                sx={{ fontSize: "20px", fontFamily: "Mincho" }}
              ></Typography>
            </td>
            <td colSpan={6} style={{ padding: "5px", textAlign: "center" }}>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontFamily: "Mincho",
                  visibility: "hidden",
                }}
              >
                hidden text
              </Typography>
            </td>
          </tr>
        </tbody>
      </table>
    </Box>
  );
};

export default Kaigo_3;
