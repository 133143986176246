import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, HEADERS } from "../../utils/config";
import { AppSnackbar } from "../../components/utils/AppSnackbar";

const initialState = {
  data: null,
  // cbmRates: null,
  loading: false,
  error: null,
};

// export const getCbmRates = createAsyncThunk(
//     "exRate/getCbmRates",
//     async ()=>{
//       try {
//           const config = {
//               method: 'get',
//               url: `https://forex.cbm.gov.mm/api/latest`,
//               Headers: {
//                 'Content-Type': 'application/json',
//               }
//           };
//           const response = await axios(config);
//           return response.data;
//       } catch (error) {
//           throw new Error(error);
//       }
//     }
//   )

  export const customExRates = createAsyncThunk(
    "exRate/customExRates",
    async ({method,data})=>{
      try {
          const config = {
              method: method,
              url: `${BASE_URL}/currency-exchange-rate`,
              data: data,
              headers: HEADERS()
          };
          const response = await axios(config);
          method === 'post' && AppSnackbar('success', 'Successful')
          return response.data;
      } catch (error) {
          AppSnackbar('error', 'Something went wrong!');
          throw new Error(error);
      }
    }
  )

const exRateSlice = createSlice({
  name: "exRate",
  initialState,
  extraReducers: (builder) => {
    builder
      // .addCase(getCbmRates.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(getCbmRates.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.cbmRates = action.payload;
      // })
      // .addCase(getCbmRates.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.error.message;
      // })
      .addCase(customExRates.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(customExRates.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(customExRates.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  },
});


export default exRateSlice.reducer;
