import { Box, Button, Paper, TextField, Typography, Card, Grid, Autocomplete, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Alert } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { customExRates } from '../../slices/backOffice/exRateSlice'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Loading from '../../components/utils/Loading'

const exchangeRateSchema = Yup.object().shape({
  key: Yup.string().required('Please select an option'),
  value: Yup.number().typeError('Invalid amount').required('MMK amount is required'),
});


const ExchangeRates = () => {
  const dispatch = useDispatch()
  const [ keyName,setKeyName ] = useState(null)
  const [ customRates,setCustomRates ] = useState([])
  const { loading } = useSelector(state=> state.ExRate)

  const exchangeRates = {
    "MMK Per Dollar": "mmk_per_dollar",
    // "MMK Per Hong Kong Dollar": "mmk_per_hkd",
    // "MMK Per Brazilian Real": "mmk_per_brl",
    // "MMK Per Serbian Dinar": "mmk_per_rsd",
    // "MMK Per Malaysian Ringgit": "mmk_per_myr",
    // "MMK Per Canadian Dollar": "mmk_per_cad",
    // "MMK Per British Pound": "mmk_per_gbp",
    // "MMK Per Swedish Krona": "mmk_per_sek",
    // "MMK Per Norwegian Krone": "mmk_per_nok",
    // "MMK Per Israeli New Shekel": "mmk_per_ils",
    // "MMK Per Danish Krone": "mmk_per_dkk",
    // "MMK Per Australian Dollar": "mmk_per_aud",
    // "MMK Per Russian Ruble": "mmk_per_rub",
    // "MMK Per Kuwaiti Dinar": "mmk_per_kwd",
    // "MMK Per Euro": "mmk_per_eur",
    // "MMK Per Indian Rupee": "mmk_per_inr",
    // "MMK Per Brunei Dollar": "mmk_per_bnd",
    // "MMK Per South African Rand": "mmk_per_zar",
    // "MMK Per Nepalese Rupee": "mmk_per_npr",
    // "MMK Per Chinese Yuan": "mmk_per_cny",
    // "MMK Per Swiss Franc": "mmk_per_chf",
    // "MMK Per Kenyan Shilling": "mmk_per_kes",
    // "MMK Per Thai Baht": "mmk_per_thb",
    // "MMK Per Pakistani Rupee": "mmk_per_pkr",
    // "MMK Per Egyptian Pound": "mmk_per_egp",
    // "MMK Per Bangladeshi Taka": "mmk_per_bdt",
    // "MMK Per Saudi Riyal": "mmk_per_sar",
    // "MMK Per Lao Kip": "mmk_per_lak",
    // "MMK Per Singapore Dollar": "mmk_per_sgd",
    // "MMK Per Indonesian Rupiah": "mmk_per_idr",
    // "MMK Per Cambodian Riel": "mmk_per_khr",
    // "MMK Per Sri Lankan Rupee": "mmk_per_lkr",
    // "MMK Per New Zealand Dollar": "mmk_per_nzd",
    // "MMK Per Czech Koruna": "mmk_per_czk",
    "MMK Per Yen": "mmk_per_yen",
    "Yen Per Dollar": "yen_per_dollar",
    // "MMK Per Philippine Peso": "mmk_per_php",
    // "MMK Per South Korean Won": "mmk_per_krw",
    // "MMK Per Vietnamese Dong": "mmk_per_vnd"
  }; 

  const {
    handleSubmit,
    register,
    setValue,
    trigger,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(exchangeRateSchema),
  });

  const convertString = (str)=>{
    let words = str.split('_');
    let capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    let result = capitalizedWords.join(' ');
    return result;
  }

  const getCustomRates = async () => {
    const dataDispatch = await dispatch(customExRates({ method: 'get', data: null }));
    try {
      setCustomRates(dataDispatch.payload.data);
    } catch (error) {
      console.log('error occurred')
    }
  }

  const onSubmit = async (data) => {
    const formattedData = {
      key: data.key,
      value: data.value.toString()
    }
    await dispatch(customExRates({method: 'post', data: formattedData}))
    reset()
    setKeyName(null)
    getCustomRates()
  }

  useEffect(() => {
    getCustomRates();
  }, [])

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', p: 3, minHeight: '100vh', borderRadius: 0 }}>
      {
        loading && <Loading></Loading>
      }
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={8}>
          <Card sx={{ p: 3 }} elevation={3}>
            <Typography variant='h5' sx={{ mb: 3, fontFamily: 'Lato' }}>Exchange Rates</Typography>
            <List>
              {
                customRates.length > 0 ?
                customRates.map((rate,index)=>
                  <ListItem disablePadding key={index}>
                    <ListItemButton>
                      <ListItemText primary={convertString(rate.key)} />
                      <ListItemText primary={`${rate.value} ${rate.key === 'yen_per_dollar' ? '-Yen' : '-MMK' }`} sx={{ textAlign: 'right'}}/>
                    </ListItemButton>
                  </ListItem>
                ):
                <ListItem disablePadding>
                  <Alert severity="warning" sx={{ width: '100%' }}>There is no rates!</Alert>
                </ListItem>
              }
            </List>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card sx={{ p: 3 }} elevation={3}>
            <Typography variant='h5' sx={{ mb: 3, fontFamily: 'Lato' }}>Update Exchange Rates</Typography>
            <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Autocomplete
                  sx={{ mb: 3 }}
                  disablePortal
                  id="combo-box-demo"
                  options={Object.keys(exchangeRates)}
                  onChange={(_, value) => {
                    setKeyName(value);
                    setValue('key', exchangeRates[value]);
                    trigger('key')
                  }}
                  value={keyName}
                  renderInput={(params) => <TextField error={ !!errors.key } helperText={errors?.key?.message} {...params} label="Rates" />}
                />
                <TextField {...register('value')} error={!!errors.value} helperText={errors?.value?.message} inputProps={{ step: "0.1" }} sx={{ mb: 3 }} label={"Amount"}></TextField>
                <Button type='submit' variant='contained' sx={{ color: "#fff" }}>Save</Button>
              </Box>
            </form>
          </Card>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ExchangeRates;