import { Alert, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PrintDialog from '../../printViews/PrintDialog'

const MembersList = () => {
    const singleGroup = useSelector(state=> state.IntGroup.group)
    const [ passsedMembers,setPassedMembers ] = useState([])
    const [ gpType, setGpType ] = useState(null)
    const [ printView,setPrintView ] = useState(false)
    const [ gpOrMem,setGpOrMem ] = useState(null)
    const [ dataType,setDataType ] = useState(null)
    const [ memId,setMemId ] = useState(null)

    const handleDownloadByMember = async (memberId,type)=>{
        setMemId(Number(memberId))
        setGpOrMem("member")
        setDataType(type)
        setPrintView(true)
    }

    useEffect(()=>{
        if(singleGroup){
            const data = singleGroup.data
            setGpType(data.type)
            const filteredMembers = data.member_data.filter(member=> member.interview_status === 'pass')
            setPassedMembers(filteredMembers);
        }
    },[singleGroup])

  return (
    <TableContainer sx={{ maxHeight: '100%' }}>
        {
            gpOrMem && dataType &&
            <PrintDialog isOpen={printView} setIsOpen={setPrintView} type={gpOrMem} dataType={dataType} visaType={gpType} typeId={memId}></PrintDialog>
        }
        <Typography variant='h6' sx={{ mt: 3 }}>Passed Members List</Typography>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>
                    <TableCell align='center'>NO</TableCell>
                    <TableCell>NAME</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Print Options</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    passsedMembers && passsedMembers.length > 0 ?
                    passsedMembers.map((member,index)=>
                        <TableRow key={index}>
                            <TableCell align='center'>{index+1}</TableCell>
                            <TableCell>{member.name}</TableCell>
                            <TableCell>{member.email}</TableCell>
                            <TableCell>{member.phone}</TableCell>
                            <TableCell>
                                <Button disabled={!Boolean(singleGroup?.data.demand_letter_date)} onClick={()=> handleDownloadByMember(member.user_id ,'demand_letter')} size='small' variant='contained' sx={{ mr: 1, color: '#fff' }}>Demand</Button>
                                {Number(singleGroup?.data.type) === 1 && <Button disabled={!Boolean(singleGroup?.data.coe_datas)} onClick={()=> handleDownloadByMember(member.user_id ,'coe')} size='small' variant='contained' sx={{ mr: 1, color: '#fff' }}>coe</Button>}
                                <Button disabled={!Boolean(singleGroup?.data.predeparture)} onClick={()=> handleDownloadByMember(member.user_id ,'pre_dep')} size='small' variant='contained' sx={{ mr: 1, color: '#fff' }}>predeparture</Button>
                                <Button disabled={!Boolean(singleGroup?.data.smart_card_date_nay_pyi_taw)} onClick={()=> handleDownloadByMember(member.user_id ,'smartcard')} size='small' variant='contained' sx={{ color: '#fff' }}>smart card</Button>
                            </TableCell>
                        </TableRow>
                    )
                    :
                    <TableRow>
                        <TableCell sx={{ py: 1, px: 0 }} colSpan={5}>
                            <Alert severity="warning">There is no interview passed members!</Alert>
                        </TableCell>
                    </TableRow>
                }                            
            </TableBody>
        </Table>
    </TableContainer>
  )
}

export default MembersList