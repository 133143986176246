import React, { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { splitDate } from "../../../utils/splitDate";

const useStyles = makeStyles({
    screen: {
        pageBreakAfter: 'always',
    },
});

const Coe11_2 = () => {
    const classes = useStyles();
    const { data: printData } = useSelector(state=> state.PrintData)
    const [ firstMember,setFirstMemberData ] = useState(null)
    const [ membersData,setMembersData ] = useState(null)
    const [ startDate,setStartDate ] = useState(null)
    const [ endDate,setEndDate ] = useState(null)
  
    useEffect(()=>{
      if(printData && printData.data && printData.data.members && printData.data.trainingDates){
        const data = printData.data.members[0]
        const trainingDates = printData.data.trainingDates
        setFirstMemberData(data)
        setMembersData(printData.data.members)
        setStartDate(trainingDates[0].date)
        setEndDate(trainingDates[trainingDates.length - 1].date)
      }
    },[printData])
    return (
        <Box className={classes.screen} sx={{ px: 15, pt: 10 }}>
            {
                membersData && firstMember &&
                <>
                    <Typography sx={{
                        fontFamily: 'Mincho',
                        fontSize: '20px',
                        textAlign: 'right'
                    }}>
                        {splitDate(printData?.data?.officeDateAfterTraining).year} 年 　{splitDate(printData?.data?.officeDateAfterTraining).month} 月 　{splitDate(printData?.data?.officeDateAfterTraining).day} 日
                    </Typography>
                    <Typography sx={{
                        py: '20px',
                        fontFamily: 'Mincho', fontSize: '20px',
                        mb: 3
                    }}>{printData?.data?.organization?.nameJp} 御中</Typography>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'end'
                    }}>
                        <Box>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}>
                                <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px', mb: 3 }}>CLOVER MANDALAY CO.,LTD</Typography>
                                <Typography sx={{ pl: 5, fontFamily: 'Mincho', fontSize: '20px' }}>㊞</Typography>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'end',
                                py: '20px',
                                mb: 3
                            }}>
                                <Box sx={{
                                    textAlign: 'center',
                                }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>KAKEHASHI Japanese Language Centre</Typography>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>校長　CHIT SU WAI</Typography>
                                </Box>
                                <Typography sx={{ pl: 5, fontFamily: 'Mincho', fontSize: '20px' }}>㊞</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Typography sx={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                        fontFamily: 'Mincho', fontSize: '24px'
                    }}>事前講習実施報告書</Typography>
                    <table style={{ paddingTop: '30px', paddingBottom: '20px' }}>
                        <tbody>
                        <tr>
                            <td>
                                <Typography sx={{ letterSpacing: '10px', fontFamily: 'Mincho', fontSize: '20px' }}>講習実施機関</Typography>
                            </td>
                            <td style={{ width: '50px', textAlign: 'center' }}>
                                <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>：</Typography>
                            </td>
                            <td>
                                <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>{printData?.data?.organization?.nameJp}</Typography>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Typography sx={{ letterSpacing: '10px', fontFamily: 'Mincho', fontSize: '20px' }}>講習実施施設</Typography>
                            </td>
                            <td style={{ width: '50px', textAlign: 'center' }}>
                                <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>：</Typography>
                            </td>
                            <td>
                                <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>KAKEHASHI Japanese Language Centre</Typography>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Typography sx={{ letterSpacing: '10px', fontFamily: 'Mincho', fontSize: '20px' }}>講習責任者</Typography>
                            </td>
                            <td style={{ width: '50px', textAlign: 'center' }}>
                                <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>：</Typography>
                            </td>
                            <td>
                                <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>校長 CHIT SU WAI</Typography>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Typography sx={{ letterSpacing: '10px', fontFamily: 'Mincho', fontSize: '20px' }}>担当講師</Typography>
                            </td>
                            <td style={{ width: '50px', textAlign: 'center' }}>
                                <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>：</Typography>
                            </td>
                            <td>
                                <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>下記のとおり</Typography>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <table border='1' style={{ borderCollapse: 'collapse', width: '100%', marginBottom: '20px' }}>
                        <tbody>
                        <tr>
                            <td style={{ textAlign: 'center', padding: '10px' }}>
                                <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>担当科目</Typography>
                            </td>
                            <td style={{ textAlign: 'center', padding: '10px' }}>
                                <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>役職</Typography>
                            </td>
                            <td style={{ textAlign: 'center', padding: '10px' }}>
                                <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>氏名</Typography>
                            </td>
                            <td style={{ textAlign: 'center', padding: '10px' }}>
                                <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>経験年数</Typography>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: 'center', padding: '10px' }}>
                                <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>日本語</Typography>
                            </td>
                            <td rowSpan={3} style={{ textAlign: 'center', padding: '10px' }}>
                                <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>講師</Typography>
                            </td>
                            <td style={{ textAlign: 'center', padding: '10px' }}>
                                <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>PHOO MYAT CHEL</Typography>
                            </td>
                            <td rowSpan={3} style={{ textAlign: 'center', padding: '10px' }}>
                                <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>2年</Typography>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: 'center', padding: '10px' }}>
                                <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>日本での生活一般に関する知識</Typography>
                            </td>
                            <td style={{ textAlign: 'center', padding: '10px' }}>
                                <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>PHOO MYAT CHEL</Typography>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: 'center', padding: '10px' }}>
                                <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>日本での円滑な技能等の修得に資する知識</Typography>
                            </td>
                            <td style={{ textAlign: 'center', padding: '10px' }}>
                                <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>PHOO MYAT CHEL</Typography>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <Box sx={{
                        display: 'flex',
                    }}>
                        <Typography sx={{
                            letterSpacing: '10px',
                            fontFamily: 'Mincho', fontSize: '20px'
                        }}>講習受講者:</Typography>
                        <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>下記 {membersData.length} 名</Typography>
                    </Box>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <table border='1' style={{ borderCollapse: 'collapse', width: '80%', marginTop: '5px', marginBottom: '5px' }}>
                            <tbody>
                            <tr>
                                <td style={{ textAlign: 'center', padding: '5px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>氏名</Typography>
                                </td>
                                <td style={{ textAlign: 'center', padding: '5px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>性別</Typography>
                                </td>
                                <td style={{ textAlign: 'center', padding: '5px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>生年月日</Typography>
                                </td>
                            </tr>
                            {
                                membersData.map((member,index)=>
                                <tr key={index}>
                                    <td style={{ textAlign: 'center', padding: '5px' }}>
                                        <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>{member.nameEng}</Typography>
                                    </td>
                                    <td style={{ textAlign: 'center', padding: '5px' }}>
                                        <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>{Number(member.gender) === 1 ? "男" : "女"}</Typography>
                                    </td>
                                    <td style={{ textAlign: 'center', padding: '5px' }}>
                                        <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>
                                            {splitDate(member.dateOfBirth).year} 年  {splitDate(member.dateOfBirth).month} 月  {splitDate(member.dateOfBirth).day} 日
                                        </Typography>
                                    </td>
                                </tr>
                                )
                            }
                            </tbody>
                        </table>
                    </div>
                    <table>
                        <tbody>
                        <tr>
                            <td style={{ width: '200px' }}>
                                <Typography sx={{ letterSpacing: '10px' }}>クラス構成</Typography>
                            </td>
                            <td style={{ width: '30px', textAlign: 'center' }}>
                                <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>:</Typography>
                            </td>
                            <td>
                                <Box sx={{
                                    display: 'flex'
                                }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>クラス構成人数</Typography>
                                    <Typography sx={{ paddingLeft: '30px', fontFamily: 'Mincho', fontSize: '20px' }}>10 名</Typography>
                                </Box>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Typography sx={{ letterSpacing: '10px' }}>講習実施期間</Typography>
                            </td>
                            <td style={{ width: '30px', textAlign: 'center' }}>
                                <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>:</Typography>
                            </td>
                            <td>
                                <Box sx={{
                                    display: 'flex'
                                }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>{splitDate(startDate).year} 年</Typography>
                                    <Typography sx={{ paddingLeft: '15px', fontFamily: 'Mincho', fontSize: '20px' }}>{splitDate(startDate).month} 月</Typography>
                                    <Typography sx={{ paddingLeft: '15px', fontFamily: 'Mincho', fontSize: '20px' }}>{splitDate(startDate).day} 日</Typography>
                                    <Typography sx={{ paddingLeft: '15px', fontFamily: 'Mincho', fontSize: '20px' }}>～</Typography>
                                    <Typography sx={{ paddingLeft: '15px', fontFamily: 'Mincho', fontSize: '20px' }}>{splitDate(endDate).year} 年</Typography>
                                    <Typography sx={{ paddingLeft: '15px', fontFamily: 'Mincho', fontSize: '20px' }}>{splitDate(endDate).month} 月</Typography>
                                    <Typography sx={{ paddingLeft: '15px', fontFamily: 'Mincho', fontSize: '20px' }}>{splitDate(endDate).day} 日</Typography>
                                </Box>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ verticalAlign: 'top' }}>
                                <Typography sx={{ letterSpacing: '10px' }}>講習科目</Typography>
                            </td>
                            <td style={{ width: '30px', textAlign: 'center', verticalAlign: 'top' }}>
                                <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>:</Typography>
                            </td>
                            <td>
                                <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>・日本語（読み書き、会話、文法）1日4時間 合計160時間</Typography>
                                <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>・日本での生活一般に関する知識（日本の歴史･文化、生活様式、職場のルール）1日5時間 合計25時間</Typography>
                                <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>・日本での円滑な技能等の修得に資する知識（修得技能の目標･内容、職場規律･心構え）1日5時間 合計25時間</Typography>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Typography sx={{ letterSpacing: '10px' }}>使用教材</Typography>
                            </td>
                            <td style={{ width: '30px', textAlign: 'center' }}>
                                <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>:</Typography>
                            </td>
                            <td>
                                <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>『まるごと　入門A1 活動、理解』等</Typography>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Typography sx={{ letterSpacing: '10px' }}>カリキュラム</Typography>
                            </td>
                            <td style={{ width: '30px', textAlign: 'center' }}>
                                <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>:</Typography>
                            </td>
                            <td>
                                <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>別紙1のとおり</Typography>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Typography sx={{ letterSpacing: '10px' }}>受講者習得成果</Typography>
                            </td>
                            <td style={{ width: '30px', textAlign: 'center' }}>
                                <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>:</Typography>
                            </td>
                            <td>
                                <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>別紙2のとおり</Typography>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </>
            }
        </Box>
    )
}

export default Coe11_2