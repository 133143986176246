import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getDataOfDoc } from "../../../../slices/backOffice/pdfDataSlice";
import { splitDate } from "../../../../utils/splitDate";
import { getShortMonthName } from "../../../../utils/getShortMonthName";
import Loading from "../../../utils/Loading";
import ErrorDialog from "../../../utils/ErrorDialog";

const useStyles = makeStyles({
  screen: {
    pageBreakAfter: "always",
  },
});

const DlSw = ({gpOrMem,id,toggleDialog}) => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const gpId = useParams().id
  const [ dmData,setDmData ] = useState(null)
  const [alertToggle, setAlertToggle] = useState(false);
  const { data: pdfData , loading} = useSelector(state=> state.PrintData)


  const handleConfirmAlert = async () => {
    setAlertToggle(false);
    toggleDialog(false)
  };
  
  const getData = async()=>{
    const dispatchData = await dispatch(getDataOfDoc({gpId: gpId, docType: 'demand-letter', userId: gpOrMem === 'member' ? id : null}))
    if(dispatchData.error){
      setAlertToggle(true)
    }
  }

  const demandList = dmData
    ? [
        {
          title: "Company Name",
          data: dmData.company.nameEng,
        },
        {
          title: "Company Address",
          data: dmData.company.addressEng,
        },
        {
          title: "Number of Employees",
          data: `${dmData.numberOfWorkers.total} persons`,
        },
        {
          title: "Gender",
          data: `${
            dmData.numberOfWorkers.male > 0 &&
            dmData.numberOfWorkers.female === 0
              ? "Male"
              : dmData.numberOfWorkers.male === 0 &&
                dmData.numberOfWorkers.female > 0
              ? "Female"
              : `Male(${dmData.numberOfWorkers.male})/ Female(${dmData.numberOfWorkers.female})`
          }`,
        },
        {
          title: "Age",
          data: dmData.ageLimitationENG,
        },
        {
          title: "Qualification",
          data: "University Degree",
          data2: dmData.qualificationENG,
        },
        {
          title: "Health & Physical Condition",
          data: "Health certificate issued by the medical authority in Myanmar",
        },
        {
          title: "Job Category",
          data: dmData.jobCategoryMM,
        },
        {
          title: "Workplace",
          data: dmData.workPlace,
        },
        {
          title: "Working Hours",
          data: dmData.workingHoursENG,
        },
        {
          title: "Basic salary",
          data: `Monthly salary:JPY ${dmData.basicSalary}`,
          data2:
            "Health Insurance, Social Insurance Premium, Employment Insurance Premium are as per corporate rules",
        },
        {
          title: "Salary Payment Method",
          data:
            Number(dmData.salaryPaymentMethod) === 1
              ? "Bank Transfer"
              : "By Cash",
        },
        {
          title: "Overtime",
          data: dmData.overtimeENG,
        },
        {
          title: "Working Days",
          data: dmData.workingDayEng,
        },
        {
          title: "Holidays",
          data: dmData.holidayENG,
        },
        {
          title: "Accommodation",
          data: "Arrange by the company",
          data2: `Monthly payment of ${Number(
            dmData.accommodation
          ).toLocaleString()}-JPY for employee`,
        },
        {
          title: "Transporation/Air Ticket",
          data: dmData.transportationENG,
        },
        {
          title: "Utility Cost",
          data: "Utility cost will be paid by the employee himself/herself",
        },
        {
          title: "Training Period",
          data: "1 month",
        },
        {
          title: "Training Period Wage Scale",
          data: "Same as above",
        },
        {
          title: "Contract Period",
          data: "Till 65 years old",
        },
        {
          title: "Allowance",
          data: "Bonus, Qualification allowance",
        },
        {
          title: "Medical Insurance",
          data: "No private insurance covered Health insurance covered by the company",
        },
      ]
    : [];
  

  useEffect(()=>{
    if(pdfData){
      const { data } = pdfData
      setDmData(data)
    }
  },[pdfData])

  useEffect(()=>{
    getData()
  },[])

  return (
    <Box className={classes.screen} sx={{ px: 15, py: 10 }}>
      {loading ? (
        <Loading></Loading>
      ) : pdfData && dmData ? (
        <>
          <Typography
            sx={{
              textAlign: "end",
              fontFamily: "PdsFont",
              fontSize: "20px",
            }}
          >
            {/* Date .28 Apr. 2023 */}
            Date. {splitDate(dmData.demandLetterApplicationDate).day}{" "}
            {getShortMonthName(
              splitDate(dmData.demandLetterApplicationDate).month
            )}{" "}
            {splitDate(dmData.demandLetterApplicationDate).year}
          </Typography>
          <Box
            sx={{
              width: "70%",
              my: 2,
            }}
          >
            <Typography
              sx={{
                fontFamily: "PdsFont",
                fontSize: "20px",
                lineHeight: "30px",
              }}
            >
              {dmData.organization.nameEng}
            </Typography>
            <Typography
              sx={{
                fontFamily: "PdsFont",
                fontSize: "20px",
                lineHeight: "30px",
              }}
            >
              {dmData.organization.addressEng}
            </Typography>
            <Typography
              sx={{
                fontFamily: "PdsFont",
                fontSize: "20px",
                lineHeight: "30px",
              }}
            >
              PHONE: {dmData.organization.phone}
            </Typography>
            <Typography
              sx={{
                fontFamily: "PdsFont",
                fontSize: "20px",
                lineHeight: "30px",
              }}
            >
              FAX: {dmData.organization.fax}
            </Typography>
          </Box>
          <Typography
            sx={{
              fontSize: "30px",
              textAlign: "center",
              fontFamily: "PdsFont",
              lineHeight: "30px",
              my: 5,
            }}
          >
            LETTER OF DEMAND FOR WORKER RECRUITMENT
          </Typography>
          <Typography
            sx={{
              fontFamily: "PdsFont",
              fontSize: "20px",
              mb: 3,
              textAlign: "justify",
            }}
          >
            We would like to recruit Myanmar Staff for employment in our Company
            under the following conditions:
          </Typography>
          {demandList.map((demandData, index) => (
            <Grid
              key={index}
              container
              spacing={2}
              sx={{
                pb: 3,
                pageBreakAfter: index === 16 ? "always" : "",
                pt: index === 17 ? 14 : 0,
              }}
            >
              <Grid item xs={4}>
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Typography
                    sx={{
                      mr: "20px",
                      fontFamily: "PdsFont",
                      fontSize: "20px",
                    }}
                  >
                    {index + 1}.
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "PdsFont",
                      fontSize: "20px",
                      lineHeight: "30px",
                    }}
                  >
                    {demandData.title}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  sx={{
                    fontFamily: "PdsFont",
                    fontSize: "20px",
                    lineHeight: "30px",
                  }}
                >
                  : {demandData.data}
                </Typography>
                <Typography
                  sx={{ pl: 1, fontFamily: "PdsFont", fontSize: "20px" }}
                >
                  {demandData.data2}
                </Typography>
              </Grid>
            </Grid>
          ))}
          <Box
            sx={{
              pt: 10,
            }}
          >
            <Typography
              sx={{
                fontFamily: "PdsFont",
                fontSize: "20px",
                lineHeight: "30px",
                my: 10,
              }}
            >
              Sincerely,
            </Typography>
            <Typography
              sx={{
                fontFamily: "PdsFont",
                fontSize: "20px",
                lineHeight: "30px",
              }}
            >
              {dmData.company.nameEng}
            </Typography>
            <Typography
              sx={{
                fontFamily: "PdsFont",
                fontSize: "20px",
                lineHeight: "30px",
              }}
            >
              Representative Director
            </Typography>
            <Typography
              sx={{
                fontFamily: "PdsFont",
                fontSize: "20px",
                lineHeight: "30px",
              }}
            >
              {dmData.company.ceoEng}
            </Typography>
            <Typography
              sx={{
                fontFamily: "PdsFont",
                fontSize: "20px",
                lineHeight: "30px",
              }}
            >
              {dmData.company.addressEng}
            </Typography>
            <Typography
              sx={{
                fontFamily: "PdsFont",
                fontSize: "20px",
                lineHeight: "30px",
              }}
            >
              PHONE: {dmData.company.phone}
            </Typography>
            <Typography
              sx={{
                fontFamily: "PdsFont",
                fontSize: "20px",
                lineHeight: "30px",
              }}
            >
              FAX: {dmData.company.fax}
            </Typography>
          </Box>
          <Typography
            sx={{
              fontFamily: "PdsFont",
              fontSize: "20px",
              textAlign: "justify",
              textIndent: "50px",
              marginTop: "30px",
            }}
          >
            A new table has been added, which lists the list of demand letters
            that technical interns must submit to the embassy for review.
          </Typography>
        </>
      ) : (
        <ErrorDialog
          type={"danger"}
          confrim={handleConfirmAlert}
          toggle={alertToggle}
          setToggle={setAlertToggle}
          title={"Oops - something went wrong!"}
          content={
            "This page isn't available at that moment, please try again later."
          }
        ></ErrorDialog>
      )}
    </Box>
  );
};


export default DlSw;
