import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { convertToMyanmarNumbers } from "../../../../utils/enTomm";
import { splitDate } from "../../../../utils/splitDate";
import { getFullMonthName } from "../../../../utils/getFullMonthName";
import { getBurmeseMonthName } from "../../../../utils/getBurmeseMonthName";
import letterHead from "../../../../assets/images/letter_head.png";
import waterMark from "../../../../assets/images/clover-rm-bg.png";


const useStyles = makeStyles({
  screen: {
    pageBreakAfter: "always",
    minHeight: "100vh",
  },
});

const AgencyContract = ({ type }) => {
  const [ dmData,setDmData ] = useState(null)
  const pdfData = useSelector(state=> state.PrintData.data)
  const classes = useStyles();
  
  useEffect(()=>{
    if(pdfData){
      const { data } = pdfData
      setDmData(data)
    }
  },[pdfData])

  return (
    <div className={classes.screen}>
      <img src={letterHead} className="letterHead"></img>
      {
        dmData &&
          <Box sx={{
            backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.78), rgba(255, 255, 255, 0.78)), url(${waterMark})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top",
            backgroundSize: "60%",
            mx: "auto",
            pt: 10, px: 15 }}>
            <Typography
              sx={{
                mb: 2,
                fontFamily: "PdsFontNumber",
                fontWeight: "bold",
                fontSize: "24px",
                textAlign: "center",
                wordSpacing: "5px",
                lineHeight: "40px",
              }}
            >
              အေဂျင်စီ ဝန်ခံကတိပြုချက်
            </Typography>
          <Typography
            sx={{
              mb: 5,
              fontFamily: "PdsFont",
              fontSize: "20px",
              textAlign: "right",
              wordSpacing: "5px",
              lineHeight: "40px",
            }}
          >
              ရက်စွဲ၊ {convertToMyanmarNumbers(splitDate(dmData.demandLetterDate).year)}ခုနှစ်
              {/* changing month into burmese name */}
              &nbsp;{getBurmeseMonthName(getFullMonthName(splitDate(dmData.demandLetterDate).month))}လ
              &nbsp;{convertToMyanmarNumbers(splitDate(dmData.demandLetterDate).day)}ရက်
          </Typography>
          <Typography
            sx={{
              mb: 5,
              fontFamily: "PdsFont",
              fontSize: "20px",
              textAlign: "justify",
              wordSpacing: "5px",
              lineHeight: "40px",
            }}
          >
            &nbsp;&nbsp;&nbsp;ကျွန်တော်များ Clover Mandalay Co.,Ltd မှ ဂျပန်နိုင်ငံသို့စေလွှတ်မည့်
            { type === 'tit' ?" အလုပ်သင်သင်တန်းသား" : " အထူးကျွမ်းကျင်လုပ်သား"} နှင့်ပတ်သက်၍ ဂျပန်နိုင်ငံဥပဒေအရ
            လစာတိုးမြှင့်ခံစားခွင့် ရှိပါက တိုးမြှင့်ခံစားခွင့်ရရှိရေးအတွက်
            တာဝန်ယူကြောင်း၊ စေလွှတ်လိုက်သည့် { type === 'tit' ?"အလုပ်သင်သင်တန်းသား" : "အထူးကျွမ်းကျင်လုပ်သား"} များ
            မြန်မာနိုင်ငံမှ ထွက်ခွာချိန်မှ ပြန်လည် ရောက်ရှိချိန်အထိ
            ဖြစ်သမျှကိစ္စအဝဝကို တာဝန်ယူကြောင်းနှင့် ပျက်ကွက်ပါက
            လိုင်စင်ပိတ်သိမ်းသည်အထိ အရေးယူခြင်းခံရမည်ကို သိရှိပါကြောင်း
            ဝန်ခံကတိပြုပါသည်။
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <Box
              sx={{
                textAlign: "center",
                mr: 10,
              }}
            >
              <Typography
                sx={{
                  mt: 10,
                  mb: 15,
                  fontFamily: "PdsFont", fontSize: '22px', wordSpacing: '5px' , lineHeight: '40px'
                }}
              >
                လေးစားစွာဖြင့်
              </Typography>
              <Typography sx={{ fontFamily: "PdsFont", fontSize: '22px', wordSpacing: '5px' , lineHeight: '40px' }}>ချစ်စုဝေ</Typography>
              <Typography sx={{ fontFamily: "PdsFont", fontSize: '22px', wordSpacing: '5px' , lineHeight: '40px' }}>မန်နေဂျင်းဒါရိုက်တာ</Typography>
              <Typography sx={{ fontFamily: "PdsFont", fontSize: '22px', wordSpacing: '5px' , lineHeight: '40px' }}>Clover Mandalay Co., Ltd</Typography>
            </Box>
          </Box>
          </Box>
      }
    </div>
  );
};

export default AgencyContract;
