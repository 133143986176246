import React from "react";
import { makeStyles } from '@mui/styles';
import { Box, Typography } from "@mui/material";

const useStyles = makeStyles({
    screen: {
        pageBreakAfter: 'always',
    },
});

const Coe9_7 = () => {
    const classes = useStyles();
    return (
        <Box className={classes.screen} sx={{ px: 15, pt: 10 }}>
            <Typography sx={{ lineHeight: '35px', fontFamily: 'PdsFont', fontSize: '20px' }}>
                ၄။ စာချုပ်တွင်ထည့်သွင်းမထားသောအကြောင်းအရာများပေါ်ပေါက်လာသည့်အခါ သင်နှင့်ကျွန်ုပ်မှ မြန်မာနိုင်ငံနှင့် ဂျပန်နိုင်ငံ၏ဥပဒေနှင့်အညီ ညှိနှိုင်းဆောင်ရွက်ရမည်။
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontFamily: 'Mincho', fontSize: '20px', mb: 3 }}>
                4.　本契約書に記載されてない出来事が発生した場合あなたと私はミャンマー連邦共和国と日本国の現行諸法令にしたがって協議する。
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontFamily: 'PdsFont', fontSize: '20px' }}>
                ၅။ ဤစာချုပ်ကို နှစ်စောင်ပြုလုပ်မည်ဖြစ်ပြီး သင်နှင့်ကျွန်ုပ်လက်မှတ်ရေးထိုးပြီးနောက် နှစ်ဦးနှစ်ဖက်မှ တစ်စောင်စီ ထိန်းသိမ်းထားရပါမည်။
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontFamily: 'Mincho', fontSize: '20px' }}>
                5.　 本契約の成立を証するため、本書 2 通を作成し、あなたとわたしがそれぞれ記名押印のうえ、各 1 通を保有する。
            </Typography>
        </Box>
    )
}

export default Coe9_7