import React, { useEffect } from 'react';
import { Grid, TextField, Box, Typography, Button, CircularProgress } from '@mui/material';
import cloverLogo from '../../assets/images/clover.png';
import theme from '../../utils/theme';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { resetPassword, verifyToken } from '../../slices/auth/authSlice';

const ResetPassword = () => {
  const { oneTimeToken } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { loading } = useSelector(state=> state.User)

  const passwordsSchema = Yup.object().shape({
      password:Yup.string().required("Password is required!").min(6,"Password must have at least 6 characters!"),
      confirm_password: Yup.string().required("Password is required!").min(6,"Password must have at least 6 characters!").oneOf([Yup.ref('password'), null], 'Confirm password must match with password!'),
  })

  const { register,handleSubmit,formState: {errors}} = useForm({
      resolver : yupResolver(passwordsSchema),
  })
  
  const checkValidToken = async()=>{
    const checkDispatch = await dispatch(verifyToken({ data: null, verifyToken: oneTimeToken }))
    if(checkDispatch.meta.requestStatus === 'rejected'){
        navigate('/forgot-password')
    }
  }

  const onSubmit = async (data)=>{
    const newData = {
        email: localStorage.getItem('cm-forgot-email'),
        password: data.password,
        confirm_password: data.confirm_password,
        token: oneTimeToken,
    }
    const dispatchData = await dispatch(resetPassword({ data: newData }))
    if(dispatchData.meta.requestStatus === 'fulfilled'){
      localStorage.removeItem('cm-forgot-email')
    }
    navigate('/login')
  }


  useEffect(()=>{
    checkValidToken()
  },[])

  return (
    <Grid container sx={{ display: 'flex', justifyContent: 'center', mt: 15 }}>
      <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img width={200} src={cloverLogo} loading="lazy" alt="Clover Logo" />
          <Typography variant="h5" sx={{ fontWeight: 'bold', textTransform: 'capitalize' }} marginTop={2} marginBottom={2}>
            Reset Your Password
          </Typography>
          <Box sx={{ width: '350px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <TextField {...register('password')} error={!!errors?.password} helperText={errors?.password?.message} sx={{ my: 3 }} id="password" label="Password" variant="outlined" />
            <TextField {...register('confirm_password')} error={!!errors?.confirm_password} helperText={errors?.confirm_password?.message} sx={{ mb: 3 }} id="confirm_password" label="Confrim Password" variant="outlined" />
          </Box>
          <Button disabled={loading} variant="contained" fullWidth sx={{ color: theme.palette.common.white, mb: 3 }} onClick={handleSubmit(onSubmit)}>
            {loading ? <CircularProgress sx={{ color: theme.palette.dark.main, ml: 2 }} size={20} /> : "Submit"}
          </Button>
          <RouterLink to={"/login"}>
            <Typography sx={{ color: theme.palette.secondary.dark}} variant='body2'>Back to Login</Typography>
          </RouterLink>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ResetPassword;
