import React, { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { splitDate } from "../../../utils/splitDate";

const useStyles = makeStyles({
    screen: {
        pageBreakAfter: 'always',
    },
});

const Coe13 = () => {
    const classes = useStyles();
    const { data: printData } = useSelector(state=> state.PrintData)
    const [ memberData,setMemberData ] = useState(null)
  console.log(memberData);
    useEffect(()=>{
      if(printData && printData.data && printData.data.members){
        const data = printData.data.members[0]
        setMemberData(data)
      }
    },[printData])
    return (
        <Box className={classes.screen} sx={{ px: 15, pt: 10 }}>
            {
                memberData &&
                <>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                        <Typography sx={{ fontSize: '16px', fontFamily: 'Mincho' }}>参考様式第２-９号（規則第27条第１項第11号イ関係）</Typography>
                        <Typography sx={{ fontSize: '16px', fontFamily: 'Mincho' }}>（日本工業規格Ａ列４）</Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'end',
                        pt: '30px',
                    }}>
                        <Box sx={{
                            border: '1px solid black',
                            pr: 7,
                            pl: 2,
                        }}>
                            <Typography sx={{ fontSize: '16px', fontFamily: 'Mincho' }}>※　整理番号</Typography>
                        </Box>
                        <Box sx={{
                            border: '1px solid black',
                            borderLeft: '0px',
                            width: '200px'
                        }} />
                    </Box>
                    <Typography sx={{
                        py: 5,
                        textAlign: 'center',
                        letterSpacing: '20px',
                        fontSize: '22px',
                        fontFamily: 'Mincho'
                    }}>
                        外国の送出機関の概要書
                    </Typography>
                    <table border='1' style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <tbody>
                            <tr>
                                <td style={{ padding: '5px', border: '2px solid #000', height: '40px', width: '15%' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>① 関名</Typography>
                                </td>
                                <td colSpan={2} style={{ padding: '5px', border: '2px solid #000' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>CLOVER MANDALAY CO., LTD</Typography>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ padding: '5px', border: '2px solid #000', height: '40px', width: '15%' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>② 表者の氏名</Typography>
                                </td>
                                <td colSpan={2} style={{ padding: '5px', border: '2px solid #000' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>CHIT SU WAI</Typography>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ padding: '5px', border: '2px solid #000', width: '15%' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>③ 在地</Typography>
                                </td>
                                <td colSpan={2} style={{ padding: '5px', border: '2px solid #000' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>No.24, Kant Kaw Street, Between 22nd x 23rd , 88th x 89th Streets, Aung Myay Thar Zan Tsp, Mandalay</Typography>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'end'
                                    }}>
                                        <Box>
                                            <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>（電話　　+95 9 769 80 4488）</Typography>
                                            <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>（E-mail　info@clovermandalay.com）</Typography>
                                        </Box>
                                    </Box>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ padding: '5px', border: '2px solid #000', width: '15%', paddingTop: '10px', paddingBottom: '10px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>④ 立年月日</Typography>
                                </td>
                                <td colSpan={2} style={{ padding: '5px', border: '2px solid #000', textAlign: 'center' }}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly'
                                    }}>
                                        <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>2019年</Typography>
                                        <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>5月</Typography>
                                        <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>30日</Typography>
                                    </Box>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ padding: '5px', border: '2px solid #000', width: '15%' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>⑤ 業種及び主要業務</Typography>
                                </td>
                                <td colSpan={2} style={{ padding: '5px', border: '2px solid #000' }}>
                                    <Box sx={{
                                        display: 'flex'
                                    }}>
                                        <Box>
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between'
                                            }}>
                                                <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>業</Typography>
                                                <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>種</Typography>
                                            </Box>
                                            <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>主要業務</Typography>
                                        </Box>
                                        <Box sx={{
                                            px: '10px'
                                        }}>
                                            <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>:</Typography>
                                            <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>:</Typography>
                                        </Box>
                                        <Box>
                                            <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>労働者派遣業</Typography>
                                            <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>海外（主に日本）への労働者派遣</Typography>
                                        </Box>
                                    </Box>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ padding: '5px', border: '2px solid #000', width: '15%', paddingTop: '10px', paddingBottom: '10px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>⑥ 資本金</Typography>
                                </td>
                                <td colSpan={2} style={{ padding: '5px', border: '2px solid #000', textAlign: 'center' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>1億チャット（　　724万　　円）</Typography>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ padding: '5px', border: '2px solid #000', width: '15%' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>⑦ 売上げ（直近年度）</Typography>
                                </td>
                                <td colSpan={2} style={{ padding: '5px', border: '2px solid #000', textAlign: 'center' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>417,600,000チャット（　　　20,000,000　　円）</Typography>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ padding: '5px', border: '2px solid #000', width: '15%', paddingTop: '10px', paddingBottom: '10px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>⑦ 常勤職員数</Typography>
                                </td>
                                <td colSpan={2} style={{ padding: '5px', border: '2px solid #000' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>5 人</Typography>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ padding: '5px', border: '2px solid #000', width: '15%', paddingTop: '10px', paddingBottom: '10px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px', whiteSpace: 'nowrap' }}>⑧ 管理者名</Typography>
                                </td>
                                <td colSpan={2} style={{ padding: '5px', border: '2px solid #000' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>PHYU HNIN WAI</Typography>
                                </td>
                            </tr>
                            <tr>
                                <td rowSpan={4} style={{ padding: '5px', border: '2px solid #000', width: '15%' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>⑩ 日本国内における連絡先等</Typography>
                                </td>
                                <td style={{ padding: '5px', border: '2px solid #000', width: '120px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>Ⅰ 氏名又は名称</Typography>
                                </td>
                                <td style={{ padding: '5px', border: '2px solid #000' }}></td>
                            </tr>
                            <tr>
                                <td style={{ padding: '5px', border: '2px solid #000' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>Ⅱ 住所</Typography>
                                </td>
                                <td style={{ padding: '5px', border: '2px solid #000' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>〒　　　－</Typography>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }} textAlign='end'>（電話　　　―　　　―　　　　）</Typography>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ padding: '5px', border: '2px solid #000' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px', whiteSpace: 'nowrap' }}>Ⅲ 代表者の氏名 <br/>（法人の場合）</Typography>
                                </td>
                                <td style={{ padding: '5px', border: '2px solid #000' }}></td>
                            </tr>
                            <tr>
                                <td style={{ padding: '5px', border: '2px solid #000' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>Ⅳ 職員数</Typography>
                                </td>
                                <td style={{ padding: '5px', border: '2px solid #000' }}></td>
                            </tr>
                        </tbody>
                    </table>
                    <Box sx={{
                        py: '10px'
                    }}>
                        <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>（注意）</Typography>
                        <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>１　※印欄には、記載しないこと。</Typography>
                        <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>２　⑥及び⑦は、現地通貨又は米ドルで記載し、括弧書きで日本円に換算した金額を記載すること。</Typography>
                        <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>３　⑨は、技能実習に関する事業の実施責任者について記載すること。</Typography>
                        <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>４　⑩は、日本国内に事務所がある場合等に記載すること。</Typography>
                    </Box>
                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>上記の記載内容は、事実と相違ありません。</Typography>
                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px', textAlign: 'right', mt: 3 }}>
                        {splitDate(memberData.coeDcDate).year} 年 　{splitDate(memberData.coeDcDate).month} 月 　{splitDate(memberData.coeDcDate).day} 日　　　作成
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'end',
                        pt: 3
                    }}>
                        <Box sx={{
                            textAlign: 'center'
                        }}>
                            <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>外国の送出機関の氏名又は名称 CLOVER MANDALAY CO., LTD</Typography>
                            <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>作成責任者　役職・氏名　　CHIT SU WAI</Typography>
                        </Box>
                    </Box>   
                </>
            }
        </Box>
    )
}

export default Coe13