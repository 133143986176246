import React, { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { splitNrc } from "mm-nrc";
import { convertToMyanmarNumbers } from "../../../utils/enTomm";
import { splitDate } from '../../../utils/splitDate'

const useStyles = makeStyles({
    screen: {
        pageBreakAfter: 'always',
    },
});

const Coe9_8 = ({index}) => {
    const classes = useStyles();
    const { data: printData } = useSelector(state=> state.PrintData)
    const [ memberData,setMemberData ] = useState(null)
    const [ convertedNrc,setConvertedNrc ] = useState(null)
  
    useEffect(()=>{
      if(printData && printData.data && printData.data.members){
        const data = printData.data.members[index]
        setMemberData(data)
        const splittedNrc = data.nrcMm && splitNrc(data.nrcMm)
        setConvertedNrc(splittedNrc);
      }
    },[printData, index])

    return (

        <Box className={classes.screen} sx={{ px: 15, pt: 10 }}>
            {
                memberData &&
                <>
                    <Box sx={{ pl: 20, mb: 5 }}>
                        <Typography sx={{ fontFamily: 'PdsFontNumber', fontSize: '20px', fontWeight: 'bold', textAlign: 'center' }}>(အေဂျင်စီ)</Typography>
                        <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px', fontWeight: 'bold', textAlign: 'center' }}>(エージェンシー側)</Typography>
                    </Box>
                    <table>
                        <tbody>
                            <tr>
                                <td style={{ paddingTop: '40px', minWidth: '300px' }}>
                                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '35px' }}>လက်မှတ်</Typography>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px', lineHeight: '35px' }}>署名</Typography>
                                </td>
                                <td style={{ minWidth: "300px" }}>
                                    <Typography></Typography>
                                    <hr style={{ border: '1px solid #000' }}></hr>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '35px', whiteSpace: 'nowrap' }}>မန်နေဂျင်းဒါရိုက်တာအမည်</Typography>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px', lineHeight: '35px', whiteSpace: 'nowrap' }}>マネージンディレクターの名前</Typography>
                                </td>
                                <td style={{ minWidth: "300px" }}>
                                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '30px', whiteSpace: 'nowrap', textAlign: 'center' }}>ချစ်စုဝေ</Typography>
                                    <hr style={{ border: '1px solid #000' }}></hr>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '35px', whiteSpace: 'nowrap' }}>မှတ်ပုံတင်အမှတ်</Typography>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px', lineHeight: '35px' }}>国民登録証書番号</Typography>
                                </td>
                                <td style={{ minWidth: "300px" }}>
                                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '30px', whiteSpace: 'nowrap', textAlign: 'center' }}>၉/အမဇ(နိုင်) ၀၃၁၃၀၅</Typography>
                                    <hr style={{ border: '1px solid #000' }}></hr>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '35px', whiteSpace: 'nowrap' }}>လိပ်စာ</Typography>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px', lineHeight: '35px' }}>住所</Typography>
                                </td>
                                <td style={{ minWidth: "300px" }}>
                                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '30px', whiteSpace: 'nowrap', textAlign: 'center' }}>အမှတ် (၂၄)၊ ကံ့ကော်လမ်း၊ ၂၂x၂၃ လမ်းကြား၊ ၈၈x၈၉ လမ်းကြား၊</Typography>
                                    <hr style={{ border: '1px solid #000' }}></hr>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td style={{ minWidth: "300px" }}>
                                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '30px', whiteSpace: 'nowrap', textAlign: 'center' }}>အောင်မြေသာစံမြို့နယ်၊ မန္တလေးမြို့</Typography>
                                    <hr style={{ border: '1px solid #000' }}></hr>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <Box sx={{ pl: 20, mb: 5, mt: 7 }}>
                        <Typography sx={{ fontFamily: 'PdsFontNumber', fontSize: '20px', fontWeight: 'bold', textAlign: 'center' }}>(အလုပ်သမား)</Typography>
                        <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px', fontWeight: 'bold', textAlign: 'center' }}>(技能実習生の名前)</Typography>
                    </Box>
                    <table>
                        <tbody>
                            <tr>
                                <td style={{ paddingTop: '40px', minWidth: '300px' }}>
                                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '35px' }}>လက်မှတ်</Typography>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px', lineHeight: '35px' }}>署名</Typography>
                                </td>
                                <td style={{ minWidth: "300px" }}>
                                    <Typography></Typography>
                                    <hr style={{ border: '1px solid #000' }}></hr>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '35px', whiteSpace: 'nowrap' }}>အလုပ်သမားအမည်</Typography>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px', lineHeight: '35px', whiteSpace: 'nowrap' }}>技能実習生の名前</Typography>
                                </td>
                                <td style={{ minWidth: "300px" }}>
                                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '30px', whiteSpace: 'nowrap', textAlign: 'center' }}>{memberData ? memberData.nameMm : ''}</Typography>
                                    <hr style={{ border: '1px solid #000' }}></hr>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '35px', whiteSpace: 'nowrap' }}>မှတ်ပုံတင်အမှတ်</Typography>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px', lineHeight: '35px' }}>国民登録証書番号</Typography>
                                </td>
                                <td style={{ minWidth: "300px" }}>
                                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '30px', whiteSpace: 'nowrap', textAlign: 'center' }}>
                                        {convertedNrc ? `${convertedNrc.stateNo}/${convertedNrc.townshipCode}(${convertedNrc.nrcType}) ${convertToMyanmarNumbers(convertedNrc.nrcNumber)}` : ''}
                                    </Typography>
                                    <hr style={{ border: '1px solid #000' }}></hr>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '35px', whiteSpace: 'nowrap' }}>နိုင်ငံကူးလက်မှတ်အမှတ်</Typography>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px', lineHeight: '35px' }}>パスポート番号</Typography>
                                </td>
                                <td style={{ minWidth: "300px" }}>
                                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '30px', whiteSpace: 'nowrap', textAlign: 'center' }}>{memberData ? memberData.passport : ''}</Typography>
                                    <hr style={{ border: '1px solid #000' }}></hr>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '35px', whiteSpace: 'nowrap' }}>လိပ်စာ</Typography>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px', lineHeight: '35px' }}>住所</Typography>
                                </td>
                                <td style={{ minWidth: "300px" }}>
                                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '30px', whiteSpace: 'nowrap', textAlign: 'center' }}>
                                        {`${memberData && memberData.addressNoMm ? `${memberData.addressNoMm}၊ ` : ''}`}
                                        {`${memberData && memberData.wardOrVillageMm ? `${memberData.wardOrVillageMm}၊ ` : ''}`}
                                        {`${memberData && memberData.townshipMm ? `${memberData.townshipMm}၊ ` : ''}`}
                                        {`${memberData && memberData.districtMm ? `${memberData.districtMm}၊ ` : ''}`}
                                    </Typography>
                                    <hr style={{ border: '1px solid #000' }}></hr>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td style={{ minWidth: "500px" }}>
                                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '30px', whiteSpace: 'nowrap', textAlign: 'center' }}>
                                        {`${memberData && memberData.stateOrDivisionMm ? `${memberData.stateOrDivisionMm}` : ''}`}
                                    </Typography>
                                    <hr style={{ border: '1px solid #000' }}></hr>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <Box sx={{
                            display: 'flex',
                            justifyContent: 'start',
                            pt: 20
                        }}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td style={{ textAlign: 'center', fontFamily: 'PdsFont', fontSize: '20px', paddingRight: '40px' }}>ရက်စွဲ</td>
                                        <td style={{ textAlign: 'center', fontFamily: 'PdsFont', fontSize: '20px', paddingRight: '40px' }}>{convertToMyanmarNumbers(splitDate(memberData.coeDcDate).year)} ခုနှစ်</td>
                                        <td style={{ textAlign: 'center', fontFamily: 'PdsFont', fontSize: '20px', paddingRight: '40px' }}>{convertToMyanmarNumbers(splitDate(memberData.coeDcDate).month)} လ</td>
                                        <td style={{ textAlign: 'center', fontFamily: 'PdsFont', fontSize: '20px', paddingRight: '40px' }}>{convertToMyanmarNumbers(splitDate(memberData.coeDcDate).day)} ရက်</td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: 'center', fontFamily: 'Mincho', fontSize: '20px', paddingRight: '40px' }}>日付：</td>
                                        <td style={{ textAlign: 'center', fontFamily: 'Mincho', fontSize: '20px', paddingRight: '40px' }}>{splitDate(memberData.coeDcDate).year} 年</td>
                                        <td style={{ textAlign: 'center', fontFamily: 'Mincho', fontSize: '20px', paddingRight: '40px' }}>{splitDate(memberData.coeDcDate).month} 月</td>
                                        <td style={{ textAlign: 'center', fontFamily: 'Mincho', fontSize: '20px', paddingRight: '40px' }}>{splitDate(memberData.coeDcDate).day} 日</td>
                                    </tr>
                                </tbody>
                            </table>
                    </Box>   
                </>
            }
        </Box>
    )
}

export default Coe9_8