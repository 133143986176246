import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { splitDate } from "../../../utils/splitDate";
import dayjs from "dayjs";

const useStyles = makeStyles({
  screen: {
    pageBreakAfter: "always",
    paddingTop: "70px",
  },
  text: {
    fontSize: "16px",
    fontFamily: "Mincho",
  },
});

const sampleData = [
  {
    title: "介護の基本Ⅰ・Ⅱ",
    time: "年　　月　　日　～　　　年　　月　　日",
  },
  {
    title: "コミュニケーション技術",
    time: "年　　月　　日　～　　　年　　月　　日",
  },
  {
    title: "移動の介護",
    time: "年　　月　　日　～　　　年　　月　　日",
  },
  {
    title: "食事の介護",
    time: "年　　月　　日　～　　　年　　月　　日",
  },
  {
    title: "排泄の介護",
    time: "年　　月　　日　～　　　年　　月　　日",
  },
  {
    title: "衣服の着脱の介護",
    time: "年　　月　　日　～　　　年　　月　　日",
  },
  {
    title: "入浴・身体の清潔の介護",
    time: "年　　月　　日　～　　　年　　月　　日",
  },
];

const Kaigo_1 = () => {
  const classes = useStyles();
  const { data: printData } = useSelector((state) => state.PrintData);
  const [realMember, setRealMember] = useState(null);
  const [memberData, setMemberData] = useState(null);
  const [depDate, setDepDate] = useState(null);

  useEffect(() => {
    if (printData && printData.data && printData.data.members) {
      const data = printData.data.members;
      setRealMember(data);
      const formattedDate = printData.data.initialDepartureDate
        ? dayjs(printData.data.initialDepartureDate).format("YYYY-MM-DD")
        : "";
      setDepDate(formattedDate);
      const filledMemberData = Array.from(
        { length: data?.length > 17 ? data?.length : 17 },
        (_, index) => data?.[index] || {}
      );
      setMemberData(filledMemberData);
    }
  }, [printData]);
  return (
    <Box className={classes.screen} sx={{ px: 15, pt: 25 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography className={classes.text}>
            介護参考様式第３号（告示第１条第２号関係）　
          </Typography>
        </Box>
        <Box sx={{ textAlign: "end" }}>
          <Typography sx={{ fontSize: "16px", fontFamily: "Mincho", mb: 5 }}>
            （日本産業規格Ａ列４）
          </Typography>
        </Box>
      </Box>
      <Typography
        sx={{
          fontSize: "22px",
          fontFamily: "Mincho",
          letterSpacing: "30px",
          textAlign: "center",
          my: 5,
        }}
      >
        入国前講習実施（予定）表
      </Typography>
      <Box sx={{ textAlign: "end" }}>
        <Typography sx={{ fontSize: "16px", fontFamily: "Mincho" }}>
          作成日：　　　　2024年　　　10月　　　7日
        </Typography>
        <Typography sx={{ fontSize: "16px", fontFamily: "Mincho", mb: 5 }}>
          申請者（実習実施者）：
        </Typography>
      </Box>
      <Box>
        <Typography className={classes.text}>
          日本語科目の講義を行う講師
        </Typography>
        <Typography className={classes.text}>氏　　　名 HTOO HTOO</Typography>
        <Typography className={classes.text}>職業 日本語講師</Typography>
        <Typography className={classes.text}>
          所属機関 KAKEHASHI Japanese Language Centre
        </Typography>
        <Typography className={classes.text}>
          専門的知識の経歴 2008年 9月～現在　KAKEHASHI Japanese Language Centre
        </Typography>
      </Box>
      <Box sx={{ mb: 10 }}>
        <Typography className={classes.text}>
          技能等の修得等に資する知識の科目の講義を行う講師
        </Typography>
        <Typography className={classes.text}>氏　　　名 </Typography>
        <Typography className={classes.text}>職業</Typography>
        <Typography className={classes.text}>所属機関</Typography>
        <Typography className={classes.text}>専門的知識の経歴</Typography>
      </Box>
      <Typography>１　日本語科目</Typography>
      <table border="1" style={{ borderCollapse: "collapse", width: "100%" }}>
        <tbody>
          <tr>
            <td
              style={{ textAlign: "center", padding: "5px", width: "50px" }}
            ></td>
            <td style={{ textAlign: "center", padding: "5px" }}>
              <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                講習内容
              </Typography>
            </td>
            <td style={{ textAlign: "center", padding: "5px" }} colSpan={4}>
              <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                実施期間
              </Typography>
            </td>
            <td style={{ textAlign: "center", padding: "5px" }}>
              <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                実施 <br /> 時間数
              </Typography>
            </td>
          </tr>
          {memberData &&
            memberData.map((member, index) => (
              <tr key={index}>
                <td style={{ textAlign: "center", padding: "10px" }}>
                  <Typography sx={{ fontFamily: "Mincho", fontSize: "20px" }}>
                    {member.nameEng ? `${index + 1}` : ""}
                  </Typography>
                </td>
                <td style={{ textAlign: "center", padding: "10px" }}>
                  <Typography sx={{ fontFamily: "Mincho", fontSize: "20px" }}>
                    {member.nameEng}
                  </Typography>
                </td>
                <td
                  colSpan={4}
                  style={{ textAlign: "center", padding: "10px" }}
                >
                  <Typography sx={{ fontFamily: "Mincho", fontSize: "20px" }}>
                    2024年　9月　2日　～　2024年　11月　13日
                  </Typography>
                </td>
                <td style={{ textAlign: "center", padding: "10px" }}>
                  <Typography sx={{ fontFamily: "Mincho", fontSize: "20px" }}>
                    50時間
                  </Typography>
                </td>
              </tr>
            ))}
          <tr>
            <td colSpan={6} style={{ textAlign: "center", padding: "5px" }}>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontFamily: "Mincho",
                  letterSpacing: 20,
                }}
              >
                合　計　時　間
              </Typography>
            </td>
            <td style={{ textAlign: "center", padding: "5px" }}>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontFamily: "Mincho",
                }}
              >
                210時間
              </Typography>
            </td>
          </tr>
        </tbody>
      </table>
      <Typography
        sx={{
          marginTop: "150px",
        }}
      >
        ２　技能等の修得等に資する知識の科目
      </Typography>
      <table border="1" style={{ borderCollapse: "collapse", width: "100%" }}>
        <tbody>
          <tr>
            <td
              style={{ textAlign: "center", padding: "5px", width: "50px" }}
            ></td>
            <td style={{ textAlign: "center", padding: "5px" }}>
              <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                講習内容
              </Typography>
            </td>
            <td style={{ textAlign: "center", padding: "5px" }} colSpan={4}>
              <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                実施期間
              </Typography>
            </td>
            <td style={{ textAlign: "center", padding: "5px" }}>
              <Typography sx={{ fontSize: "20px", fontFamily: "Mincho" }}>
                実施 <br /> 時間数
              </Typography>
            </td>
          </tr>
          {sampleData &&
            sampleData.map((sample, index) => (
              <tr key={index}>
                <td style={{ textAlign: "center", padding: "10px" }}>
                  <Typography sx={{ fontFamily: "Mincho", fontSize: "20px" }}>
                    {`${index + 1}`}
                  </Typography>
                </td>
                <td style={{ textAlign: "center", padding: "10px" }}>
                  <Typography sx={{ fontFamily: "Mincho", fontSize: "20px" }}>
                    {sample.title}
                  </Typography>
                </td>
                <td
                  colSpan={4}
                  style={{ textAlign: "center", padding: "10px" }}
                >
                  <Typography sx={{ fontFamily: "Mincho", fontSize: "20px" }}>
                    {sample.time}
                  </Typography>
                </td>
                <td style={{ textAlign: "center", padding: "10px" }}>
                  <Typography sx={{ fontFamily: "Mincho", fontSize: "20px" }}>
                    時間
                  </Typography>
                </td>
              </tr>
            ))}
          <tr>
            <td colSpan={6} style={{ textAlign: "center", padding: "5px" }}>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontFamily: "Mincho",
                  letterSpacing: 20,
                }}
              >
                合　計　時　間
              </Typography>
            </td>
            <td style={{ textAlign: "center", padding: "5px" }}>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontFamily: "Mincho",
                }}
              >
                210時間
              </Typography>
            </td>
          </tr>
        </tbody>
      </table>
    </Box>
  );
};

export default Kaigo_1;
