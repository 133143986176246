import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { convertToMyanmarNumbers } from "../../../../utils/enTomm";
import { useSelector } from "react-redux";
import { useState } from "react";

const useStyles = makeStyles({
  screen: {
    pageBreakAfter: "always",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: '400px'
  },
  rotatedBox: {
    width: "100%",
    transform: `rotate(-90deg) translate(0px, -250px)`,
    // overflowX: 'auto',
  },
  table: {
    borderCollapse: "collapse",
    width: "100%",
    lineHeight: "40px",
    marginTop: "15px",
  },
  tableHeaderCell: {
    textAlign: "center",
    verticalAlign: "middle",
    fontFamily: "PdsFont",
    fontSize: "22px",
    padding: "5px",
    border: "2px solid #000",
  },
  tableCell: {
    textAlign: "center",
    verticalAlign: "middle",
    fontFamily: "PdsFont",
    fontSize: "22px",
    padding: "10px 15px",
    border: "2px solid #000",
  },
});

const DemandLetterTable = ({ type }) => {
  const [dmData, setDmData] = useState(null);
  const pdfData = useSelector((state) => state.PrintData.data);
  const classes = useStyles();

  useEffect(() => {
    if (pdfData) {
      const { data } = pdfData;
      setDmData(data);
    }
  }, [pdfData]);

  return (
    <Box className={classes.screen}>
      {dmData && (
        <>
          <div style={{ height: "400px", width: "100%" }}></div>
          <Box className={classes.rotatedBox}>
            <Typography
              sx={{
                fontFamily: "PdsFontNumber",
                fontSize: "20px",
                textAlign: "center",
                lineHeight: "40px",
                mb: 3,
                fontWeight: "bold",
              }}
            >
              နည်းပညာအလုပ်သင် Demand Letter များကို စိစစ်ပေးနိုင်ရေးအတွက်
              အလုပ်သမားသံအရာရှိထံအကြောင်းကြားပေးနိုင်ရန် တင်ပြသည့်စာရင်း
            </Typography>
            <table className="custom-table">
              <tbody>
                <tr>
                  <td className={classes.tableCell} rowSpan={2}>
                    စဉ်
                  </td>
                  <td className={classes.tableCell} rowSpan={2}>
                    ဝင်စာအမှတ်ရက်စွဲ
                  </td>
                  <td className={classes.tableCell} rowSpan={2}>
                    အေဂျင်စီအမည်
                  </td>
                  <td className={classes.tableCell} rowSpan={2}>
                    ဂျပန်ကုမ္ပဏီအမည်/ကြီးကြပ်အဖွဲ့အစည်းအမည်
                  </td>
                  <td className={classes.tableCell} rowSpan={2}>
                    အလုပ်အကိုင်အမျိုးအစား{" "}
                  </td>
                  <td className={classes.tableCell} rowSpan={2}>
                    အလုပ်ခေါ်စာအမျိုးအစား{" "}
                  </td>
                  <td
                    className={classes.tableCell}
                    colSpan={3}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    အလုပ်သမားစာရင်း
                  </td>

                  <td className={classes.tableCell} rowSpan={2}>
                    လစာနှုန်း(ယန်း)
                  </td>
                  <td className={classes.tableCell} rowSpan={2}>
                    လစာနှုန်း(ယန်း)
                    အလုပ်သမားများလုပ်ကိုင်ရမည့်ဂျပန်ကုမ္ပဏီလိပ်စာ{" "}
                  </td>
                </tr>
                <tr>
                  <td className={classes.tableCell}>ကျား</td>
                  <td className={classes.tableCell}>မ</td>
                  <td className={classes.tableCell}>ပေါင်း</td>
                </tr>
                <tr>
                  <td className={classes.tableCell}>1</td>
                  <td
                    className={classes.tableCell}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {dmData.demandLetterDate}
                  </td>
                  <td
                    className={classes.tableCell}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    Clover Mandalay Co., Ltd
                  </td>
                  <td className={classes.tableCell}>
                    Organization: {dmData.organization.nameEng}
                    <br></br>
                    Company: {dmData.company.nameEng}
                  </td>
                  <td
                    className={classes.tableCell}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {dmData.jobCategoryENG}
                  </td>
                  <td
                    className={classes.tableCell}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    Technical Intern Training
                  </td>
                  <td className={classes.tableCell}>
                    {dmData.numberOfWorkers.male}
                  </td>
                  <td className={classes.tableCell}>
                    {dmData.numberOfWorkers.female}
                  </td>
                  <td className={classes.tableCell}>
                    {dmData.numberOfWorkers.total}
                  </td>
                  <td
                    className={classes.tableCell}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {dmData.trainingPeriodWageScale}
                  </td>
                  <td className={classes.tableCell}>
                    {/* Demand Letter တွင် ဖော်ပြထားသည့် (ကြီးကြပ်အဖွဲ့အစည်း လိပ်စာ၊
                    Post Code၊ ဖုန်း၊ Fax No အပြည့်အစုံ) (ဂျပန်ကုမ္ပဏီ လိပ်စာ၊ ဖုန်း
                    နံပါတ်၊ Fax No အပြည့်အစုံ) */}
                    Organization:{" "}
                    {`${dmData.organization.addressEng}, ${dmData.organization.postcode}, ${dmData.organization.phone}, ${dmData.organization.fax}`}
                    <br></br>
                    Company:{" "}
                    {`${dmData.company.addressEng}, ${dmData.company.phone}, ${dmData.company.fax}`}
                  </td>
                </tr>
                <tr>
                  <td className={classes.tableCell} rowSpan={2} colSpan={6}>
                    စုစုပေါင်း
                  </td>
                  <td className={classes.tableCell}>
                    {dmData.numberOfWorkers.male}
                  </td>
                  <td className={classes.tableCell}>
                    {dmData.numberOfWorkers.female}
                  </td>
                  <td className={classes.tableCell}>
                    {dmData.numberOfWorkers.total}
                  </td>
                  <td
                    className={classes.tableCell}
                    style={{ whiteSpace: "nowrap" }}
                  ></td>
                  <td
                    className={classes.tableCell}
                    style={{ whiteSpace: "nowrap" }}
                  ></td>
                </tr>
              </tbody>
            </table>
          </Box>
          <div style={{ height: "400px", width: "100%" }}></div>
        </>
      )}
    </Box>
  );
};

export default DemandLetterTable;
