import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from '@mui/styles';
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
    screen: {
        pageBreakAfter: 'always',
    },
    text: {
        textAlign: 'justify',
        textIndent: '50px',
        lineHeight: '55px',
        wordSpacing: 10,
    },
    bodyMm: {
        fontFamily: 'PdsFont',
        fontSize: '22px',
    },
    bodyJp: {
        fontFamily: 'Mincho',
        fontSize: '22px',
    },
    centeredText: {
        textAlign: 'center',
        lineHeight: '55px',
        fontFamily: 'Mincho',
        fontSize: '18px',
    },
    centeredSubtitle: {
        textAlign: 'justify',
        fontSize: '20px',
        fontFamily: 'PdsFont',
        lineHeight: '50px',
    },
    title: {
        textAlign: 'center',
        fontSize: '24px',
        letterSpacing: 35,
        fontFamily: 'Mincho',
        lineHeight: '50px',
    },
    subtitle: {
        textAlign: 'center',
        fontSize: '22px',
        fontFamily: 'PdsFont',
        lineHeight: '55px',
        letterSpacing: 2,
    },
});

const Coe6_1 = ({index}) => {
    const classes = useStyles();
    const [ currentJob, setCurrentJob ] = useState(null)
    const { data: printData } = useSelector(state=> state.PrintData)
  
    useEffect(()=>{
      if(printData && printData.data && printData.data.members){
        const experienceData = printData.data.members[index].experiences
        const lastJob = experienceData[experienceData.length -1]
        setCurrentJob(lastJob)
      }
    },[printData])


    return (
      <Box className={classes.screen} sx={{ px: 15, pt: 10 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Typography sx={{ fontSize: "16px", fontFamily: "Mincho" }}>
              参考様式第１-20号（規則第８条第18号関係）
              <Typography>
                ရည်ညွန်းပုံစံ အမှတ် - 1-20 ( စည်းမျဉ်းစာပိုဒ် 8 အမှတ်စဉ် 18 )
              </Typography>
            </Typography>
            <Typography sx={{ fontSize: "16px", fontFamily: "Mincho" }}>
              Ａ・Ｂ・Ｃ・Ｄ・Ｅ・Ｆ
            </Typography>
          </Box>
          <Typography sx={{ fontSize: "16px", fontFamily: "Mincho" }}>
            （日本工業規格Ａ列４）
            <Typography>(ဂျပန်စက်မှု သတ်မှတ်စံနှုန်း A-4)</Typography>
          </Typography>
        </Box>

        <Box sx={{ py: 5, textAlign: "center" }}>
          <Typography className={classes.title}>技能実習生の申告書</Typography>
          <Typography className={classes.subtitle}>
            နည်းပညာအလုပ်သင် သင်တန်းသား၏ သတင်းပို့လွှာ
          </Typography>
        </Box>

        <Box sx={{ display: "flex", textAlign: "center" }}>
          <Box>
            <Typography className={classes.centeredText}>
              下記の事項を申告します。
            </Typography>
            <Typography className={classes.centeredSubtitle}>
              အောက်ပါအတိုင်း သတင်းပို့ပါသည် ။
            </Typography>
          </Box>
        </Box>

        <Box sx={{ py: 7, textAlign: "center" }}>
          <Typography
            sx={{
              letterSpacing: 1,
              fontSize: "22px",
              fontFamily: "Mincho",
              lineHeight: "40px",
            }}
          >
            記
          </Typography>
          <Typography
            sx={{
              letterSpacing: 1,
              fontSize: "22px",
              fontFamily: "PdsFont",
              lineHeight: "40px",
            }}
          >
            အကြောင်းအရာ
          </Typography>
        </Box>

        <Typography className={`${classes.text} ${classes.bodyJp}`}>
          日本国における技能実習制度の趣旨が、開発途上地域等への技能等の移転による国際協力の推進であることを承知しています。
        </Typography>
        <Typography className={`${classes.text} ${classes.bodyMm}`}>
          ဂျပန်နိုင်ငံနည်းပညာလက်တွေ့သင်ယူခြင်းစနစ်၏ရည်ရွယ်ချက်မှာ
          ဖွံ့ဖြိုးဆဲဒေသများသို့ နည်းပညာများ ဝေမျှခြင်းဖြင့် နိုင်ငံတကာ
          ပူးပေါင်းကူညီမှုကို တိုးမြှင့်ရန်ဖြစ်သည်ကို နားလည်သဘောပေါက်ပါသည်။
        </Typography>
        <Typography className={`${classes.text} ${classes.bodyJp}`}>
          私の本国である{" "}
          <span style={{ fontFamily: "Mincho", textDecoration: "underline" }}>
            ミャンマー
          </span>{" "}
          では修得等が困難である{" "}
          <span style={{ fontFamily: "Mincho", textDecoration: "underline" }}>
            {currentJob?.jobCategoryJp}
          </span>{" "}
          に係る技能等について 修得等をし、技能実習の終了後に帰国した際には、
          <span style={{ fontFamily: "Mincho", textDecoration: "underline" }}>
            技能実習開始前に所属していた勤務先に復職
          </span>{" "}
          するこ とにより、本国への技能等の移転に努めたいと考えています。
        </Typography>
        <Typography className={`${classes.text} ${classes.bodyMm}`}>
          ကျွန်ုပ်တို့၏နိုင်ငံဖြစ်သော{" "}
          <span style={{ fontFamily: "PdsFont", textDecoration: "underline" }}>
            မြန်မာနိုင်ငံ
          </span>{" "}
          တွင် သင်ယူရန်ခက်ခဲသော{" "}
          <span style={{ fontFamily: "PdsFont", textDecoration: "underline" }}>
            {currentJob?.jobCategoryMm}
          </span>{" "}
          နှင့်ဆိုင်သော နည်းပညာများနှင့် စပ်လျဉ်းကာ သင်ယူမှုများဆောင်ရွက်၍
          နည်းပညာ လက်တွေ့သင်ယူခြင်း ပြီးဆုံးသည့်နောက် အမိနိုင်ငံသို့
          ပြန်သည့်အချိန်တွင်{" "}
          <span style={{ fontFamily: "PdsFont", textDecoration: "underline" }}>
            နည်းပညာလက်တွေ့သင်ယူခြင်းမစတင်မှီတွင် သက်ဆိုင်ခဲ့သော
            အလုပ်သို့ပြန်လည်ဝင်ရောက်မှု
          </span>{" "}
          ပြုလုပ်ခြင်းဖြင့် အမိနိုင်ငံသို့ နည်းပညာများဝေမျှခြင်းတွင်
          အကျိုးပြုချင်ပါသည်။
        </Typography>
        <Typography className={`${classes.text} ${classes.bodyJp}`}>
          日本国で技能実習を行うに当たり、私や私と関係のある人が、誰かに保証金を預ける契約を結んでいません。また、今後結ぶ予定もありません。
        </Typography>
        <Typography className={`${classes.text} ${classes.bodyMm}`}>
          ဂျပန်နိုင်ငံတွ်နိုင်ငံတွ် နည်းပညာလက်တွေ့သင်ယူခြင်းဆောင်ရွက်ရန်
          ကျွန်ုပ်နှင့်ကျွန်ုပ်၏သက်ဆိုင်သူများသည် တစ်ဦး တစ်ယောက်ထံတွင်
          အာမခံငွေအပ်နှင်းခြင်းစာချုပ်ကို ချုပ်ဆိုမထားပါ။ တစ်ဖန် နောက်နောင်တွင်
          ချုပ်ဆိုရန် အစီအစဉ် လည်းမရှိပါ။
        </Typography>
        
      </Box>
    );
}

export default Coe6_1;
