import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import theme from "../../../utils/theme";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getGroupById } from "../../../slices/backOffice/interviewGpSlice";
import FormDatePicker from "../../../components/main/DatePicker";
import {
  createSmartCard,
  updateSmartCard,
} from "../../../slices/backOffice/documentSlice";
import Loading from "../../../components/utils/Loading";

const createOwicSchema = Yup.object().shape({
  smart_card_date_nay_pyi_taw: Yup.string().required(
    "Smart card date is required"
  ),
  smart_card_date_north_dagon: Yup.string().required(
    "North Dagon smart card date is required"
  ),
  approval_date: Yup.string().required("Approval date is required"),
  owic_approval_date: Yup.string().required("OWIC approval date is required"),
  mofa_approval_date: Yup.string().required(
    "MOFA approval date is required"
  ),
});

const CreateOwic = ({ isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [open, setOpen] = React.useState(isOpen);
  const [date, setDate] = React.useState(null);
  const [ndDate, setNdDate] = React.useState(null);
  const [approvalDate, setApprovalDate] = React.useState(null);
  const [mofaApprovalDate, setMofaApprovalDate] = React.useState(null);
  const [owicApprovalDate, setOwicApprovalDate] = React.useState(null);
  const createLoading = useSelector((state) => state.Document.loading);
  const currentGroup = useSelector((state) => state.IntGroup.group);

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    trigger,
  } = useForm({
    resolver: yupResolver(createOwicSchema),
  });

  const handleClose = () => {
    reset();
    setDate(null);
    setOpen(false);
    setIsOpen(false);
  };

  const handleOnSubmit = async (data) => {
    currentGroup &&
    currentGroup.data.smart_card_date_nay_pyi_taw &&
    currentGroup.data.smart_card_date_north_dagon
      ? await dispatch(updateSmartCard({ data: data, gpId: id }))
      : await dispatch(createSmartCard({ data: data, gpId: id }));
    await dispatch(getGroupById(id));
    handleClose();
  };

  React.useEffect(() => {
    if (date) {
      setValue("smart_card_date_nay_pyi_taw", date);
      trigger("smart_card_date_nay_pyi_taw");
    }
    if (ndDate) {
      setValue("smart_card_date_north_dagon", ndDate);
      trigger("smart_card_date_north_dagon");
    }
    if (approvalDate) {
      setValue("approval_date", approvalDate);
      trigger("approval_date");
    }
    if (owicApprovalDate) {
      setValue("owic_approval_date", owicApprovalDate);
      trigger("owic_approval_date");
    }
    if (mofaApprovalDate) {
      setValue("mofa_approval_date", mofaApprovalDate);
      trigger("mofa_approval_date");
    }
  }, [approvalDate, date, mofaApprovalDate, ndDate, owicApprovalDate, setValue, trigger]);

  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  React.useEffect(() => {
    if (
      currentGroup &&
      currentGroup.data.smart_card_date_nay_pyi_taw &&
      currentGroup.data.smart_card_date_north_dagon
    ) {
      setValue(
        "smart_card_date_nay_pyi_taw",
        currentGroup.data.smart_card_date_nay_pyi_taw
      );
      setValue(
        "smart_card_date_north_dagon",
        currentGroup.data.smart_card_date_north_dagon
      );
      setDate(currentGroup.data.smart_card_date_nay_pyi_taw);
      setNdDate(currentGroup.data.smart_card_date_north_dagon);
    }
    if (
      currentGroup &&
      currentGroup.data.approval_date &&
      currentGroup.data.owic_approval_date &&
      currentGroup.data.mofa_approval_date
    ) {
      setValue("approval_date", currentGroup.data.approval_date);
      setValue("owic_approval_date", currentGroup.data.owic_approval_date);
      setValue("mofa_approval_date", currentGroup.data.mofa_approval_date);
      setApprovalDate(currentGroup.data.approval_date);
      setOwicApprovalDate(currentGroup.data.owic_approval_date);
      setMofaApprovalDate(currentGroup.data.mofa_approval_date);
    }
  }, [currentGroup, isOpen]);

  return (
    <React.Fragment>
      {createLoading && <Loading />}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Smart Card</DialogTitle>
        <DialogContent sx={{ width: 400 }}>
          <DialogContentText id="alert-dialog-description" sx={{ mb: 1 }}>
            Fill the date to create smart card document.
          </DialogContentText>
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <Box sx={{ mt: 2 }}></Box>
            <FormDatePicker
              placeholderText={"Smart Card Date"}
              dateVal={date}
              setDate={setDate}
              error={errors?.smart_card_date_nay_pyi_taw}
            />
            <Box sx={{ mt: 2 }}></Box>
            <FormDatePicker
              placeholderText={"North Dagon smart card date"}
              dateVal={ndDate}
              setDate={setNdDate}
              error={errors?.smart_card_date_north_dagon}
            />
            <Box sx={{ mt: 2 }}></Box>
            <FormDatePicker
              placeholderText={"Approval Date"}
              dateVal={approvalDate}
              setDate={setApprovalDate}
              error={errors?.approval_date}
            />
            <Box sx={{ mt: 2 }}></Box>
            <FormDatePicker
              placeholderText={"OWIC Approval Date"}
              dateVal={owicApprovalDate}
              setDate={setOwicApprovalDate}
              error={errors?.owic_approval_date}
            />
            <Box sx={{ mt: 2 }}></Box>
            <FormDatePicker
              placeholderText={"MOFA Approval Date"}
              dateVal={mofaApprovalDate}
              setDate={setMofaApprovalDate}
              error={errors?.mofa_approval_date}
            />

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                mt: 2,
              }}
            >
              <Button
                type="button"
                onClick={handleClose}
                sx={{ color: theme.palette.common.black }}
              >
                Cancel
              </Button>
              <Button type="submit" autoFocus sx={{ ml: 2 }}>
                Submit
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default CreateOwic;
