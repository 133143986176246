import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { splitDate } from "../../../utils/splitDate";
import { getAge } from "../../../utils/getAge";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    padding: theme.spacing(1),
    fontSize: "18px",
    fontFamily: "Mincho",
    border: "2px solid #000"
  },
  label: {
    fontSize: "18px",
    fontFamily: "Mincho",
    marginRight: theme.spacing(1),
  },
  language: {
    fontFamily: "PdsFont",
    fontSize: "18px",
    whiteSpace: "nowrap",
    lineHeight: "25px",
  },
}));

const Coe3_2 = ({info}) => {
  const classes = useStyles();

  return (
    <>
      {/* 2 -> နိုင်ငံ */}
      <tr>
        <td colSpan={2} className={classes.tableCell}>
          <Typography className={classes.label} sx={{ whiteSpace: 'nowrap' }}>
            ④ 国籍（国又は地域）နိုင်ငံသား <br />
          </Typography>
          <Typography className={classes.language}>
            (နိုင်ငံသို့မဟုတ်ဒေသ)
          </Typography>
        </td>
        <td className={classes.tableCell} colSpan={2}>ミャンマー</td>
        <td className={classes.tableCell} style={{ padding: "5px" }}>
          <Typography className={classes.label}>⑤ 母国語</Typography>
          <Typography className={classes.language}>
            မိခင်ဘာသာစကား
          </Typography>
        </td>
        <td className={classes.tableCell} style={{ textAlign: "end", padding: "5px" }}>
          ミャンマー 語 <Typography className={classes.language}>စကား</Typography>
        </td>
      </tr>
      {/* 3 -> မွေးနေ့ */}
      <tr>
        <td colSpan={2} className={classes.tableCell}>
          <Typography className={classes.label}>⑥ 生年月日</Typography>
          <Typography className={classes.language}>&nbsp;မွေးနေ့</Typography>
        </td>
        <td colSpan={4} className={classes.tableCell} style={{ padding: "5px" }}>
          <table style={{ width: "60%", margin: "0 auto" }}>
            <tbody>
              <tr>
                <td
                  className={classes.label}
                >
                  {splitDate(info.dateOfBirth).year}
                </td>
                <td
                  className={classes.label}
                >
                  年
                </td>
                <td
                  className={classes.label}
                >
                  {splitDate(info.dateOfBirth).month}
                </td>
                <td
                  className={classes.label}
                >
                  月
                </td>
                <td
                  className={classes.label}
                >
                  {splitDate(info.dateOfBirth).day}
                </td>
                <td
                  className={classes.label}
                >
                  日
                </td>
                <td
                  className={classes.label}
                >
                  （ {getAge(info.dateOfBirth)} 歳 ）
                </td>
              </tr>
              <tr>
                <td></td>
                <td
                  className={classes.language}
                >
                  နှစ်
                </td>
                <td></td>
                <td
                  className={classes.language}
                >
                  လ
                </td>
                <td></td>
                <td
                  className={classes.language}
                >
                  ရက်
                </td>
                <td
                  className={classes.language}
                >
                  နှစ်
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      {/* 4 -> နေရပ် လိပ်စာ */}
      <tr>
        <td className={classes.tableCell} colSpan={2} style={{ padding: "5px" }}>
          <Typography sx={{ fontSize: "18px", fontFamily: "Mincho" }}>
            ⑦ 現住所
          </Typography>
          <Typography sx={{ fontSize: "18px", fontFamily: "PdsFont" }}>
            နေရပ် လိပ်စာ
          </Typography>
        </td>
        <td className={classes.tableCell} colSpan={4} style={{ padding: "5px" }}>
          <table>
            <tbody>
              <tr>
                <td
                  style={{
                    fontSize: "18px",
                    verticalAlign: "top",
                    fontFamily: "Mincho",
                    whiteSpace: "nowrap",
                  }}
                >
                  身分証住所
                </td>
                <td
                  style={{
                    fontSize: "18px",
                    verticalAlign: "top",
                    fontFamily: "Mincho",
                    textAlign: "center",
                  }}
                >
                  :
                </td>
                <td
                  style={{
                    fontSize: "18px",
                    verticalAlign: "top",
                    fontFamily: "Mincho",
                  }}
                >
                  {info.addressNoEng ? `${info.addressNoEng},` : ''}
                  {info.wardOrVillageEng ? `${info.wardOrVillageEng},` : ''}
                  {/* {info.wardOrVillageEng ? `${info.wardOrVillageEng},` : ''} */}
                  {info.townshipEng ? `${info.townshipEng},` : ''}
                  {info.stateOrDivisionEng ? `${info.stateOrDivisionEng}` : ''}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontSize: "18px",
                    verticalAlign: "top",
                    fontFamily: "Mincho",
                    whiteSpace: "nowrap",
                  }}
                >
                  現住所
                </td>
                <td
                  style={{
                    fontSize: "18px",
                    verticalAlign: "top",
                    fontFamily: "Mincho",
                    textAlign: "center",
                  }}
                >
                  :
                </td>
                <td
                  style={{
                    fontSize: "18px",
                    verticalAlign: "top",
                    fontFamily: "Mincho",
                  }}
                >
                  {info && info.experiences && info.experiences[info.experiences.length -1 ].location}
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </>
  );
};

export default Coe3_2;
