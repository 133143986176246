import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { splitDate } from "../../../../utils/splitDate";
import { convertToMyanmarNumbers } from "../../../../utils/enTomm";

const useStyles = makeStyles({
  screen: {
    pageBreakAfter: "always",
    minHeight: "100vh",
  },
});

const EmployerContract = ({ type }) => {
  const [ dmData,setDmData ] = useState(null)
  const pdfData = useSelector(state=> state.PrintData.data)
  const classes = useStyles();
  
  useEffect(()=>{
    if(pdfData){
      const { data } = pdfData
      setDmData(data)
    }
  },[pdfData])

  return (
    <div className={classes.screen}>
      {
        dmData &&
        <Box sx={{ p: 20 }}>
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "20px",
              wordSpacing: "5px",
              textAlign: "justify",
              lineHeight: "35px",
              textAlign: "center",
              fontFamily: "Mincho",
            }}
          >
            実習実施者の誓約書
          </Typography>
          <Typography
            sx={{
              fontSize: "20px",
              wordSpacing: "5px",
              textAlign: "justify",
              lineHeight: "35px",
              textAlign: "center",
              fontFamily: "PdsFont",
            }}
          >
            ဂျပန်နိုင်ငံရှိ နည်းပညာ  { type === 'tit' ?"အလုပ်သင်သင်တန်းသား" : "အထူးကျွမ်းကျင်လုပ်သား"} အလုပ်ခန့်အပ်မည့်
            အလုပ်ရှင်၏ ဝန်ခံကတိပြုလွှာ
          </Typography>
        </Box>
        <Box
          sx={{
            py: 10,
          }}
        >
          <Typography
            sx={{
              fontSize: "20px",
              wordSpacing: "5px",
              textAlign: "right",
              lineHeight: "35px",
              fontFamily: "Mincho",
            }}
          >
            日付: {splitDate(dmData.oathDate).year}年 {splitDate(dmData.oathDate).month}月 {splitDate(dmData.oathDate).day}日
          </Typography>
          <Typography
            sx={{
              fontSize: "20px",
              wordSpacing: "5px",
              textAlign: "right",
              lineHeight: "35px",
              fontFamily: "PdsFont",
            }}
          >
            နေ့စွဲ။ {splitDate(dmData.oathDate).year}နှစ် {splitDate(dmData.oathDate).month}လ {splitDate(dmData.oathDate).day}ရက်
          </Typography>
        </Box>
        <Box
          sx={{
            fontSize: "20px",
            wordSpacing: "5px",
            textAlign: "justify",
            lineHeight: "35px",
            fontFamily: "Mincho",
          }}
        >
          日本国実習実施者(
          <Typography sx={{ display: "inline", textDecoration: "underline", fontFamily: 'PdsFont', fontSize: '20px', wordSpacing: '5px' }}>
            &nbsp;&nbsp;&nbsp;{dmData.company.nameJp}&nbsp;&nbsp;&nbsp;
          </Typography>
          ) 、 所在地 （
          <Typography sx={{ display: "inline", textDecoration: "underline", fontFamily: 'PdsFont', fontSize: '20px', wordSpacing: '5px' }}>
          &nbsp;&nbsp;&nbsp;{dmData.company.addressEng}&nbsp;&nbsp;&nbsp;
          </Typography>
          ） は (管理団体
          <Typography sx={{ display: "inline", textDecoration: "underline", fontFamily: 'PdsFont', fontSize: '20px', wordSpacing: '5px' }}>
          &nbsp;&nbsp;&nbsp;{dmData.organization.nameJp}&nbsp;&nbsp;&nbsp;
          </Typography>
          ) を通じてミャンマー送り出し機関 (
          <Typography sx={{ display: "inline", textDecoration: "underline", fontFamily: 'PdsFont', fontSize: '20px', wordSpacing: '5px' }}>
            &nbsp;&nbsp;株式会社クローバーマンダレー&nbsp;&nbsp;
          </Typography>
          ) より （ 職種
          <Typography sx={{ display: "inline", textDecoration: "underline", fontFamily: 'PdsFont', fontSize: '20px', wordSpacing: '5px' }}>
            &nbsp;&nbsp;{dmData.jobCategoryJP}&nbsp;&nbsp;
          </Typography>
          ） 向け求人票記 載募集人数男（
          <Typography sx={{ display: "inline", textDecoration: "underline", fontFamily: 'PdsFont', fontSize: '20px', wordSpacing: '5px' }}>
            &nbsp;&nbsp;{dmData.numberOfWorkers.male !== 0 ? dmData.numberOfWorkers.male : '-'}&nbsp;&nbsp;
          </Typography>
          ）女 （
          <Typography sx={{ display: "inline", textDecoration: "underline", fontFamily: 'PdsFont', fontSize: '20px', wordSpacing: '5px' }}>
          &nbsp;&nbsp;{dmData.numberOfWorkers.female !== 0 ? dmData.numberOfWorkers.female : '-'}&nbsp;&nbsp;
          </Typography>
          ）計（
          <Typography sx={{ display: "inline", textDecoration: "underline", fontFamily: 'PdsFont', fontSize: '20px', wordSpacing: '5px' }}>
          &nbsp;&nbsp;{dmData.numberOfWorkers.total}&nbsp;&nbsp;
          </Typography>
          ）を日本へ実習生とし て入国させます。 日本入国後も、
          実習計画通りの就業と、 日本国の新型肺炎予 防措置を導守してい
          く事を誓約します。
        </Box>
        <Box
          sx={{
            py: 3,
            fontSize: "20px",
            wordSpacing: "5px",
            textAlign: "justify",
            lineHeight: "35px",
            fontFamily: "PdsFont",
          }}
        >
          ဂျပန်နိုင်ငံရှိ
          {type === "tit" ? "အလုပ်သင်သင်တန်းသား" : "အထူးကျွမ်းကျင်လုပ်သား"}
          ခန့်အပ်မည့်အလုပ်ရှင် (
          <Typography sx={{ display: "inline", textDecoration: "underline", fontFamily: 'PdsFont', fontSize: '20px', wordSpacing: '5px' }}>
            &nbsp;&nbsp;{dmData.company.nameEng }&nbsp;&nbsp;
          </Typography>
          ) တည်နေရာ 
          <Typography sx={{ display: "inline", textDecoration: "underline", fontFamily: 'PdsFont', fontSize: '20px', wordSpacing: '5px' }}>
            &nbsp;&nbsp;{dmData.company.addressEng }&nbsp;&nbsp;
          </Typography>
           သည် (အလုပ်သမားကြီးကြပ်ရေးအဖွဲ့အစည်း
          <Typography sx={{ display: "inline", textDecoration: "underline", fontFamily: 'PdsFont', fontSize: '20px', wordSpacing: '5px' }}>
            &nbsp;&nbsp;{dmData.organization.nameEng }&nbsp;&nbsp;
          </Typography>
          ) မှတစ်ဆင့် မြန်မာနိုင်ငံပြည်ပအလုပ်အကိုင် အကျိုးဆောင်လိုင်စင်ရ
          အေဂျင်စီ (
            <Typography sx={{ display: "inline", textDecoration: "underline", fontFamily: 'PdsFont', fontSize: '20px', wordSpacing: '5px' }}>
            &nbsp;&nbsp;Clover Mandalay Co.,Ltd&nbsp;&nbsp;
            </Typography>
          ) သို့အလုပ်အမျိုးအစား(
          <Typography sx={{ display: "inline", textDecoration: "underline", fontFamily: 'PdsFont', fontSize: '20px', wordSpacing: '5px' }}>
            &nbsp;{dmData.jobCategoryMM}&nbsp;
          </Typography>
          ) နှင့်ပတ်သတ်သော အလုပ်ကမ်းလှမ်းစာပါ လူဦးရေ ကျား(
          <Typography sx={{ display: "inline", textDecoration: "underline", fontFamily: 'PdsFont', fontSize: '20px', wordSpacing: '5px' }}>
            &nbsp;&nbsp;{dmData.numberOfWorkers.male !== 0 ? convertToMyanmarNumbers(dmData.numberOfWorkers.male) : '-'}&nbsp;&nbsp;
          </Typography>
          ) ၊ မ (
          <Typography sx={{ display: "inline", textDecoration: "underline", fontFamily: 'PdsFont', fontSize: '20px', wordSpacing: '5px' }}>
            &nbsp;&nbsp;{dmData.numberOfWorkers.female !== 0 ? convertToMyanmarNumbers(dmData.numberOfWorkers.female) : '-'}&nbsp;&nbsp;
          </Typography>
          ) ၊ စုစုပေါင်း (
          <Typography sx={{ display: "inline", textDecoration: "underline", fontFamily: 'PdsFont', fontSize: '20px', wordSpacing: '5px' }}>
            &nbsp;&nbsp;{convertToMyanmarNumbers(dmData.numberOfWorkers.total)}&nbsp;&nbsp;
          </Typography>
          ) ဦးကို ဂျပန်နိုင်ငံသို့
          {type === "tit" ? "အလုပ်သင် အလုပ်သမား" : "အထူးကျွမ်းကျင် လုပ်သား"}
          အဖြစ် တာဝန်ယူခန့်အပ်မည်ဖြစ်သည်။ ဂျပန်နိုင်ငံ အလုပ်သမားခန့်အပ်ခြင်း၏
          သတ်မှတ်စံနှုန်းများ နှင့်အညီ ရေးဆွဲထားသောအစီအစဉ်အတိုင်း
          လုပ်ငန်းလုပ်ဆောင်စေခြင်းနှင့် ယခုအခါတွင် ဖြစ်ပေါ်နေသော COVID-19
          (Corona Virus) နှင့်ပတ်သက်သော သက်ဆိုင်ရာ နိုင်ငံအလိုက်
          လိုက်နာဆောင်ရွက်ရမည့် ကျန်းမာရေး စည်းကမ်းများအတိုင်းကို
          လိုက်နာဆောင်ရွက်မည်ဖြစ်ကြောင်းကို ဝန်ခံကတိပြုအပ်ပါသည်။
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            mt: 10
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              width: "70%",
            }}
          >
            <Grid item xs={5}>
              <Typography
                sx={{
                  fontSize: "20px",
                  wordSpacing: "5px",
                  textAlign: "justify",
                  fontFamily: "Mincho",
                }}
              >
                代表者（自署）
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <hr style={{ border: '1px solid #000', marginTop: '30px' }}></hr>
            </Grid>
            <Grid item xs={5}>
              <Typography
                sx={{
                  fontSize: "20px",
                  wordSpacing: "5px",
                  textAlign: "justify",
                  fontFamily: "PdsFont",
                }}
              >
                အလုပ်ရှင်၏ လက်မှတ်
              </Typography>
            </Grid>
            <Grid item xs={7}></Grid>
            <Grid item xs={5}>
              <Typography
                sx={{
                  fontSize: "20px",
                  wordSpacing: "5px",
                  textAlign: "justify",
                  fontFamily: "Mincho",
                }}
              >
                代表者氏名/住所
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography sx={{ fontFamily: "PdsFont", fontSize: '20px' }}>{dmData.company.nameEng}</Typography>
              <hr style={{ border: '1px solid #000' }}></hr>
            </Grid>
            <Grid item xs={5}>
              <Typography
                sx={{
                  fontSize: "20px",
                  wordSpacing: "5px",
                  textAlign: "justify",
                  fontFamily: "PdsFont",
                }}
              >
                အလုပ်ရှင်အမည်/လိပ်စာ
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography sx={{ fontFamily: "PdsFont", fontSize: '20px' }}>{dmData.company.ceoEng}</Typography>
              <hr style={{ border: '1px solid #000' }}></hr>
            </Grid>
            <Grid item xs={5}></Grid>
            <Grid item xs={7}>
              <Typography sx={{ fontFamily: "PdsFont", fontSize: '20px' }}>{dmData.company.addressEng}</Typography>
              <hr style={{ border: '1px solid #000' }}></hr>
            </Grid>
          </Grid>
        </Box>
      </Box>
      }
    </div>
  );
};

export default EmployerContract;
