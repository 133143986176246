import React, { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { splitDate } from "../../../utils/splitDate";
import dayjs from "dayjs";

const useStyles = makeStyles({
    screen: {
        pageBreakAfter: 'always',
        paddingTop: '70px'
    },
});

const Coe11_6 = () => {
    const classes = useStyles();
    const { data: printData } = useSelector(state=> state.PrintData)
    const [ realMember,setRealMember ] = useState(null)
    const [ memberData,setMemberData ] = useState(null)
    const [ depDate,setDepDate ] = useState(null)
  
    useEffect(()=>{
      if(printData && printData.data && printData.data.members){
        const data = printData.data.members
        setRealMember(data)
        const formattedDate = printData.data.initialDepartureDate ? dayjs(printData.data.initialDepartureDate).format('YYYY-MM-DD') : '';
        setDepDate(formattedDate)
        const filledMemberData = Array.from({ length: data?.length > 17 ? data?.length : 17 }, (_, index) =>
            data?.[index] || {}
        );
        setMemberData(filledMemberData)
      }
    },[printData])
    return (
        <Box className={classes.screen} sx={{ px: 15, pt: 25 }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <Box>
                    <Typography sx={{ fontSize: '16px', fontFamily: 'Mincho' }}>参考様式第４-８号別紙（規則第54条第１項第５号関係）</Typography>
                    <Typography sx={{ fontSize: '16px', fontFamily: 'Mincho' }}>Ｄ</Typography>
                </Box>
                <Box sx={{ textAlign: 'end' }}>
                    <Typography sx={{ fontSize: '16px', fontFamily: 'Mincho', mb: 5 }}>（日本工業規格Ａ列４）</Typography>
                    <Typography sx={{ fontSize: '16px', fontFamily: 'Mincho' }}>（入国前講習実施記録別紙）</Typography>
                </Box>
            </Box>
            <Typography sx={{
                fontSize: '22px',
                fontFamily: 'Mincho',
                letterSpacing: '30px',
                textAlign: 'center',
                my: 5,
            }}>技能実習生一覧表</Typography>
            <table border='1' style={{ borderCollapse: 'collapse', width: '100%' }}>
                <tbody>
                    <tr>
                        <td style={{ textAlign: 'center', padding: '5px', width: '50px' }}>
                            <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho', whiteSpace: 'nowrap' }}>番号</Typography>
                        </td>
                        <td style={{ textAlign: 'center', padding: '5px' }}>
                            <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}>技能実習生氏名</Typography>
                        </td>
                        <td style={{ textAlign: 'center', padding: '5px', width: '100px' }}>
                            <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}>入国日</Typography>
                        </td>
                        <td style={{ textAlign: 'center', padding: '5px', width: '40%' }}>
                            <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}>備考</Typography>
                        </td>
                    </tr>
                    {
                        memberData &&
                        memberData.map((member, index) => (
                            <tr key={index}>
                                <td style={{ textAlign: 'center', padding: '10px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>{member.nameEng ? `${index+1}` : ''}</Typography>
                                </td>
                                <td style={{ textAlign: 'center', padding: '10px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>{member.nameEng}</Typography>
                                </td>
                                <td style={{ textAlign: 'center', padding: '10px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>
                                        {   depDate && realMember && index < realMember.length ?
                                            `${splitDate(depDate).year}/${splitDate(depDate).month}/${splitDate(depDate).day}`
                                            :
                                            "/ /"
                                        }
                                    </Typography>
                                </td>
                                <td style={{ textAlign: 'center', padding: '10px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}></Typography>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            <Typography sx={{
                fontSize: '20px', fontFamily: 'Mincho',
                pt: '10px'
            }}>（注意）</Typography>
            <Typography sx={{
                fontSize: '20px', fontFamily: 'Mincho',
                textIndent: '20px'
            }}>実習監理を行う実習実施者の管理簿及び実習監理に係る技能実習生の管理簿と併せて保存すること。</Typography>
        </Box>
    )
}


export default Coe11_6