import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  screen: {
    pageBreakAfter: "always",
  },
  header: {
    fontSize: "27px",
    textAlign: "center",
    lineHeight: "60px",
    fontWeight: "bold",
  },
  content: {
    fontSize: "22px",
    whiteSpace: "nowrap",
  },
});

const Coe9_1 = ({index}) => {
  const classes = useStyles();
  const { data: printData } = useSelector(state=> state.PrintData)
  const [ memberData,setMemberData ] = useState(null)

  useEffect(()=>{
    if(printData && printData.data && printData.data.members){
      const data = printData.data.members[index]
      setMemberData(data)
    }
  },[printData, index])

  return (
    <Box className={classes.screen} sx={{ px: 15, pt: 10 }}>
      <Box sx={{ mt: 30 }}>
        <Typography className={classes.header} sx={{ fontFamily: 'PdsFontNumber' }}>
          ပြည်ပအလုပ်အကိုင်ရှာဖွေရေးအေဂျင်စီနှင့်အလုပ်သင်သင်တန်းသားတို့ကြားချုပ်ဆိုသော
          <br />
          စေလွှတ်မှုသဘောတူစာချုပ်
        </Typography>
        <Typography className={classes.header} sx={{ fontFamily: 'Mincho', mb: 25 }}>
          海外雇用エージェンシーと技能実習生との契約書
        </Typography>

        <table style={{ marginBottom: "30px" }}>
        <tbody>
          <tr>
            <td width="250px">
              <Typography className={classes.content} sx={{ fontFamily: 'PdsFontNumber', fontWeight: 'bold'}}>အေဂျင်စီအမည်</Typography>
            </td>
            <td>
              <Typography className={classes.content} sx={{ ml: 12.5, mr: 2 }}>-</Typography>
            </td>
            <td>
              <Typography className={classes.content} sx={{ fontFamily: 'PdsFontNumber', fontWeight: 'bold'}}>
                CLOVER MANDALAY CO., LTD
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography className={classes.content} sx={{ fontFamily: 'Mincho', fontWeight: 'bold'}}>エージェンシー名 ：</Typography>
            </td>
            <td></td>
            <td>
              <Typography className={classes.content} sx={{ fontFamily: 'Mincho' }}>
                株式会社クローバーマンダレー
              </Typography>
            </td>
          </tr>
          </tbody>
        </table>

        <table>
          <tbody>
          <tr>
            <td width="250px">
              <Typography className={classes.content} sx={{ fontFamily: 'PdsFontNumber', fontWeight: 'bold'}}>အလုပ်သင်သင်တန်းသားအမည်</Typography>
            </td>
            <td>
              <Typography className={classes.content} sx={{ ml: 10, mr: 2 }}>-</Typography>
            </td>
            <td>
              <Typography className={classes.content} sx={{ fontFamily: 'PdsFont' }}>{memberData ? memberData.nameEng : '' }</Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography className={classes.content} sx={{ fontFamily: 'Mincho', fontWeight: 'bold'}}>技能実習生名：</Typography>
            </td>
            <td></td>
            <td></td>
          </tr>
          </tbody>
        </table>
      </Box>
    </Box>
  );
};

export default Coe9_1;
